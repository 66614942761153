export enum StepState {
    Idle = 'IDLE',
    Pending = 'PENDING',
    Success = 'SUCCESS',
    Fail = 'FAIL',
    Rejected = 'REJECTED',
}

export interface Step {
    name: string;
    state: StepState;
}

export enum InteractionState {
    Inactive = 'INACTIVE',
    Active = 'ACTIVE',
    Finished = 'FINISHED',
}

export interface ContractsInteractionsState {
    activeStep: number;
    steps: Step[];
    stepsNames: string[];
    state: InteractionState;
}

export const initialState: ContractsInteractionsState = {
    activeStep: 0,
    steps: [],
    stepsNames: [],
    state: InteractionState.Inactive,
};
