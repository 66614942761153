// @ts-ignore
import useGeoLocation from 'react-ipgeolocation';
import { BLOCKED_COUNTRIES } from '@oilerKit/OilerIPblocker/blockedCountries';
import STOP from '@oilerKit/assets/img/banned-country.png';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    max-width: 600px;
    width: 100%;
    margin: auto;
    margin-top: 5rem;
`;

const StopImage = styled.img`
    width: inherit;
`;

const Message = () => {
    return (
        <Wrapper>
            <StopImage src={STOP} alt="stop message" />
        </Wrapper>
    );
};

interface OilerIPblockerProps {
    children: ReactNode;
}

// no network - add proper handling
const OilerIPblocker = (props: OilerIPblockerProps) => {
    const { children } = props;
    const { country, isLoading, error } = useGeoLocation();

    // if (error) {
    //     sendTelegramAlert("useGeoLocation error").then(() => {});
    // }

    const isIPBanned = Object.values(BLOCKED_COUNTRIES).includes(country);

    return (
        <>{isLoading ? null : isIPBanned || error ? <Message /> : children}</>
    );
};

export default OilerIPblocker;
