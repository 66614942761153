import { NFTEntry } from '@components/NFTsTable/NFTEntry';
export type ForwardsValues = {
    lambda: string | undefined;
    strike: string | undefined;
};

export type NftCollectionTokens = {
    mintBlockNumber: number;
    mintTxHash: string;
    owner: string;
    tokenId: number;
};

export interface FirebaseState {
    forwardsValues: ForwardsValues;
    nftCollectionTokens: NftCollectionTokens[];
    displayNftToken: NFTEntry | undefined;
    pagination: number;
    positionsCount: number;
}

export const initialState: FirebaseState = {
    forwardsValues: {
        lambda: undefined,
        strike: undefined,
    },
    nftCollectionTokens: [],
    displayNftToken: undefined,
    pagination: 0,
    positionsCount: 0,
};

// FORWARDS (NFTs) PER PAGE
export const ROWS_PER_PAGE = 4;
