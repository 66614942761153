import OilerGrid from '@oilerKit/OilerGrid';
import React, { useState } from 'react';
import OilerToggleButtonGroup, {
    OilerToggleButton,
} from '@oilerKit/OilerToggleButtonGroup';
import ForwardMiniChart from './ForwardsMiniChart';
import styled from 'styled-components';
import { useMediaQuery, useTheme } from '@material-ui/core';

const data = [
    {
        name: 'Page A',
        value: 4000,
    },
    {
        name: 'Page B',
        value: 3000,
    },
    {
        name: 'Page C',
        value: 2000,
    },
    {
        name: 'Page A',
        value: 2000,
    },
    {
        name: 'Page B',
        value: 1000,
    },
    {
        name: 'Page C',
        value: 2000,
    },
    {
        name: 'Page A',
        value: 4000,
    },
    {
        name: 'Page B',
        value: 3000,
    },
    {
        name: 'Page C',
        value: 2000,
    },
    {
        name: 'Page B',
        value: 3000,
    },
    {
        name: 'Page C',
        value: 2000,
    },
    {
        name: 'Page A',
        value: 2000,
    },
    {
        name: 'Page B',
        value: 3000,
    },
    {
        name: 'Page C',
        value: 2000,
    },
    {
        name: 'Page A',
        value: 2000,
    },
];

enum ForwardsMiniChartFamily {
    HEALTH,
    TVL,
    LIQUIDITY,
}

const StyledOilerToggleButtonGroup = styled(OilerToggleButtonGroup)`
    ${(props) => props.theme.breakpoints.up('sm')} {
        width: 117px;
    }
`;

const StyledMiniChartWrapper = styled.div`
    ${(props) => props.theme.breakpoints.down('xs')} {
        width: 100%;
    }
    width: 80%;
`;

const ForwardsPositionMiniCharts = () => {
    const [miniChart, setMiniChart] = useState(ForwardsMiniChartFamily.HEALTH);
    const theme = useTheme();
    const isXsmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleMiniChartChange = (option: ForwardsMiniChartFamily) => {
        setMiniChart(option);
    };

    return (
        <OilerGrid
            container
            direction={isXsmallScreen ? 'column' : 'row'}
            alignItems="center"
            justifyContent={'space-between'}
            width="100%"
        >
            <OilerGrid
                container
                flexDirection="row"
                justifyContent={'flex-end'}
                item
                md={8}
                sm={8}
                xs={12}
                mb={isXsmallScreen ? 2 : 0}
            >
                <StyledMiniChartWrapper>
                    <ForwardMiniChart data={data} dataKey="value" />
                </StyledMiniChartWrapper>
            </OilerGrid>
            <OilerGrid
                pl={!isXsmallScreen ? 2 : 0}
                item
                md={4}
                sm={4}
                xs={12}
                alignSelf={'stretch'}
            >
                <StyledOilerToggleButtonGroup
                    fullWidth={isXsmallScreen}
                    size={'xsmall'}
                    orientation={isXsmallScreen ? 'horizontal' : 'vertical'}
                >
                    <OilerToggleButton
                        selected={miniChart === ForwardsMiniChartFamily.HEALTH}
                        onClick={() =>
                            handleMiniChartChange(
                                ForwardsMiniChartFamily.HEALTH,
                            )
                        }
                    >
                        Health
                    </OilerToggleButton>
                    <OilerToggleButton
                        selected={miniChart === ForwardsMiniChartFamily.TVL}
                        onClick={() =>
                            handleMiniChartChange(ForwardsMiniChartFamily.TVL)
                        }
                    >
                        Collateral
                    </OilerToggleButton>
                    <OilerToggleButton
                        selected={
                            miniChart === ForwardsMiniChartFamily.LIQUIDITY
                        }
                        onClick={() =>
                            handleMiniChartChange(
                                ForwardsMiniChartFamily.LIQUIDITY,
                            )
                        }
                    >
                        Payout
                    </OilerToggleButton>
                </StyledOilerToggleButtonGroup>
            </OilerGrid>
        </OilerGrid>
    );
};

export default ForwardsPositionMiniCharts;
