import React from 'react';
import Logo from './Logo';
import MediaList from './MediaList';
import styled from 'styled-components';

const Nav = styled.div`
    display: flex;
    //min-height: 282px;
    background-color: transparent;
    position: relative;
    align-items: start !important;
    flex-direction: column;
    ${(props) => props.theme.breakpoints.up(630)} {
        flex-direction: row;
    }
`;

const StyledMediaList = styled(MediaList)`
    //padding-right: 10px;
    padding-left: 10px;
    ${(props) => props.theme.breakpoints.down(630)} {
        display: none !important;
    }
    ${(props) => props.theme.breakpoints.up(660)} {
        margin-left: auto;
        justify-content: space-between;
        width: 425px;
    }
`;
export interface OilerNavProps {
    showWalletBalance?: boolean;
}

const OilerNav = () => {
    return (
        <Nav>
            <Logo />
            <StyledMediaList />
        </Nav>
    );
};

export default OilerNav;
