import React from 'react';
import styled from 'styled-components';
import WalletInfo from './WalletInfo/WalletInfo';

const Wrapper = styled.div`
    width: 100%;
    min-height: 164px;
    display: flex;
    flex-direction: column;
`;

interface ConnectedInfoProps {
    walletBalance?: boolean;
}

const ConnectedInfo = ({ walletBalance }: ConnectedInfoProps) => {
    return (
        <Wrapper>
            <WalletInfo walletBalance={walletBalance} />
        </Wrapper>
    );
};

export default ConnectedInfo;
