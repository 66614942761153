import { BigNumberish } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import { NftCollectionTokens } from '@features/firebase/types';
import { formatNbToSI } from '@utils/formatNbToSI';

export enum NFTStatus {
    UNKNOWN = 'UNKNOWN',
    OPEN = 'OPEN',
    LIQUIDATABLE = 'LIQUIDATABLE',
    LIQUIDATED = 'LIQUIDATED',
    EXPIRED = 'EXPIRED',
    SETTLED = 'SETTLED',
}

export interface NFTEntry {
    owner: string;
    code: number;
    status: NFTStatus[];
    notional: string;
    strike: string;
    settlementDate: string;
    healthIndex: string;
    lambda: string; // At minting date
    expectedPayout: string;
}

export interface NftMetadata {
    collateral: BigNumberish;
    mintTimestamp: BigNumberish;
    notional: BigNumberish;
    settlementTimestamp: BigNumberish;
    strike: BigNumberish;
}

export function populateNftEntry(
    nft: NftCollectionTokens,
    metadata: NftMetadata,
): NFTEntry {
    const { owner, tokenId: code } = nft;
    const { notional, strike, settlementTimestamp: settlementDate } = metadata;
    const date = new Date(Number(settlementDate) * 1000);
    const settlementDateFormatted = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;

    return {
        owner,
        code,
        notional: formatEther(notional),
        strike: formatNbToSI(strike?.toString()),
        settlementDate: settlementDateFormatted,
        status: [],
        healthIndex: '',
        lambda: '',
        expectedPayout: '',
    };
}
