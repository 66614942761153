import { mintCollateralTxAsync } from './collateral';
import { addLiquidityTxAsync, removeLiquidityTxAsync } from './liquidity';
import { swapOptionTxAsync } from './swap';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { buildStepsAsync } from './steps-builder';
import {
    ContractsInteractionsState,
    initialState,
    InteractionState,
    StepState,
} from './types';
import { writeAndAddLiquidityTxAsync, writeOptionTxAsync } from './write';
import { withdrawTxAsync } from './withdraw';
import { makeApprovalTxAsync } from './transaction-utils';
import { exerciseTxAsync } from './exercise';

export const moveContractInteractionStep = (
    state: ContractsInteractionsState,
) => {
    const { activeStep, steps } = state;
    const curStep = steps[activeStep - 1];
    if (curStep) {
        curStep.state = StepState.Success;
        const isLastStep = activeStep === steps.length;
        if (!isLastStep) state.activeStep += 1;
        else state.state = InteractionState.Finished;
    }
};

export const blockchainMessenger = createSlice({
    name: 'blockchainMessenger',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(buildStepsAsync.pending, (state, action) => {
                state = initialState;
            })
            .addCase(buildStepsAsync.rejected, (state, action) => {
                state = initialState;
            })
            .addCase(
                buildStepsAsync.fulfilled,
                (_, action: PayloadAction<ContractsInteractionsState>) => ({
                    ...action.payload,
                }),
            )

            .addCase(makeApprovalTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(makeApprovalTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })

            .addCase(writeOptionTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(writeOptionTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })

            .addCase(writeAndAddLiquidityTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(writeAndAddLiquidityTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })

            .addCase(swapOptionTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(swapOptionTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })

            .addCase(addLiquidityTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(addLiquidityTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })

            .addCase(removeLiquidityTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(removeLiquidityTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })

            .addCase(withdrawTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(withdrawTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })

            .addCase(exerciseTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(exerciseTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })

            .addCase(mintCollateralTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(mintCollateralTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            });
    },
});

export const selectBlockchainMessenger = (state: RootState) =>
    state.blockchainMessenger;

export default blockchainMessenger.reducer;
