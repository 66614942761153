import { ChartingLibraryWidgetOptions } from '@components/Chart/charting_library';

interface ChartContainerProps {
    symbol?: ChartingLibraryWidgetOptions['symbol'];
    interval?: ChartingLibraryWidgetOptions['interval'];

    // BEWARE: no trailing slash is expected in feed URL
    datafeedUrl: string;
    libraryPath: ChartingLibraryWidgetOptions['library_path'];
    chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
    chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
    clientId: ChartingLibraryWidgetOptions['client_id'];
    userId: ChartingLibraryWidgetOptions['user_id'];
    fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
    autosize: ChartingLibraryWidgetOptions['autosize'];
    studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
    containerId: ChartingLibraryWidgetOptions['container_id'];
}

export const chartOptions: ChartContainerProps = {
    containerId: 'tv_chart_container',
    datafeedUrl:
        'https://europe-west1-oiler-playground.cloudfunctions.net/TradingViewApi',
    libraryPath: '/charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
};

export const widgetOptions: ChartingLibraryWidgetOptions = {
    symbol: chartOptions.symbol as string,
    // BEWARE: no trailing slash is expected in feed URL
    // tslint:disable-next-line:no-any
    datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(
        chartOptions.datafeedUrl,
    ),
    interval: chartOptions.interval as ChartingLibraryWidgetOptions['interval'],
    container_id: chartOptions.containerId as ChartingLibraryWidgetOptions['container_id'],
    library_path: chartOptions.libraryPath as string,
    container: 'div',
    locale: 'en',
    disabled_features: [
        'use_localstorage_for_settings',
        'header_widget_dom_node',
        'header_symbol_search',
        'symbol_search_hot_key',
        'header_compare',
        'control_bar',
        'header_indicators',
        'border_around_the_chart',
        'header_saveload',
        'left_toolbar',
        'timeframes_toolbar',
        'legend_widget',
        'context_menus',
        'header_undo_redo',
        'header_resolutions',
    ],
    enabled_features: [''],
    charts_storage_url: chartOptions.chartsStorageUrl,
    charts_storage_api_version: chartOptions.chartsStorageApiVersion,
    client_id: chartOptions.clientId,
    user_id: chartOptions.userId,
    fullscreen: chartOptions.fullscreen,
    autosize: chartOptions.autosize,
    studies_overrides: chartOptions.studiesOverrides,
    custom_css_url: '/themed.css',
    loading_screen: {
        backgroundColor: '#000',
        foregroundColor: '#000',
    },
    overrides: {
        volumePaneSize: 'tiny',
        'paneProperties.background': 'black',
        'paneProperties.horzGridProperties.color': '#312e22',
        'mainSeriesProperties.priceLineColor': '#f3e7a9',
        'mainSeriesProperties.candleStyle.upColor': '#f3e7a9',
        'mainSeriesProperties.candleStyle.downColor': '#c51e48',
        'mainSeriesProperties.candleStyle.drawWick': true,
        'mainSeriesProperties.candleStyle.drawBorder': true,
        'mainSeriesProperties.candleStyle.borderColor': '#dc7891',
        'mainSeriesProperties.candleStyle.borderUpColor': '#928b65',
        'mainSeriesProperties.candleStyle.borderDownColor': '#ef5350',
        'mainSeriesProperties.candleStyle.wickUpColor': '#f3e7a9',
        'mainSeriesProperties.candleStyle.wickDownColor': '#c51e48',
        'mainSeriesProperties.candleStyle.barColorsOnPrevClose': false,
        'mainSeriesProperties.barStyle.upColor': '#f3e7a9',
        'mainSeriesProperties.barStyle.downColor': '#c51e48',
        'mainSeriesProperties.hollowCandleStyle.upColor': '#f3e7a9',
        'mainSeriesProperties.hollowCandleStyle.downColor': '#c51e48',
        'mainSeriesProperties.hollowCandleStyle.borderUpColor': '#928b65',
        'mainSeriesProperties.hollowCandleStyle.borderDownColor': '#ef5350',
        'mainSeriesProperties.haStyle.upColor': '#f3e7a9',
        'mainSeriesProperties.haStyle.downColor': '#c51e48',
        'mainSeriesProperties.haStyle.borderUpColor': '#928b65',
        'mainSeriesProperties.haStyle.borderDownColor': '#ef5350',
        'mainSeriesProperties.lineStyle.color': '#f3e7a9',
        'mainSeriesProperties.areaStyle.color1': 'rgba(243,231,169,0.28)',
        'mainSeriesProperties.areaStyle.color2': '#615c44',
        'mainSeriesProperties.areaStyle.linecolor': '#f3e7a9',
        'mainSeriesProperties.baselineStyle.baselineColor': '#f3e7a9',
        'mainSeriesProperties.baselineStyle.topFillColor1':
            'rgba(243,231,169,0.28)',
        'mainSeriesProperties.baselineStyle.topFillColor2':
            'rgba(243,231,169,0.1)',
        'mainSeriesProperties.baselineStyle.bottomFillColor1':
            'rgba(197,30,72,0.05)',
        'mainSeriesProperties.baselineStyle.bottomFillColor2': '#c51e48',
        'mainSeriesProperties.baselineStyle.topLineColor': '#f3e7a9',
        'mainSeriesProperties.baselineStyle.bottomLineColor': '#c51e48',
        'paneProperties.legendProperties.showStudyArguments': false,
        'paneProperties.legendProperties.showStudyTitles': false,
        'paneProperties.legendProperties.showStudyValues': false,
        'paneProperties.legendProperties.showSeriesTitle': false,
        'paneProperties.legendProperties.showSeriesOHLC': false,
        'paneProperties.legendProperties.showLegend': false,
        'paneProperties.legendProperties.showBarChange': false,
        'paneProperties.legendProperties.showOnlyPriceSource': false,
        'paneProperties.axisProperties.autoScale': true,
        'paneProperties.axisProperties.lockScale': false,
        'paneProperties.axisProperties.percentage': false,
        'paneProperties.axisProperties.indexedTo100': false,
        'paneProperties.axisProperties.log': false,
        'paneProperties.axisProperties.alignLabels': false,
        'paneProperties.axisProperties.isInverted': false,
        'scalesProperties.backgroundColor': '#f3e7a9',
        'scalesProperties.textColor': '#f3e7a9',
        'scalesProperties.lineColor': '#312e22',
    },
};

export const SUPPORTED_RESOLUTIONS = {
    '1': '1m',
    '15': '15m',
    '60': '1h',
    '1D': '1D',
    '1W': '1W',
    '1M': '1M',
};

export type KeyT = keyof typeof SUPPORTED_RESOLUTIONS;
