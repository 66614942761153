import React, { ReactNode } from 'react';
import { Backdrop, BackdropProps } from '@material-ui/core';

interface OilerBackdropProps extends BackdropProps {
    children: ReactNode;
}

const OilerBackdrop = (props: OilerBackdropProps) => {
    return (
        <Backdrop {...props}>
            {props.children}
        </Backdrop>
    )
}

export default OilerBackdrop;
