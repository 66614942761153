import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { initialState, TxState, TxType } from './types';

export const addTransactionAsync = createAsyncThunk(
    'transactions/add-tx',
    async (payload: any, { dispatch }) => {
        try {
            dispatch(
                addTransaction({
                    type: payload.type || TxType.Unknown,
                    state: TxState.Pending,
                    hash: payload.hash,
                    url: `${process.env.REACT_APP_ETHERSCAN_URL}/tx/${payload.hash}`,
                    nonce: payload.nonce ?? -1,
                    chainId: payload.chainId ?? -1,
                }),
            );

            const tx = await payload.wait();

            dispatch(
                updateTransaction({
                    type: payload.type || TxType.Unknown,
                    state: tx.status === 1 ? TxState.Success : TxState.Fail,
                    hash: tx.transactionHash ?? '',
                    url: tx.transactionHash
                        ? `${process.env.REACT_APP_ETHERSCAN_URL}/tx/${tx.transactionHash}`
                        : '',
                    nonce: payload.nonce ?? -1,
                    chainId: payload.chainId ?? -1,
                }),
            );
        } catch (err) {
            dispatch(
                updateTransaction({
                    type: payload.type || TxType.Unknown,
                    state: TxState.Fail,
                    hash: '',
                    url: '',
                    nonce: -1,
                    chainId: -1,
                }),
            );
        }
    },
);

export const transactions = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        addTransaction(state, action) {
            state.transactions = [action.payload, ...state.transactions];
        },
        updateTransaction(state, action) {
            const transactions = state.transactions.slice();
            const index = transactions.findIndex(
                (x) => x.hash === action.payload.hash,
            );
            transactions.splice(index, 1, action.payload);

            state.transactions = transactions;
        },
    },
    extraReducers: (builder) => {},
});

export const selectTransactions = (state: RootState) => state.transactions;
export const { addTransaction, updateTransaction } = transactions.actions;
export default transactions.reducer;
