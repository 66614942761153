import React, { useCallback, useEffect, useState } from 'react';
import OilerBox from '@oilerKit/OilerBox';
import OilerTableHead, {
    oilerSortRows,
} from '@oilerKit/OilerTable/OilerTableHead';
import OilerTableRow from '@oilerKit/OilerTable/OilerTableRow';
import OilerTableCell from '@oilerKit/OilerTable/OilerTableCell';
import OilerTableBody from '@oilerKit/OilerTable/OilerTableBody';
import OilerToggleButtonGroup, {
    OilerToggleButton,
} from '@oilerKit/OilerToggleButtonGroup';
import {
    OilerTable,
    OilerTableContainer,
} from '@oilerKit/OilerTable/OilerTable';
import { SortOrder } from '@constants/index';
import { OilerOption } from '@features/options/types';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchDbOptionsDataAsync,
    selectOptions,
    setDisplayOption,
} from '@features/options/optionsSlice';
import { OilerAlert } from '@oilerKit/OilerAlert';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import styled from 'styled-components';
import {
    comparators,
    tableColumns,
} from '@components/OptionsTable/optionsTableConfig';
import OilerChip from '@oilerKit/OilerChip';

const StyledOilerToggleButtonGroup = styled(OilerToggleButtonGroup)`
    position: relative;
    top: 2px;
    overflow-x: auto;
    width: 100%;
`;

const OptionsTable = () => {
    const dispatch = useDispatch();

    const { historicalOptions, displayOption, tradingOption } =
        useSelector(selectOptions);

    const [selectedRow, setSelectedRow] = useState<null | string>(
        displayOption?.address || null,
    );

    enum ActiveFilters {
        ALL = 'All',
        ACTIVE = 'Active',
        OWNED = 'Owned',
        EXERCISED = 'Exercised',
        EXPIRED = 'Expired',
        WITHDRAWABLE = 'Withdrawable',
        LP = 'LP',
    }

    const currentHistoricals = historicalOptions[tradingOption];

    const [rowsView, setRowsView] = useState<OilerOption[]>(currentHistoricals);
    const [activeFilter, setActiveFilter] = useState<string>(
        ActiveFilters.ACTIVE,
    );

    const [orderBy, setOrderBy] = useState('Status');
    const [order, setOrder] = useState<SortOrder>(SortOrder.DESC);

    useEffect(() => {
        setSelectedRow(displayOption?.address || null);
    }, [displayOption]);

    useEffect(() => {
        if (activeFilter === ActiveFilters.ALL) {
            setRowsView(currentHistoricals);
            return;
        }

        setRowsView(
            currentHistoricals.filter((x: any) =>
                x.status.includes(activeFilter),
            ),
        );
    }, [currentHistoricals, activeFilter, ActiveFilters.ALL]);

    const selectDisplayOption = useCallback(
        (optionAddress: string) => {
            dispatch(setDisplayOption(optionAddress));
        },
        [dispatch],
    );

    const refreshHistoricalOptions = () => {
        dispatch(fetchDbOptionsDataAsync('H'));
    };

    const placeholder = '---';

    return (
        <>
            <StyledOilerToggleButtonGroup color={'primary'} exclusive>
                <OilerToggleButton
                    value={ActiveFilters.ALL}
                    selected={activeFilter === ActiveFilters.ALL}
                    onClick={() => {
                        setActiveFilter(ActiveFilters.ALL);
                        setRowsView(currentHistoricals);
                    }}
                >
                    ALL
                </OilerToggleButton>
                <OilerToggleButton
                    value={ActiveFilters.ACTIVE}
                    selected={activeFilter === ActiveFilters.ACTIVE}
                    onClick={() => {
                        setActiveFilter(ActiveFilters.ACTIVE);
                        setRowsView(
                            currentHistoricals.filter((x: any) =>
                                x.status.includes(ActiveFilters.ACTIVE),
                            ),
                        );
                    }}
                >
                    ACTIVE
                </OilerToggleButton>
                <OilerToggleButton
                    value={ActiveFilters.OWNED}
                    selected={activeFilter === ActiveFilters.OWNED}
                    onClick={() => {
                        setActiveFilter(ActiveFilters.OWNED);
                        setRowsView(
                            currentHistoricals.filter((x: any) =>
                                x.status.includes(ActiveFilters.OWNED),
                            ),
                        );
                    }}
                >
                    OWNED
                </OilerToggleButton>
                <OilerToggleButton
                    value={ActiveFilters.EXERCISED}
                    selected={activeFilter === ActiveFilters.EXERCISED}
                    onClick={() => {
                        setActiveFilter(ActiveFilters.EXERCISED);
                        setRowsView(
                            currentHistoricals.filter((x: any) =>
                                x.status.includes(ActiveFilters.EXERCISED),
                            ),
                        );
                    }}
                >
                    EXERCISED
                </OilerToggleButton>
                <OilerToggleButton
                    value={ActiveFilters.EXPIRED}
                    selected={activeFilter === ActiveFilters.EXPIRED}
                    onClick={() => {
                        setActiveFilter(ActiveFilters.EXPIRED);
                        setRowsView(
                            currentHistoricals.filter((x: any) =>
                                x.status.includes(ActiveFilters.EXPIRED),
                            ),
                        );
                    }}
                >
                    EXPIRED
                </OilerToggleButton>
                <OilerToggleButton
                    value={ActiveFilters.WITHDRAWABLE}
                    selected={activeFilter === ActiveFilters.WITHDRAWABLE}
                    onClick={() => {
                        setActiveFilter(ActiveFilters.WITHDRAWABLE);
                        setRowsView(
                            currentHistoricals.filter((x: any) =>
                                x.status.includes(ActiveFilters.WITHDRAWABLE),
                            ),
                        );
                    }}
                >
                    WITHDRAWABLE
                </OilerToggleButton>
                <OilerToggleButton
                    value={ActiveFilters.LP}
                    selected={activeFilter === ActiveFilters.LP}
                    onClick={() => {
                        setActiveFilter(ActiveFilters.LP);
                        setRowsView(
                            currentHistoricals.filter((x: any) =>
                                x.status.includes(ActiveFilters.LP),
                            ),
                        );
                    }}
                >
                    LP
                </OilerToggleButton>
            </StyledOilerToggleButtonGroup>
            <OilerBox>
                <OilerTableContainer>
                    <OilerTable stickyHeader size={'small'}>
                        <OilerTableHead
                            columns={tableColumns}
                            order={order}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            setOrder={setOrder}
                        />
                        {rowsView.length !== 0 && (
                            <OilerTableBody>
                                {oilerSortRows(
                                    rowsView,
                                    order,
                                    orderBy,
                                    comparators,
                                ).map((row: OilerOption, idx: number) => (
                                    <OilerTableRow
                                        hover
                                        key={`${row.code}-${idx}`}
                                        selected={selectedRow === row.address}
                                        onClick={() =>
                                            selectDisplayOption(row.address)
                                        }
                                    >
                                        <OilerTableCell
                                            loading={row.code === placeholder}
                                        >
                                            {row.code}
                                        </OilerTableCell>
                                        <OilerTableCell
                                            loading={
                                                row.status[0] === placeholder
                                            }
                                        >
                                            <OilerChip
                                                size={'small'}
                                                label={row.status.join(' | ')}
                                            />
                                        </OilerTableCell>
                                        <OilerTableCell
                                            loading={row.type === placeholder}
                                        >
                                            {row.type}
                                        </OilerTableCell>
                                        <OilerTableCell
                                            loading={
                                                row.expDate === placeholder
                                            }
                                        >
                                            {row.expDate + ' ' + row.expTime}
                                        </OilerTableCell>
                                        <OilerTableCell
                                            loading={row.strike === placeholder}
                                        >
                                            {row.strike + row.strikeMetric}
                                        </OilerTableCell>
                                        <OilerTableCell
                                            loading={row.held === placeholder}
                                        >
                                            {row.held}
                                        </OilerTableCell>
                                        <OilerTableCell
                                            loading={
                                                row.written === placeholder
                                            }
                                        >
                                            {row.written}
                                        </OilerTableCell>
                                        <OilerTableCell
                                            loading={
                                                row.withdrawable === placeholder
                                            }
                                        >
                                            {row.withdrawable}
                                        </OilerTableCell>
                                        {/*<OilerTableCell*/}
                                        {/*    loading={*/}
                                        {/*        row.lpTokensBalanceFormatted ===*/}
                                        {/*        placeholder*/}
                                        {/*    }*/}
                                        {/*>*/}
                                        {/*    {row.lpTokensBalanceFormatted}*/}
                                        {/*</OilerTableCell>*/}
                                        {/*<OilerTableCell*/}
                                        {/*    loading={*/}
                                        {/*        row.unrealizedPnl[0] ===*/}
                                        {/*        placeholder*/}
                                        {/*    }*/}
                                        {/*>*/}
                                        {/*    {row.unrealizedPnl[0]}%*/}
                                        {/*</OilerTableCell>*/}
                                        {/*<OilerTableCell*/}
                                        {/*    loading={*/}
                                        {/*        row.realizedPnl[0] ===*/}
                                        {/*        placeholder*/}
                                        {/*    }*/}
                                        {/*>*/}
                                        {/*    {row.realizedPnl[0]}%*/}
                                        {/*</OilerTableCell>*/}
                                        {/*<OilerTableCell*/}
                                        {/*    loading={*/}
                                        {/*        row.fullPnl[0] === placeholder*/}
                                        {/*    }*/}
                                        {/*>*/}
                                        {/*    {row.fullPnl[0]}%*/}
                                        {/*</OilerTableCell>*/}
                                    </OilerTableRow>
                                ))}
                            </OilerTableBody>
                        )}
                    </OilerTable>
                    {rowsView.length === 0 &&
                        currentHistoricals.length !== 0 && (
                            <OilerAlert severity="info">
                                No {activeFilter} options found
                            </OilerAlert>
                        )}
                    {currentHistoricals.length === 0 && (
                        <OilerAlert
                            severity="info"
                            action={
                                <OilerButton
                                    size={'small'}
                                    variant={'text'}
                                    onClick={refreshHistoricalOptions}
                                >
                                    REFRESH
                                </OilerButton>
                            }
                        >
                            No historical options found
                        </OilerAlert>
                    )}
                </OilerTableContainer>
            </OilerBox>
        </>
    );
};
export default OptionsTable;
