import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from '@store/index';
import { ContractsInteractionsState, initialState } from '../types';
import {
    buildBuySellSteps,
    goShortAsync,
    goLongAsync,
    buildLiquidateSteps,
    buildSettleSteps,
    liquidateTxAsync,
    settleTxAsync,
    buildAddLiquiditySteps,
    buildRemoveLiquiditySteps,
    buildAddCollateralSteps,
    buildRemoveCollateralSteps,
    addLiquidityTxAsync,
    removeLiquidityTxAsync,
    addCollateralTxAsync,
    removeCollateralTxAsync,
} from './actions';
import { openModal } from '@features/ui/uiSlice';
import { dispatchTxStatus } from '../transaction-utils';

export enum InteractionName {
    GO_LONG = 'Open Long Position',
    GO_SHORT = 'Open Short Position',
    LIQUIDATE = 'LIQUIDATE',
    SETTLE = 'SETTLE',
    ADD_LIQUIDITY = 'ADD_LIQUIDITY',
    REMOVE_LIQUIDITY = 'REMOVE_LIQUIDITY',
    ADD_COLLATERAL = 'ADD_COLLATERAL',
    REMOVE_COLLATERAL = 'REMOVE_COLLATERAL',
    CLEAR_STEPS = 'CLEAR_STEPS',
}

export const buildStepsAsync = createAsyncThunk<
    ContractsInteractionsState,
    { interactionName: InteractionName; [rest: string]: any },
    {
        dispatch: AppDispatch;
    }
>(
    'contracts-interactions/forwards/build-steps',
    async (payload, { dispatch, getState }) => {
        const { interactionName } = payload;

        let state;

        switch (interactionName) {
            case InteractionName.GO_LONG:
                state = await buildBuySellSteps({ ...payload, isLong: true });
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.GO_SHORT:
                state = await buildBuySellSteps({ ...payload, isLong: false });
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.LIQUIDATE:
                state = await buildLiquidateSteps({ ...payload });
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.SETTLE:
                state = await buildSettleSteps({ ...payload });
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.ADD_LIQUIDITY:
                state = await buildAddLiquiditySteps({ ...payload });
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.REMOVE_LIQUIDITY:
                state = await buildRemoveLiquiditySteps({ ...payload });
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.ADD_COLLATERAL:
                state = await buildAddCollateralSteps({ ...payload });
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.REMOVE_COLLATERAL:
                state = await buildRemoveCollateralSteps({ ...payload });
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.CLEAR_STEPS:
                return initialState;
            default:
                console.error(`No such interaction: "${interactionName}"`);
                return initialState;
        }
    },
);

export const processStepsAsync = createAsyncThunk(
    'contracts-interactions/forwards/processSteps',
    async (payload: any, { dispatch, getState }) => {
        const { steps } = payload;

        const { account } = (getState() as any).wallet;
        const { modal } = (getState() as any).ui;
        let res;

        if (modal.state) {
            dispatch(openModal({ state: false, type: null }));
        }

        for (const step of steps) {
            switch (step.name) {
                case 'Go Long':
                    res = await dispatch(
                        goLongAsync({
                            ...payload,
                        }),
                    );
                    break;
                case 'Go Short':
                    res = await dispatch(
                        goShortAsync({
                            ...payload,
                        }),
                    );
                    break;
                case 'Liquidate':
                    res = await dispatch(liquidateTxAsync(null));
                    break;
                case 'Settle':
                    res = await dispatch(settleTxAsync(null));
                    break;
                case 'Add Liquidity':
                    res = await dispatch(
                        addLiquidityTxAsync({
                            ...payload,
                        }),
                    );
                    break;
                case 'Remove Liquidity':
                    res = await dispatch(
                        removeLiquidityTxAsync({
                            ...payload,
                        }),
                    );
                    break;
                case 'Add Collateral':
                    res = await dispatch(
                        addCollateralTxAsync({
                            ...payload,
                        }),
                    );
                    break;
                case 'Remove Collateral':
                    res = await dispatch(
                        removeCollateralTxAsync({
                            ...payload,
                        }),
                    );
                    break;

                default:
                    throw new Error(`No such step: "${step.name}"`);
            }

            dispatchTxStatus(res, account, dispatch);
        }
    },
);
