import OilerInput from '@oilerKit/OilerInput';
import OilerGrid from '@oilerKit/OilerGrid';
import React, { useState } from 'react';
import OilerModal from '@oilerKit/OilerModal';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import OilerToggleButtonGroup, {
    OilerToggleButton,
} from '@oilerKit/OilerToggleButtonGroup';
import { isValidNumber } from '@utils/isValidNumber';
import { ActionModalProps } from '@features/ui/types';
import NFTsSelect, {
    NTFSelectItem,
} from '@components/TradingActions/Forwards/LiquidityModal/NFTsSelect';
import { OilerAlert } from '@oilerKit/OilerAlert';
import {
    buildStepsAsync,
    InteractionName,
} from '@features/contracts-interactions/forwards/steps-builder';
import { selectWallet } from '@features/wallet/walletSlice';
import { selectFirebase } from '@features/firebase/firebaseSlice';

const StyledOilerModal = styled(OilerGrid)`
    ${(props) => props.theme.breakpoints.up('sm')} {
        min-height: 322px;
        width: 272px;
    }
    margin: auto;
`;

interface LiquidityModalProps {
    open: boolean;
    // eslint-disable-next-line
    setOpenActionModal: ({}: ActionModalProps) => void;
}
const NFTsList: NTFSelectItem[] = [
    {
        title: '0x454...2345',
        value: 'a',
        size: '200k USD',
    },
    {
        title: '0x454...2345',
        value: 'b',
        size: '100k USD',
    },
    {
        title: '0x454...2349',
        value: 'c',
        size: '50k USD',
    },
];

const LiquidityModal = (props: LiquidityModalProps) => {
    const { open, setOpenActionModal } = props;

    const dispatch = useDispatch();

    const { account } = useSelector(selectWallet);
    const { displayNftToken } = useSelector(selectFirebase);

    const [addLiquidityMode, setAddLiquidityMode] = useState<string>(
        InteractionName.ADD_LIQUIDITY,
    );

    const [lpTokensInputAmount, setLpTokensInputAmount] = useState<string>('0');

    const handleLpTokensInputChange = (value: string) => {
        setLpTokensInputAmount(value);
    };

    const handleAddLiquidity = async () => {
        setOpenActionModal({ state: false, type: null });
        dispatch(
            buildStepsAsync({
                interactionName: InteractionName.ADD_LIQUIDITY,
                amount: lpTokensInputAmount,
                account,
            }),
        );
    };

    const handleRemoveLiquidity = async () => {
        setOpenActionModal({ state: false, type: null });
        dispatch(
            buildStepsAsync({
                interactionName: InteractionName.REMOVE_LIQUIDITY,
                tokenId: displayNftToken?.code,
                account,
            }),
        );
    };

    const handleLiquidityMode = (
        event: React.MouseEvent<HTMLElement>,
        liquidityMode: string,
    ) => setAddLiquidityMode(liquidityMode);

    const HeaderAction = (
        <OilerToggleButtonGroup
            value={addLiquidityMode}
            onChange={handleLiquidityMode}
            exclusive
            color={'black'}
            fullWidth
        >
            <OilerToggleButton value={InteractionName.ADD_LIQUIDITY}>
                ADD
            </OilerToggleButton>
            <OilerToggleButton value={InteractionName.REMOVE_LIQUIDITY}>
                REMOVE
            </OilerToggleButton>
        </OilerToggleButtonGroup>
    );

    return (
        <OilerModal
            open={open}
            onClose={() => setOpenActionModal({ state: false, type: null })}
            title={'LIQUIDITY'}
            headerAction={HeaderAction}
        >
            {addLiquidityMode === InteractionName.ADD_LIQUIDITY ? (
                <StyledOilerModal
                    container
                    direction="column"
                    alignItems={'center'}
                    justify="space-between"
                >
                    <OilerAlert severity={'info'}>
                        You have provided 0 TestUSDC as Liquidity
                    </OilerAlert>

                    <OilerInput
                        mt={4}
                        fullWidth
                        label={`TestUSDC`}
                        handleInputChange={handleLpTokensInputChange}
                        inputAmount={lpTokensInputAmount}
                    />

                    <OilerButton
                        fullWidth
                        size={'large'}
                        disabled={
                            !isValidNumber(lpTokensInputAmount) ||
                            Number(lpTokensInputAmount) === 0
                        }
                        mt={4}
                        onClick={handleAddLiquidity}
                        color={'secondary'}
                    >
                        ADD LIQUIDITY
                    </OilerButton>
                </StyledOilerModal>
            ) : (
                <StyledOilerModal
                    direction="column"
                    alignItems={'center'}
                    justify="center"
                >
                    <NFTsSelect items={NFTsList} />

                    <OilerInput
                        mt={4}
                        fullWidth
                        label={`Remove LP Tokens to Withdraw Liquidity`}
                        handleInputChange={handleLpTokensInputChange}
                        inputAmount={lpTokensInputAmount}
                    />

                    <OilerButton
                        fullWidth
                        size={'large'}
                        disabled={
                            !isValidNumber(lpTokensInputAmount) ||
                            Number(lpTokensInputAmount) === 0
                        }
                        mt={4}
                        onClick={handleRemoveLiquidity}
                        color={'secondary'}
                    >
                        REMOVE LIQUIDITY
                    </OilerButton>
                </StyledOilerModal>
            )}
        </OilerModal>
    );
};

export default LiquidityModal;
