import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { initialState } from './types';

export const ui = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        makeUIToast: (uiState, action) => {
            uiState.toast.content = action.payload.content;
            uiState.toast.options = action.payload.options;
            uiState.toast.callback = action.payload.callback;
        },
        openModal: (uiState, action) => {
            uiState.modal = action.payload;
        },
    },
});

export const { makeUIToast, openModal } = ui.actions;

export const selectUI = (state: RootState) => state.ui;

export default ui.reducer;
