import React, { useEffect, useState } from 'react';
import OilerModal from '@oilerKit/OilerModal';
import styled from 'styled-components';
import OilerStepper from '@oilerKit/OilerStepper';
import { useSelector } from 'react-redux';
import { InteractionState } from '@features/contracts-interactions/types';
import { selectBlockchainMessenger } from '@features/contracts-interactions/blockchainMessengerSlice';
import { selectTransactions } from '@features/transactions/transactionsSlice';
import { TxState } from '@features/transactions/types';
import { selectForwardsMessenger } from '@features/contracts-interactions/forwards/forwardsMessengerSlice';

const Wrapper = styled.div<any>`
    max-height: 250px;
    overflow-y: auto;
    padding: 20px 0;
`;

interface TransactionsStepperProps {
    onHide: () => void;
}

export const TransactionsStepper = (props: TransactionsStepperProps) => {
    const { onHide } = props;
    const { stepsNames, activeStep, state: stepsState } = useSelector(
        selectBlockchainMessenger,
    );
    const {
        stepsNames: stepsNamesForwards,
        activeStep: activeStepForwards,
        state: stepsStateForwards,
    } = useSelector(selectForwardsMessenger);
    const { transactions = [] } = useSelector(selectTransactions);

    const [pendingTx, setPendingTx] = useState<string | undefined>();

    useEffect(() => {
        setPendingTx(
            transactions[0] && transactions[0].state === TxState.Pending
                ? transactions[0]?.url
                : undefined,
        );
    }, [transactions]);

    return (
        <OilerModal
            open={
                stepsState === InteractionState.Active ||
                stepsStateForwards === InteractionState.Active
            }
            onClose={onHide}
            // title={'CONTRACT INTERACTIONS'}
        >
            <Wrapper>
                <OilerStepper
                    size={'lg'}
                    activeStep={
                        activeStep !== 0 ? activeStep : activeStepForwards
                    }
                    steps={stepsNames.length ? stepsNames : stepsNamesForwards}
                    pendingTx={pendingTx}
                />
            </Wrapper>
        </OilerModal>
    );
};
