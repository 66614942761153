import React, { ReactNode } from 'react';
import styled from 'styled-components';
import OilerTypography from '@oilerKit/OilerTypography';
import { Transaction, TxState } from '@features/transactions/types';
import { formatAddr } from '@utils/formatAddr';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import ScheduleSharpIcon from '@material-ui/icons/ScheduleSharp';
import HourglassFullSharpIcon from '@material-ui/icons/HourglassFullSharp';
import WarningSharpIcon from '@material-ui/icons/WarningSharp';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import OilerTooltip from '@oilerKit/OilerTooltip';

const StyledTransactionDescription = styled.div`
    display: flex;
    align-items: center;
`;

const Description = styled.div`
    margin-left: 24px;
`;

interface TransactionDescriptionProps {
    tx: Transaction;
}

const txStatesMap: {
    [key in TxState]: ReactNode;
} = {
    IDLE: <ScheduleSharpIcon />,
    PENDING: <HourglassFullSharpIcon />,
    SUCCESS: <CheckSharpIcon />,
    FAIL: <WarningSharpIcon />,
    REJECTED: <CancelSharpIcon />,
};

const TransactionDescription = ({ tx }: TransactionDescriptionProps) => {
    return (
        <StyledTransactionDescription>
            <OilerTooltip title={tx.state.toString()}>
                <div>{txStatesMap[tx.state as TxState]}</div>
            </OilerTooltip>
            <Description>
                <OilerTypography weight={'bold'}>
                    {tx.type.toString().replace('_', ' ')}{' '}
                </OilerTypography>
                <OilerTypography>
                    {formatAddr(tx.hash as string)}
                </OilerTypography>
            </Description>
        </StyledTransactionDescription>
    );
};

export default TransactionDescription;
