import OilerTypography from '@oilerKit/OilerTypography';
import OilerGrid from '@oilerKit/OilerGrid';
import OilerModal from '@oilerKit/OilerModal';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { OilerOption, tradingOptionsInfo } from '@features/options/types';
import { OilerAlert, OilerAlertTitle } from '@oilerKit/OilerAlert';
import {
    buildStepsAsync,
    InteractionName,
} from '@features/contracts-interactions/steps-builder';
import { ActionModalProps } from '@features/ui/types';

const StyledButton = styled(OilerButton)`
    ${(props) => props.theme.breakpoints.up('sm')} {
        width: 272px;
    }
`;

interface ExerciseModalProps {
    open: boolean;
    option: OilerOption;
    // eslint-disable-next-line
    setOpenActionModal: ({}: ActionModalProps) => void;
}

const ExerciseModal = (props: ExerciseModalProps) => {
    const { open, setOpenActionModal, option } = props;

    const dispatch = useDispatch();

    const handleExerciseClick = () => {
        dispatch(
            buildStepsAsync({
                interactionName: InteractionName.EXERCISE,
                optionAddress: option.address,
                exercisableBlockNumber: option.exercisableAtBlock,
            }),
        );
    };

    return (
        <OilerModal
            open={open}
            onClose={() => setOpenActionModal({ state: false, type: null })}
            title={'EXERCISE'}
            noScroll
        >
            <OilerGrid container direction="column" alignItems="center">
                <OilerAlert severity={'info'}>
                    <OilerAlertTitle>
                        {(tradingOptionsInfo as any)[option.family].name}{' '}
                        {option.code} OPTION
                    </OilerAlertTitle>
                    The exercise transaction only needs to be executed once for
                    all owners of the options of this type. If you are afraid
                    that nobody will exercise this option type then you can do
                    it yourself by pressing the button below.
                </OilerAlert>

                <OilerTypography mt={2} color={'secondary'}>
                    Expiring at {option.expDate} {option.expTime}
                </OilerTypography>

                <StyledButton
                    fullWidth
                    size={'large'}
                    mt={4}
                    onClick={handleExerciseClick}
                    color="secondary"
                >
                    EXERCISE
                </StyledButton>
            </OilerGrid>
        </OilerModal>
    );
};

export default ExerciseModal;
