import React from 'react';
import styled from 'styled-components';
import { Tooltip, TooltipProps } from '@material-ui/core';

const StyledTooltip = styled((props) => (
    <Tooltip classes={{ popper: props.className }} {...props}>
        {props.children}
    </Tooltip>
))`
    & .MuiTooltip-tooltip {
        background-color: ${(props) => props.theme.palette.common.white};
        color: ${(props) => props.theme.palette.secondary.contrastText};
        border: 1px solid
            ${(props) => props.theme.palette.secondary.contrastText};
        border-radius: 0;
    }
    & .MuiTooltip-arrow {
        color: ${(props) => props.theme.palette.common.white};
    }

    &&& {
        cursor: ${(props) => props.title.length !== 0 && 'help'}; !important;
    }
`;

const OilerTooltip = (props: TooltipProps) => {
    return (
        <StyledTooltip arrow {...props}>
            {props.children}
        </StyledTooltip>
    );
};

export default OilerTooltip;
