import OilerGrid from '@oilerKit/OilerGrid';
import { ActionModalProps, ModalType } from '@features/ui/types';
import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import TradeModal from '@components/TradingActions/Forwards/TradeModal/TradeModal';
import IsoSharpIcon from '@material-ui/icons/IsoSharp';
import { useMediaQuery, useTheme } from '@material-ui/core';
import LiquidityModal from '@components/TradingActions/Forwards/LiquidityModal/LiquidityModal';

const StyledOilerButton = styled(OilerButton)`
    ${(props) => props.theme.breakpoints.up('sm')} {
        //min-width: 180px;
    }
`;

const getModalContent = (
    type: ModalType | null,
    open: boolean,
    setOpenActionModal: Dispatch<SetStateAction<ActionModalProps>>,
) => {
    switch (type) {
        case ModalType.TRADE:
            return (
                <TradeModal
                    open={open}
                    setOpenActionModal={setOpenActionModal}
                />
            );
        case ModalType.LIQUIDITY:
            return (
                <LiquidityModal
                    open={open}
                    setOpenActionModal={setOpenActionModal}
                />
            );
        case null:
            return <></>;
    }
};

const PoolActions = () => {
    const [openActionModal, setOpenActionModal] = useState<ActionModalProps>({
        state: false,
        type: ModalType.TRADE,
    });
    const theme = useTheme();
    const isXsSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <OilerGrid
                direction={isXsSmallScreen ? 'column' : 'row'}
                alignItems={isXsSmallScreen ? 'stretch' : 'center'}
                justifyContent={'center'}
                spacing={2}
                container
            >
                <OilerGrid
                    container
                    md
                    sm={6}
                    xs={12}
                    item
                    justifyContent={'flex-end'}
                >
                    <StyledOilerButton
                        // disabled
                        fullWidth={isSmallScreen}
                        onClick={() =>
                            setOpenActionModal({
                                state: true,
                                type: ModalType.TRADE,
                            })
                        }
                        color={'secondary'}
                    >
                        TRADE
                    </StyledOilerButton>
                </OilerGrid>
                <OilerGrid
                    container
                    md
                    sm={6}
                    xs={12}
                    item
                    justifyContent={'flex-start'}
                >
                    <StyledOilerButton
                        fullWidth={isSmallScreen}
                        onClick={() =>
                            setOpenActionModal({
                                state: true,
                                type: ModalType.LIQUIDITY,
                            })
                        }
                        color={'secondary'}
                        endIcon={<IsoSharpIcon />}
                    >
                        LIQUIDITY
                    </StyledOilerButton>
                </OilerGrid>
            </OilerGrid>

            {getModalContent(
                openActionModal.type,
                openActionModal.state,
                setOpenActionModal,
            )}
        </>
    );
};

export default PoolActions;
