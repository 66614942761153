import { Skeleton, SkeletonProps } from '@material-ui/lab';
import React from 'react';
import styled from 'styled-components';

const StyledSkeleton = styled(Skeleton)`
    &.MuiSkeleton-text {
        border-radius: 0;
    }
`;

const OilerSkeleton = (props: SkeletonProps) => {
    return <StyledSkeleton {...props} />;
};

export default OilerSkeleton;
