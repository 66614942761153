import React, { useState } from 'react';
import OilerToggleButtonGroup, {
    OilerToggleButton,
} from '@oilerKit/OilerToggleButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { changeSlippage, selectOptions } from '@features/options/optionsSlice';
import OilerInput from '@oilerKit/OilerInput';
import styled from 'styled-components';
import { initialState } from '@features/options/types';

const StyledOilerInput = styled(OilerInput)`
    width: 70px;
    min-height: 36px;
    padding: 4px 8px;
    padding-right: 25px;
    border: 2px solid ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.black};
    font-size: 0.875rem;
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    &.active {
        background: ${(props) => props.theme.palette.common.black};
        color: ${(props) => props.theme.palette.primary.contrastText};
    }
    &.active:hover {
        background: ${(props) => props.theme.palette.primary.contrastText};
    }
    &:hover {
        background: ${(props) => props.theme.palette.primary[700]};
        color: ${(props) => props.theme.palette.common.black};
    }
    &:focus {
        border-color: ${(props) => props.theme.palette.primary.contrastText};
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const SlippageInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    &.active {
        div,
        input {
            color: ${(props) => props.theme.palette.primary.main} !important;
        }
    }
    &:hover {
        background: ${(props) => props.theme.palette.primary[700]};
        color: ${(props) => props.theme.palette.primary.main};
    }
`;

const StyledOilerToggleButton = styled(OilerToggleButton)`
    &.Mui-disabled {
        color: ${(props) => props.theme.palette.primary.main};
    }
`;

const Prefix = styled.div`
    position: absolute;
    right: 25px;
    margin-top: 8px;
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    color: ${(props) => props.theme.palette.common.black};
`;

const Slippage = () => {
    const dispatch = useDispatch();

    const { slippage } = useSelector(selectOptions);

    const mainValues = [1, 2, 5];
    const customSlippage = !mainValues.includes(slippage);

    const [customSlippageInput, setCustomSlippageInput] = useState(
        customSlippage ? slippage.toString() : '',
    );

    return (
        <Wrapper>
            <OilerToggleButtonGroup
                value={slippage.toString()}
                onChange={(_, newSlippage) => {
                    dispatch(changeSlippage(+newSlippage));
                }}
                exclusive
                fullWidth
                mr={3}
                color={'black'}
            >
                <StyledOilerToggleButton disabled={slippage === 1} value="1">
                    1%
                </StyledOilerToggleButton>
                <StyledOilerToggleButton disabled={slippage === 2} value="2">
                    2%
                </StyledOilerToggleButton>
                <StyledOilerToggleButton disabled={slippage === 5} value="5">
                    5%
                </StyledOilerToggleButton>
            </OilerToggleButtonGroup>
            <SlippageInputWrapper className={customSlippage ? 'active' : ''}>
                <StyledOilerInput
                    placeholder={initialState.slippage.toString()}
                    className={customSlippage ? 'active' : ''}
                    onClick={() =>
                        dispatch(changeSlippage(+customSlippageInput))
                    }
                    handleInputChange={(value) => {
                        setCustomSlippageInput(value);

                        if (value.trim() === '') {
                            dispatch(changeSlippage(+initialState.slippage));
                        } else {
                            dispatch(changeSlippage(+value));
                        }
                    }}
                    inputAmount={customSlippageInput}
                />
                <Prefix>%</Prefix>
            </SlippageInputWrapper>
        </Wrapper>
    );
};
export default Slippage;
