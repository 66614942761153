import styled from 'styled-components';
import OilerIcon from '@oilerKit/OilerIcon';
import OilerLogo from '@oilerKit/assets/oiler-logo.svg';
import React from 'react';

const StyledBootstrapNavBrand = styled.div`
    margin-top: 38px;
    margin-bottom: 24px;
    width: 250px;

    &:hover {
        cursor: pointer;
    }
`;

const Logo = () => {
    return (
        <StyledBootstrapNavBrand onClick={() => (window.location.href = '/')}>
            <OilerIcon icon={OilerLogo} alt="Oiler logo" height={75} />
        </StyledBootstrapNavBrand>
    );
};

export default Logo;
