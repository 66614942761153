import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContractsProvider } from '@services/contracts-provider';
import { ContractType } from '@services/contracts-types';
import {
    ContractsInteractionsState,
    InteractionState,
    Step,
    StepState,
} from './types';
import invariant from 'tiny-invariant';
import { BigNumber, utils } from 'ethers';
import { addTransactionAsync } from '@features/transactions/transactionsSlice';
import { TxType } from '@features/transactions/types';
import { parseUnits } from '@ethersproject/units';

export const buildWriteSteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const { optionAddress, allowanceAmount, addLiquidity } = payload;

    const contractsProvider = getContractsProvider();

    const collateralContract = getContractsProvider().findOrCreate(
        ContractType.Collateral,
    );
    invariant(collateralContract, 'No collateral contract set');

    const optionContract = getContractsProvider().findOrCreate(
        ContractType.Option,
        optionAddress,
    );
    invariant(optionContract, 'No option contract set');

    const userAddr = await contractsProvider.getAccount();
    invariant(userAddr, 'No user address set');

    const givenCollateralAllowance = await collateralContract.allowance(
        userAddr,
        process.env.REACT_APP_OILER_OPTIONS_ROUTER_ADDRESS,
    );

    const minRequired = utils.parseEther(allowanceAmount.toString());
    const collateralNeedsApproval = BigNumber.from(
        givenCollateralAllowance.toString(),
    ).lt(minRequired);

    const steps = [] as Step[];

    if (collateralNeedsApproval) {
        steps.push({ name: 'Approve Collateral', state: StepState.Idle });
    }

    if (addLiquidity) {
        steps.push({ name: 'Write and Add Liquidity', state: StepState.Idle });
    } else {
        steps.push({ name: 'Write', state: StepState.Idle });
    }

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

interface WriteOptionPayload {
    optionAddress: string;
    writeAmount: string;
}

export const writeOptionTxAsync = createAsyncThunk(
    'write/tx',
    async (payload: WriteOptionPayload, { dispatch }) => {
        const { optionAddress, writeAmount } = payload;

        const routerContract = getContractsProvider().findOrCreate(
            ContractType.OptionsRouter,
        );
        invariant(routerContract, 'No router contract set');

        const parsedAmount = parseUnits(writeAmount, 6);

        const writeTx = await routerContract['write(address,uint256)'](
            optionAddress,
            parsedAmount,
            { gasLimit: process.env.REACT_APP_GAS_LIMIT },
        );

        dispatch(addTransactionAsync({ ...writeTx, type: TxType.Write }));

        await writeTx.wait();
        return writeTx?.hash;
    },
);

interface WriteOptionAndAddLiquidityPayload {
    optionAddress: string;
    writeAmount: string;
    collateralAmount: string;
}

export const writeAndAddLiquidityTxAsync = createAsyncThunk(
    'write/liquidity-tx',
    async (payload: WriteOptionAndAddLiquidityPayload, { dispatch }) => {
        const { optionAddress, writeAmount, collateralAmount } = payload;

        const routerContract = getContractsProvider().findOrCreate(
            ContractType.OptionsRouter,
        );
        invariant(routerContract, 'No router contract set');

        const parsedWriteAmount = parseUnits(writeAmount.toString(), 6);
        const parsedCollateralAmount = parseUnits(
            collateralAmount.toString(),
            6,
        );

        const writeAndLiquidityTx = await routerContract[
            'writeAndAddLiquidity(address,uint256,uint256)'
        ](optionAddress, parsedWriteAmount, parsedCollateralAmount, {
            gasLimit: process.env.REACT_APP_GAS_LIMIT,
        });

        dispatch(
            addTransactionAsync({
                ...writeAndLiquidityTx,
                type: TxType.WriteAndAddLiquidity,
            }),
        );

        await writeAndLiquidityTx.wait();
        return writeAndLiquidityTx?.hash;
    },
);
