import OilerGrid from '@oilerKit/OilerGrid';
import React, { useState } from 'react';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import { useDispatch } from 'react-redux';
import IsoSharpIcon from '@material-ui/icons/IsoSharp';
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
    buildStepsAsync,
    InteractionName,
} from '@features/contracts-interactions/forwards/steps-builder';
import CollateralModal from '@components/TradingActions/Forwards/CollateralModal';

const ForwardsActions = () => {
    const [openActionModal, setOpenActionModal] = useState<boolean>(false);
    const theme = useTheme();
    const isXsSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const dispatch = useDispatch();

    const handleLiquidate = async () => {
        dispatch(
            buildStepsAsync({
                interactionName: InteractionName.LIQUIDATE,
            }),
        );
    };

    const handleSettle = async () => {
        dispatch(
            buildStepsAsync({
                interactionName: InteractionName.SETTLE,
            }),
        );
    };

    return (
        <>
            <OilerGrid
                direction={isXsSmallScreen ? 'column' : 'row'}
                alignItems={isXsSmallScreen ? 'stretch' : 'center'}
                justifyContent={'center'}
                spacing={2}
                container
            >
                <OilerGrid
                    container
                    md
                    sm={4}
                    xs={12}
                    item
                    justifyContent={'flex-end'}
                >
                    <OilerButton
                        fullWidth={isSmallScreen}
                        color={'secondary'}
                        endIcon={<IsoSharpIcon />}
                        onClick={() => setOpenActionModal(true)}
                    >
                        COLLATERAL
                    </OilerButton>
                </OilerGrid>
                <OilerGrid
                    container
                    md={2}
                    sm={4}
                    xs={12}
                    item
                    justifyContent={'center'}
                >
                    <OilerButton
                        fullWidth={isSmallScreen}
                        color={'secondary'}
                        onClick={handleSettle}
                    >
                        SETTLE
                    </OilerButton>
                </OilerGrid>
                <OilerGrid
                    container
                    md
                    sm={4}
                    xs={12}
                    item
                    justifyContent={'flex-start'}
                >
                    <OilerButton
                        fullWidth={isSmallScreen}
                        color={'secondary'}
                        onClick={handleLiquidate}
                    >
                        LIQUIDATE
                    </OilerButton>
                </OilerGrid>
            </OilerGrid>
            <CollateralModal
                open={openActionModal}
                setOpenActionModal={() => setOpenActionModal(false)}
            />
        </>
    );
};

export default ForwardsActions;
