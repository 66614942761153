import React, { useEffect, useState } from 'react';
import OilerBox from '@oilerKit/OilerBox';
import OilerGrid from '@oilerKit/OilerGrid';
import OilerStatistic from '@oilerKit/OilerStatistic';
import OilerTooltip from '@oilerKit/OilerTooltip';
import OilerDivider from '@oilerKit/OilerDivider';
import OilerHidden from '@oilerKit/OilerHidden';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ForwardsActions from '@components/TradingActions/Forwards/ForwardsActions';
import ForwardsPositionMiniCharts from '@components/Statistics/Forwards/ForwardsMiniCharts/ForwardsPositionMiniCharts';
import { useSelector } from 'react-redux';
import { selectFirebase } from '@features/firebase/firebaseSlice';
import { getSpecificForwardData } from '@features/contracts-interactions/forwards/actions';
import { BigNumber } from 'ethers/lib/ethers';
import { ForwardStatus } from '@services/types';

const PositionStatistics = () => {
    const theme = useTheme();
    const isXsmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const justify = isXsmallScreen ? 'flex-start' : 'center';
    const spacing = isXsmallScreen ? 2 : 0;
    const marginBottom = isSmallScreen ? 4 : 0;

    const { displayNftToken } = useSelector(selectFirebase);

    // const { status, strike, notional, settlementDate } = displayNftToken || {};

    const [specificForwardData, setSpecificForwardData] = useState<{
        strike: BigNumber;
        collateral: BigNumber;
        mintTimestamp: BigNumber;
        settlementTimestamp: BigNumber;
        mintExpectedSettlementPayout: BigNumber;
        notional: BigNumber;
        mintTick: BigNumber;
        settlementTick: BigNumber;
        tenorInTicks: BigNumber;
        initialLambda: BigNumber;
        status: ForwardStatus;
    } | null>(null);

    useEffect(() => {
        if (!displayNftToken) {
            return;
        }

        getSpecificForwardData(displayNftToken).then(setSpecificForwardData);
    }, [displayNftToken]);

    const placeholder = '---';

    return (
        <>
            <OilerBox p={3} mb={5} title="Position - 1M FWD">
                <OilerGrid container direction="row" justify={'space-around'}>
                    <OilerGrid
                        container
                        direction="column"
                        justifyContent={'space-between'}
                        item
                        mb={4}
                    >
                        <OilerGrid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <OilerGrid
                                spacing={spacing}
                                container
                                justifyContent={'center'}
                                item
                                sm={12}
                            >
                                <OilerGrid item md container justify={justify}>
                                    <OilerTooltip title="Status">
                                        <div>
                                            <OilerStatistic
                                                value={
                                                    specificForwardData?.status
                                                        ? Object.keys(
                                                              ForwardStatus,
                                                          )[
                                                              4 +
                                                                  specificForwardData.status
                                                          ]
                                                        : placeholder
                                                }
                                                label="STATUS"
                                            />
                                        </div>
                                    </OilerTooltip>
                                </OilerGrid>

                                <OilerHidden smDown>
                                    <OilerDivider
                                        orientation="vertical"
                                        flexItem
                                        small
                                    />
                                </OilerHidden>

                                <OilerGrid item md container justify={justify}>
                                    <OilerTooltip title="Lambda">
                                        <div>
                                            <OilerStatistic
                                                value={
                                                    specificForwardData?.initialLambda
                                                        ? specificForwardData?.initialLambda?.toString()
                                                        : placeholder
                                                }
                                                label={'LAMBDA'}
                                            />
                                        </div>
                                    </OilerTooltip>
                                </OilerGrid>

                                <OilerHidden smDown>
                                    <OilerDivider
                                        orientation="vertical"
                                        flexItem
                                        small
                                    />
                                </OilerHidden>

                                <OilerGrid item md container justify={justify}>
                                    <OilerStatistic
                                        value={
                                            specificForwardData?.strike
                                                ? specificForwardData?.strike?.toString()
                                                : placeholder
                                        }
                                        label="STRIKE"
                                        skeletonWidth={70}
                                    />
                                </OilerGrid>

                                <OilerHidden smDown>
                                    <OilerDivider
                                        orientation="vertical"
                                        flexItem
                                        small
                                    />
                                </OilerHidden>

                                <OilerGrid item md container justify={justify}>
                                    <OilerStatistic
                                        value={
                                            specificForwardData?.collateral
                                                ? specificForwardData?.collateral?.toString()
                                                : placeholder
                                        }
                                        label="COLLATERAL"
                                        skeletonWidth={70}
                                    />
                                </OilerGrid>

                                <OilerHidden smDown>
                                    <OilerDivider
                                        orientation="vertical"
                                        flexItem
                                        small
                                    />
                                </OilerHidden>

                                <OilerGrid item md container justify={justify}>
                                    <OilerStatistic
                                        value={
                                            specificForwardData?.notional
                                                ? specificForwardData?.notional?.toString()
                                                : placeholder
                                        }
                                        label="NOTIONAL"
                                        skeletonWidth={70}
                                    />
                                </OilerGrid>

                                <OilerHidden smDown>
                                    <OilerDivider
                                        orientation="vertical"
                                        flexItem
                                        small
                                    />
                                </OilerHidden>

                                <OilerGrid item md container justify={justify}>
                                    <OilerStatistic
                                        value={
                                            specificForwardData?.mintExpectedSettlementPayout
                                                ? specificForwardData?.mintExpectedSettlementPayout?.toString()
                                                : placeholder
                                        }
                                        label="EXPECTED PAYOUT"
                                        skeletonWidth={70}
                                    />
                                </OilerGrid>

                                <OilerHidden smDown>
                                    <OilerDivider
                                        orientation="vertical"
                                        flexItem
                                        small
                                    />
                                </OilerHidden>

                                <OilerGrid item md container justify={justify}>
                                    <OilerTooltip title="DD/MM/YYYY">
                                        <div>
                                            <OilerStatistic
                                                value={
                                                    specificForwardData?.settlementTick
                                                        ? specificForwardData?.settlementTick?.toString()
                                                        : placeholder
                                                }
                                                label="SETTLEMENT"
                                                skeletonWidth={70}
                                            />
                                        </div>
                                    </OilerTooltip>
                                </OilerGrid>
                            </OilerGrid>
                        </OilerGrid>
                    </OilerGrid>
                </OilerGrid>

                <OilerGrid
                    container
                    direction={'row'}
                    item
                    width="100%"
                    justifyContent={'space-between'}
                >
                    <OilerGrid
                        mb={marginBottom}
                        sm={12}
                        md={7}
                        xs={12}
                        item
                        alignSelf={'center'}
                    >
                        <ForwardsActions />
                    </OilerGrid>
                    <OilerGrid
                        item
                        md={5}
                        sm={12}
                        width="100%"
                        justifySelf={'flex-end'}
                    >
                        <ForwardsPositionMiniCharts />
                    </OilerGrid>
                </OilerGrid>
            </OilerBox>
        </>
    );
};
export default PositionStatistics;
