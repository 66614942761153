import styled, { css, keyframes } from 'styled-components';
import React from 'react';
import OilerButton, {
    OilerButtonProps,
} from '@oilerKit/OilerButton/OilerButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const ProgressBar = styled.ul`
    counter-reset: step;
    width: fit-content;
    margin: auto;
    padding: 0;
    display: flex;
    gap: 24px;
`;

const activeProperties = css`
    background: ${(props) => props.theme.palette.secondary['500']};
    color: ${(props) => props.theme.palette.secondary['900']};
`;
const doneProperties = css`
    background: ${(props) => props.theme.palette.secondary['500']};
    color: ${(props) => props.theme.palette.secondary['900']};
`;
const pendingProperties = css`
    background: ${(props) => props.theme.palette.primary['500']};
    color: ${(props) => props.theme.palette.primary['900']};
`;

const activeColor = css`
    color: ${(props) => props.theme.palette.secondary['500']};
`;
const doneColor = css`
    color: ${(props) => props.theme.palette.secondary['500']};
`;
const pendingColor = css`
    color: ${(props) => props.theme.palette.primary['500']};
`;

const pulseKeyframes = (color: string) => keyframes`
0% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 ${color};
}

70% {
  transform: scale(1);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
}

100% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
`;

const pulseAnimation = css`
    animation: ${(props) =>
            pulseKeyframes(props.theme.palette.secondary['500'])}
        2s infinite;
`;

const Step = styled.li<any>`
    list-style-type: none;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    min-width: 120px;
    max-width: 150px;
    text-transform: uppercase;
    transition: color 500ms;
    font-weight: bold;
    word-wrap: break-word;
    ${(props) => (props.active ? activeColor : '')};
    ${(props) => (props.done ? doneColor : '')};
    ${(props) => (props.pending ? pendingColor : '')};

    &:before {
        position: relative;
        width: ${(props) => (props.size === 'lg' ? '45px' : '30px')};
        height: ${(props) => (props.size === 'lg' ? '45px' : '30px')};
        content: ${(props: any) => `'${props.idx.toString()}'`};
        font-size: ${(props) => (props.size === 'lg' ? '18px' : '12px')};
        counter-increment: step;
        line-height: ${(props) => (props.size === 'lg' ? '42px' : '27px')};
        padding: 2px;
        display: block;
        text-align: center;
        margin: 0 auto 10px auto;
        border-radius: 50%;

        ${(props) => (props.active ? activeProperties : '')};
        ${(props) => (props.active ? pulseAnimation : '')};
        ${(props) => (props.done ? doneProperties : '')};
        ${(props) => (props.pending ? pendingProperties : '')};

        z-index: 100;
    }
    &:after {
        width: 100%;
        height: 2px;
        content: '';
        position: absolute;
        ${(props) => (props.active ? activeProperties : '')};
        ${(props) => (props.done ? doneProperties : '')};
        ${(props) => (props.pending ? pendingProperties : '')};

        top: ${(props) => (props.size === 'lg' ? '22.5px' : '15px')};
        left: -65%;
        transition: background-color 200ms ease 200ms;
    }
    &:first-child:after {
        content: none;
    }
`;

const StyledOilerButton = styled(OilerButton)<OilerButtonProps>`
    font-size: 0.5rem;
`;

interface OilerStepperProps {
    activeStep: number;
    steps: string[];
    size?: 'lg';
    pendingTx?: string;
}

const OilerStepper = (props: OilerStepperProps) => {
    const { activeStep, steps, size, pendingTx } = props;

    return (
        <ProgressBar>
            {steps.map((step: string, index: number) => {
                return (
                    <Step
                        size={size}
                        key={index}
                        idx={index + 1}
                        active={index + 1 === activeStep}
                        done={index + 1 < activeStep}
                        pending={index + 1 > activeStep}
                    >
                        {step}
                        {pendingTx && index + 1 === activeStep && (
                            <StyledOilerButton
                                mt={3}
                                endIcon={<OpenInNewIcon />}
                                variant={'outlined'}
                                size={'small'}
                                href={pendingTx}
                                target={'_blank'}
                            >
                                View on Etherscan
                            </StyledOilerButton>
                        )}
                    </Step>
                );
            })}
        </ProgressBar>
    );
};

export default OilerStepper;
