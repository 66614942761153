import OilerGrid from '@oilerKit/OilerGrid';
import OilerStatistic from '@oilerKit/OilerStatistic';
import { Balances } from '@features/wallet/types';
import { OilerOption, SpotPrices } from '@features/options/types';
import OilerTooltip from '@oilerKit/OilerTooltip';
import OilerDivider from '@oilerKit/OilerDivider';
import { useMediaQuery, useTheme } from '@material-ui/core';
import OilerHidden from '@oilerKit/OilerHidden';
import React from 'react';

interface UserStatisticsProps {
    balances: Balances;
    spotPrices: SpotPrices;
    option: OilerOption;
}

const UserStatistics = (props: UserStatisticsProps) => {
    const { spotPrices, option } = props;

    const {
        address,
        held,
        written,
        withdrawable,
        lpTokensBalance,
        lpTokensBalanceFormatted,
        lpValueLocked,
        fullPnl,
    } = option || {};

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const justify = isSmallScreen ? 'flex-start' : 'center';
    const spacing = isSmallScreen ? 2 : 0;

    const optionSpotPrice =
        spotPrices[process.env.REACT_APP_COLLATERAL_ADDRESS!]?.[address]
            ?.priceWithoutFee;

    const optionsValue = Number(held) * Number(optionSpotPrice);
    const writtenValue = Number(written) * Number(optionSpotPrice);

    const placeholder = '---';

    return (
        <>
            <OilerGrid
                spacing={2}
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                alignContent="flex-start"
            >
                <OilerGrid spacing={spacing} container item md={6}>
                    <OilerGrid pt={2} item container sm justify={justify}>
                        <OilerTooltip title="The options that you still hold in your wallet">
                            <div>
                                <OilerStatistic
                                    value={held}
                                    suffix={
                                        '($' +
                                        (isNaN(optionsValue)
                                            ? placeholder
                                            : Number(optionsValue).toFixed(2)) +
                                        ')'
                                    }
                                    label={'YOU HOLD'}
                                    loading={held === placeholder}
                                />
                            </div>
                        </OilerTooltip>
                    </OilerGrid>

                    <OilerHidden smDown>
                        <OilerDivider orientation="vertical" flexItem />
                    </OilerHidden>

                    <OilerGrid pt={2} item container sm justify={justify}>
                        <OilerTooltip title="The options written by you (but not necessary held in your wallet)">
                            <div>
                                <OilerStatistic
                                    value={written}
                                    suffix={
                                        '($' +
                                        (isNaN(writtenValue)
                                            ? placeholder
                                            : Number(writtenValue).toFixed(2)) +
                                        ')'
                                    }
                                    label={'YOU WROTE'}
                                    loading={written === placeholder}
                                />
                            </div>
                        </OilerTooltip>
                    </OilerGrid>

                    <OilerHidden smDown>
                        <OilerDivider orientation="vertical" flexItem />
                    </OilerHidden>

                    <OilerGrid pt={2} item container sm justify={justify}>
                        <OilerTooltip title="The amount of collateral you can withdraw from this option (should be either exercised or expired or be just after writing)">
                            <div>
                                <OilerStatistic
                                    value={withdrawable}
                                    suffix={
                                        '($' +
                                        (withdrawable
                                            ? withdrawable
                                            : placeholder) +
                                        ')'
                                    }
                                    label={'WITHDRAWABLE'}
                                    loading={withdrawable === placeholder}
                                />
                            </div>
                        </OilerTooltip>
                    </OilerGrid>
                </OilerGrid>

                <OilerGrid spacing={spacing} container item md={6}>
                    <OilerHidden smDown>
                        <OilerDivider orientation="vertical" flexItem />
                    </OilerHidden>

                    <OilerGrid pt={2} item container sm justify={justify}>
                        <OilerTooltip
                            title={lpTokensBalance + ' Balancer Pool Tokens'}
                        >
                            <div>
                                <OilerStatistic
                                    value={lpTokensBalanceFormatted}
                                    suffix={
                                        '($' +
                                        (lpValueLocked
                                            ? lpValueLocked
                                            : placeholder) +
                                        ')'
                                    }
                                    label={'LP TOKENS'}
                                    loading={lpTokensBalance === placeholder}
                                />
                            </div>
                        </OilerTooltip>
                    </OilerGrid>

                    <OilerHidden smDown>
                        <OilerDivider orientation="vertical" flexItem />
                    </OilerHidden>

                    <OilerGrid pt={2} item container sm justify={justify}>
                        <OilerTooltip title="The profit and loss of your portfolio">
                            <div>
                                <OilerStatistic
                                    value={
                                        (fullPnl && fullPnl.length
                                            ? fullPnl[0]
                                            : placeholder) + '%'
                                    }
                                    suffix={
                                        held === placeholder
                                            ? '($' + placeholder + ')'
                                            : fullPnl && fullPnl.length
                                            ? '($' + fullPnl[1] + ')'
                                            : ''
                                    }
                                    label={'TOTAL PnL (EXPERIMENTAL)'}
                                    loading={held === placeholder}
                                />
                            </div>
                        </OilerTooltip>
                    </OilerGrid>
                </OilerGrid>
            </OilerGrid>
        </>
    );
};

export default UserStatistics;
