import { makeStyles, Select, SelectProps } from '@material-ui/core';
import theme from '@oilerKit/themes/theme';
import styled from 'styled-components';
import OilerTypography from '@oilerKit/OilerTypography';
import React, { useState } from 'react';
import OilerMenuItem from '@oilerKit/OilerMenu/OilerMenuItem';

const useStyles = makeStyles({
    paper: {
        background: theme.palette.common.black,
        borderColor: theme.palette.primary.main,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 0,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
    },
});

const StyledSelect = styled(Select)`
    ${(props) => props.fullWidth && 'width: 100%;'}
    &.MuiOutlinedInput-root {
        height: 50px;
    }
    &.MuiOutlinedInput-root {
        border-radius: 0;
        &&& fieldset.MuiOutlinedInput-root:hover {
            border-color: ${(props) =>
                props.theme.palette.primary.main} !important;
        }
    }
    & fieldset.MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.palette.primary.main};
    }

    &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.palette.common.white};
        border-width: 1px;
    }
    &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.palette.primary.main};
    }
    &.MuiOutlinedInput-root legend {
        width: 0;
    }
`;

const Label = styled.div`
    margin-bottom: 10px;
`;

interface OilerSelectProps extends SelectProps {
    placeholder: string;
    fullWidth?: boolean;
    label?: string;
}

const OilerSelect = (props: OilerSelectProps) => {
    const classes = useStyles();
    const [showPlaceholder, setShowPlaceholder] = useState(!props.value);
    return (
        <>
            {props.label && (
                <Label>
                    <OilerTypography>{props.label}</OilerTypography>
                </Label>
            )}
            <StyledSelect
                displayEmpty
                MenuProps={{
                    classes: {
                        paper: classes.paper,
                    },
                }}
                onFocus={() => setShowPlaceholder(false)}
                variant="outlined"
                {...props}
            >
                {showPlaceholder && (
                    <OilerMenuItem disabled>{props.placeholder}</OilerMenuItem>
                )}
                {props.children}
            </StyledSelect>
        </>
    );
};
export default OilerSelect;
