import React, { useEffect } from 'react';
import styled from 'styled-components';
import invariant from 'tiny-invariant';
import { getContractsProvider } from '@services/contracts-provider';
import { useDispatch, useSelector } from 'react-redux';
import {
    buildStepsAsync,
    InteractionName,
} from '@features/contracts-interactions/steps-builder';
import {
    calculateSpotPriceAsync,
    changeHistoricalOptionsInitialFetchFlag,
    dbOptionsLiveDataListener,
    fetchDbOptionsDataAsync,
    fetchDynamicOptionsDataAsync,
    selectOptions,
    setDisplayOption,
    setTradingOption,
} from '@features/options/optionsSlice';
// import WrongNetwork from '@components/WrongNetwork';
import { TransactionsStepper } from '@components/TransactionsStepper/TransactionsStepper';
import Statistics from '@components/Statistics/Statistics';
import {
    selectWallet,
    setBlockNumber,
    setIsWrongNetwork,
} from '@features/wallet/walletSlice';
import OptionsTable from '@components/OptionsTable/OptionsTable';
import Chart from '@components/Chart/Chart';
import OilerToggleButtonGroup, {
    OilerToggleButton,
} from '@oilerKit/OilerToggleButtonGroup';
import {
    HistoricalOptionsState,
    TradingOptionsFamily,
} from '@features/options/types';
import NFTsTable from '@components/NFTsTable/NFTsTable';
import { FirebaseListener } from '@components/Firebase/Listener';
import PoolStatistics from '@components/Statistics/Forwards/PoolStatistics';
import PositionStatistics from '@components/Statistics/Forwards/PositionStatistics';
import { provider } from '@services/web3-provider';

const StyledOilerToggleButtonGroup = styled(OilerToggleButtonGroup)`
    position: relative;
    top: 2px;
`;

function App() {
    const dispatch = useDispatch();

    const { isWrongNetwork, account, blockNumber } = useSelector(selectWallet);
    const {
        historicalOptions,
        historicalOptionsState,
        historicalOptionsInitialFetch,
        displayOption,
        tradingOption,
    } = useSelector(selectOptions);

    useEffect(() => {
        let latestBlock = -1;
        async function fetchLatestBlock() {
            latestBlock = await provider.getBlockNumber();
            dispatch(setBlockNumber(latestBlock));
        }

        fetchLatestBlock();

        provider.on('block', (blockNumber: number) => {
            if (blockNumber !== latestBlock) {
                dispatch(setBlockNumber(blockNumber));
            }
        });
    }, [dispatch]);

    useEffect(() => {
        const checkWrongNetwork = async () => {
            const provider = getContractsProvider()?.getProvider();
            if (!provider) return;
            const { chainId } = await provider.getNetwork();

            const appChainId: string = process.env.REACT_APP_CHAIN_ID!;
            invariant(appChainId, 'Please set REACT_APP_CHAIN_ID');

            if (chainId.toString() !== appChainId.toString())
                dispatch(setIsWrongNetwork(true));
        };

        checkWrongNetwork();
    }, [dispatch]);

    useEffect(() => {
        if (
            tradingOption === TradingOptionsFamily.FORWARDS ||
            historicalOptionsInitialFetch
        ) {
            return;
        }

        switch (historicalOptionsState) {
            case HistoricalOptionsState.Initial:
                dispatch(
                    fetchDbOptionsDataAsync(TradingOptionsFamily.FORWARDS),
                );
                break;
            case HistoricalOptionsState.DbDataSuccess:
                if (!account) {
                    return;
                }
                if (
                    historicalOptions &&
                    historicalOptions[tradingOption].length &&
                    historicalOptions[tradingOption][0]
                ) {
                    dispatch(
                        setDisplayOption(
                            historicalOptions[tradingOption][0].address,
                        ),
                    );
                }

                dispatch(
                    fetchDynamicOptionsDataAsync([
                        ...historicalOptions[TradingOptionsFamily.HASHRATE],
                        ...historicalOptions[TradingOptionsFamily.BASEFEE],
                    ]),
                );

                dispatch(dbOptionsLiveDataListener(null));
                break;
            case HistoricalOptionsState.DynamicDataSuccess:
                if (!account) {
                    return;
                }

                dispatch(changeHistoricalOptionsInitialFetchFlag(true));
                if (displayOption && displayOption.address)
                    dispatch(setDisplayOption(displayOption.address));
                // dispatch(pnlLiveDataListener(null));
                break;
        }
    }, [
        dispatch,
        account,
        tradingOption,
        displayOption,
        historicalOptions,
        historicalOptionsState,
        historicalOptionsInitialFetch,
    ]);

    useEffect(() => {
        if (!displayOption || tradingOption === TradingOptionsFamily.FORWARDS) {
            return;
        }

        dispatch(
            calculateSpotPriceAsync({
                from: displayOption.address!,
                to: process.env.REACT_APP_COLLATERAL_ADDRESS!,
            }),
        );
        dispatch(
            calculateSpotPriceAsync({
                from: process.env.REACT_APP_COLLATERAL_ADDRESS!,
                to: displayOption.address!,
            }),
        );
    }, [dispatch, displayOption, blockNumber, tradingOption]);

    const handleStepperHide = () => {
        dispatch(
            buildStepsAsync({ interactionName: InteractionName.CLEAR_STEPS }),
        );
    };

    const handleTradingOptionChange = (option: TradingOptionsFamily) => {
        dispatch(setTradingOption(option));
    };

    if (isWrongNetwork) {
        window.location.replace(process.env.REACT_APP_REDIRECT_URL!);
        // return <WrongNetwork />;
    }

    return (
        <>
            <StyledOilerToggleButtonGroup color={'primary'} exclusive>
                <OilerToggleButton
                    selected={tradingOption === TradingOptionsFamily.HASHRATE}
                    onClick={() =>
                        handleTradingOptionChange(TradingOptionsFamily.HASHRATE)
                    }
                    // disabled={
                    //     !historicalOptions[
                    //         TradingOptionsFamily
                    //             .HASHRATE
                    //     ].length
                    // }
                >
                    HASHRATE Options
                </OilerToggleButton>
                <OilerToggleButton
                    selected={tradingOption === TradingOptionsFamily.BASEFEE}
                    onClick={() =>
                        handleTradingOptionChange(TradingOptionsFamily.BASEFEE)
                    }
                    // disabled={
                    //     !historicalOptions[
                    //         TradingOptionsFamily
                    //             .BASEFEE
                    //     ].length
                    // }
                >
                    BASEFEE Options
                </OilerToggleButton>
                {Number(process.env.REACT_APP_CHAIN_ID) !== 1 && (
                    <OilerToggleButton
                        selected={
                            tradingOption === TradingOptionsFamily.FORWARDS
                        }
                        onClick={() =>
                            handleTradingOptionChange(
                                TradingOptionsFamily.FORWARDS,
                            )
                        }
                    >
                        FORWARDS
                    </OilerToggleButton>
                )}
            </StyledOilerToggleButtonGroup>

            {tradingOption === TradingOptionsFamily.FORWARDS && (
                <PoolStatistics />
            )}

            <Chart family={tradingOption} />

            {tradingOption === TradingOptionsFamily.FORWARDS ? (
                <>
                    <PositionStatistics />
                    <NFTsTable />
                </>
            ) : (
                <>
                    <Statistics />
                    <OptionsTable />
                </>
            )}

            <TransactionsStepper onHide={handleStepperHide} />

            <FirebaseListener />
        </>
    );
}

export default App;
