/**
 * Is a valid number (input) for a transaction.
 * @param number input
 * @returns boolean
 */
export const isValidNumber = (number: string) =>
    /^\d*\.?\d+$/.test(number) && !isNaN(parseFloat(number));

export const isValidInteger = (number: string) =>
    isValidNumber(number) && parseFloat(number) > 0;
