import { addTransactionAsync } from '@features/transactions/transactionsSlice';
import { TxType } from '@features/transactions/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContractsProvider } from '@services/contracts-provider';
import { ContractType } from '@services/contracts-types';
import { getBlockRLP } from '@utils/rlpEncoder';
import { ethers } from 'ethers';
import invariant from 'invariant';
import {
    ContractsInteractionsState,
    InteractionState,
    Step,
    StepState,
} from './types';

export const buildExerciseSteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const steps = [] as Step[];

    steps.push({ name: 'Exercise', state: StepState.Idle });

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

interface ExercisePayload {
    optionAddress: string;
    exercisableBlockNumber: number;
}

export const exerciseTxAsync = createAsyncThunk(
    'exercise/tx',
    async (payload: ExercisePayload, { dispatch, getState }) => {
        const { optionAddress, exercisableBlockNumber } = payload;

        console.log('exercise', optionAddress, exercisableBlockNumber);

        const { network } = (getState() as any).wallet;
        invariant(network, 'No network set');

        if (!window.ethereum) {
            console.error('No instance of window.ethereum found');
            return;
        }
        const chainProvider = new ethers.providers.Web3Provider(
            window.ethereum,
        );

        const block = await chainProvider.send('eth_getBlockByNumber', [
            ethers.utils.hexValue(exercisableBlockNumber),
            true,
        ]);

        console.log('block', block);

        const rlp = '0x' + getBlockRLP(block, network);

        const optionContract = getContractsProvider().findOrCreate(
            ContractType.Option,
            optionAddress,
        );
        invariant(optionContract, 'No option contract set');

        console.log('rlp', rlp);

        const exerciseTx = await optionContract.exercise(rlp, {
            gasLimit: process.env.REACT_APP_GAS_LIMIT,
        });

        dispatch(addTransactionAsync({ ...exerciseTx, type: TxType.Exercise }));

        await exerciseTx.wait();
        return exerciseTx?.hash;
    },
);
