import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectWallet } from '@features/wallet/walletSlice';
import styled from 'styled-components';
import OilerStatistic from '@oilerKit/OilerStatistic';
import { ethers } from 'ethers';
import AddIcon from '@material-ui/icons/Add';
import OilerIconButton from '@oilerKit/OilerButton/OilerIconButton';
import OilerTooltip from '@oilerKit/OilerTooltip';
import {
    buildStepsAsync,
    InteractionName,
} from '@features/contracts-interactions/steps-builder';

const Wrapper = styled.div`
    //width: 160px;
    display: flex;
    align-items: center;
`;

const COLLATERAL = process.env.REACT_APP_COLLATERAL_ADDRESS;

interface BalanceProps {
    walletBalance?: boolean;
}

const Balance = ({ walletBalance }: BalanceProps) => {
    if (!COLLATERAL)
        throw new Error(
            'REACT_APP_COLLATERAL_ADDRESS or REACT_APP_OILER_ADDRESS not set',
        );

    const { isConnected, balances } = useSelector(selectWallet);

    const dispatch = useDispatch();

    const mintCollateral = () => {
        dispatch(
            buildStepsAsync({
                interactionName: InteractionName.MINT_COLLATERAL,
            }),
        );
    };

    if (!isConnected) return <></>;

    return (
        <Wrapper>
            <OilerStatistic
                label="WALLET BALANCE"
                suffix={process.env.REACT_APP_COLLATERAL_NAME}
                value={
                    balances?.USDC?.toString() &&
                    ethers.utils.formatUnits(balances.USDC, 6)
                }
                skeletonWidth={50}
                loading={!walletBalance || !balances.USDC}
            />
            <OilerTooltip title="Faucet">
                <div>
                    <OilerIconButton onClick={mintCollateral}>
                        <AddIcon />
                    </OilerIconButton>
                </div>
            </OilerTooltip>
        </Wrapper>
    );
};

export default Balance;
