import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContractsProvider } from '@services/contracts-provider';
import { ContractType } from '@services/contracts-types';
import {
    ContractsInteractionsState,
    InteractionState,
    Step,
    StepState,
} from './types';
import invariant from 'tiny-invariant';
import { utils, BigNumber } from 'ethers';
import { addTransactionAsync } from '@features/transactions/transactionsSlice';
import { TxType } from '@features/transactions/types';
import { parseUnits } from '@ethersproject/units';

export const buildAddLiquiditySteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const { optionAddress, allowanceAmount } = payload;

    const contractsProvider = getContractsProvider();

    const collateralContract = getContractsProvider().findOrCreate(
        ContractType.Collateral,
    );
    invariant(collateralContract, 'No collateral contract set');

    const optionContract = getContractsProvider().findOrCreate(
        ContractType.Option,
        optionAddress,
    );
    invariant(optionContract, 'No option contract set');

    const userAddr = await contractsProvider.getAccount();
    invariant(userAddr, 'No user address set');

    const givenCollateralAllowance = await collateralContract.allowance(
        userAddr,
        process.env.REACT_APP_BALANCER_ROUTER_ADDRESS,
    );

    const givenOptionAllowance = await optionContract.allowance(
        userAddr,
        process.env.REACT_APP_BALANCER_ROUTER_ADDRESS,
    );

    const minRequired = utils.parseEther(allowanceAmount.toString());

    const collateralNeedsApproval = BigNumber.from(
        givenCollateralAllowance.toString(),
    ).lt(minRequired);

    const optionNeedsApproval = BigNumber.from(
        givenOptionAllowance.toString(),
    ).lt(minRequired);

    const steps = [] as Step[];

    if (collateralNeedsApproval) {
        steps.push({ name: 'Approve Collateral', state: StepState.Idle });
    }
    if (optionNeedsApproval) {
        steps.push({ name: 'Approve Option', state: StepState.Idle });
    }
    steps.push({ name: 'Add Liquidity', state: StepState.Idle });

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

export const buildRemoveLiquiditySteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const { optionAddress, allowanceAmount } = payload;

    const contractsProvider = getContractsProvider();

    const bRouterContract = contractsProvider.findOrCreate(
        ContractType.BalancerRouter,
    );
    invariant(bRouterContract, 'No router contract set');

    const poolAddress = await bRouterContract.getPoolByTokens(
        optionAddress,
        process.env.REACT_APP_COLLATERAL_ADDRESS,
    );

    const bPoolContract = getContractsProvider().findOrCreate(
        ContractType.BalancerPool,
        poolAddress,
    );
    invariant(bPoolContract, 'No pool contract set');

    const userAddr = await contractsProvider.getAccount();
    invariant(userAddr, 'No user address set');

    const givenAllowance = await bPoolContract.allowance(
        userAddr,
        process.env.REACT_APP_BALANCER_ROUTER_ADDRESS,
    );

    const minRequired = utils.parseEther(allowanceAmount.toString());
    const needsApproval = BigNumber.from(givenAllowance.toString()).lt(
        minRequired,
    );

    const steps = [] as Step[];

    if (needsApproval) {
        steps.push({
            name: 'Approve LP Tokens',
            state: StepState.Idle,
        });
    }
    steps.push({ name: 'Remove Liquidity', state: StepState.Idle });

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

interface AddLiquidityPayload {
    optionAddress: string;
    optionsAmount: string;
    collateralAmount: string;
}

export const addLiquidityTxAsync = createAsyncThunk(
    'liquidity/add-tx',
    async (payload: AddLiquidityPayload, { dispatch }) => {
        const { optionsAmount, collateralAmount, optionAddress } = payload;

        const routerContract = getContractsProvider().findOrCreate(
            ContractType.BalancerRouter,
        );
        invariant(routerContract, 'No router contract set');

        const parsedAmountOptions = parseUnits(optionsAmount.toString(), 6);
        const parsedAmountCollateral = parseUnits(
            collateralAmount.toString(),
            6,
        );

        const addLiquidityTx = await routerContract.addLiquidity(
            optionAddress,
            process.env.REACT_APP_COLLATERAL_ADDRESS,
            parsedAmountOptions,
            parsedAmountCollateral,
            { gasLimit: process.env.REACT_APP_GAS_LIMIT },
        );

        dispatch(
            addTransactionAsync({
                ...addLiquidityTx,
                type: TxType.AddLiquidity,
            }),
        );

        await addLiquidityTx.wait();
        return addLiquidityTx?.hash;
    },
);

interface RemoveLiquidityPayload {
    optionAddress: string;
    lpTokensAmount: string;
}

export const removeLiquidityTxAsync = createAsyncThunk(
    'liquidity/remove-tx',
    async (payload: RemoveLiquidityPayload, { dispatch }) => {
        const { lpTokensAmount, optionAddress } = payload;

        const routerContract = getContractsProvider().findOrCreate(
            ContractType.BalancerRouter,
        );
        invariant(routerContract, 'No router contract set');

        const parsedAmountTokens = parseUnits(lpTokensAmount.toString(), 18);

        const removeLiquidityTx = await routerContract.removeLiquidity(
            optionAddress,
            process.env.REACT_APP_COLLATERAL_ADDRESS,
            parsedAmountTokens,
            { gasLimit: process.env.REACT_APP_GAS_LIMIT },
        );

        dispatch(
            addTransactionAsync({
                ...removeLiquidityTx,
                type: TxType.AddLiquidity,
            }),
        );

        await removeLiquidityTx.wait();
        return removeLiquidityTx?.hash;
    },
);
