import {
    Table,
    TableContainer,
    TableContainerProps,
    TableProps,
    TableSortLabel,
    TableSortLabelProps,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export const OilerTable = (props: TableProps) => (
    <Table {...props}>{props.children}</Table>
);

export const OilerTableContainer = (props: TableContainerProps) => (
    <TableContainer {...props}>{props.children}</TableContainer>
);

const StyledTableSortLabel = styled(TableSortLabel)`
    &.MuiTableSortLabel-active {
        font-weight: ${(props) => props.theme.typography.fontWeightBold};
        color: ${(props) => props.theme.palette.common.black};
    }
`;

export const OilerTableSortLabel = (props: TableSortLabelProps) => (
    <StyledTableSortLabel {...props}>{props.children}</StyledTableSortLabel>
);
