import { BigNumber } from 'ethers';

export interface ForwardsValues {
    liquidity: string;
    lambda: string;
    shortContracts: string;
    longContracts: string;
    poolHealth: string;
    tvl: string;
}

export enum ForwardStatus {
    DOESNT_EXIST = 0,
    ACTIVE = 1,
    LIQUIDATED = 2,
    SETTLED = 3,
}

export type ForwardDataRaw = {
    strike: BigNumber;
    collateral: BigNumber;
    mintTimestamp: BigNumber;
    settlementTimestamp: BigNumber;
    mintExpectedSettlementPayout: BigNumber;
    notional: BigNumber;
    mintTick: BigNumber;
    settlementTick: BigNumber;
    tenorInTicks: BigNumber;
    initialLambda: BigNumber;
};

export type ForwardData = {
    strike: number;
    collateral: number;
    mintTimestamp: number;
    settlementTimestamp: number;
    mintExpectedSettlementPayout: number;
    notional: number;
    mintTick: number;
    settlementTick: number;
    tenorInTicks: number;
    initialLambda: number;
};
