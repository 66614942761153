import { Contract } from 'ethers';
import { Contract as MultiCallContract } from 'ethers-multicall';

export enum ContractType {
    Collateral = 'Collateral',
    Option = 'Option',
    BalancerPool = 'BalancerPool',
    BalancerRouter = 'BalancerRouter',
    OptionsRouter = 'OptionsRouter',
    OilerRegistry = 'OilerRegistry',
    Faucet = 'Faucet',
    ForwardsUI = 'ForwardsUI',
}

export type Contracts = {
    [chainId: number]: {
        [contractAddress: string]: Contract;
    };
};

export type MultiCallContracts = {
    [chainId: number]: {
        [contractAddress: string]: MultiCallContract;
    };
};
