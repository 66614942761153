import React from 'react';
import OilerGrid from '@oilerKit/OilerGrid';
import SwapModal from '@components/TradingActions/SwapModal';
import WriteModal from '@components/TradingActions/WriteModal';
import WithdrawModal from '@components/TradingActions/WithdrawModal';
import ExerciseModal from '@components/TradingActions/ExerciseModal';
import styled from 'styled-components';
import { OilerOption, tradingOptionsInfo } from '@features/options/types';
import { useDispatch, useSelector } from 'react-redux';
import { openModal, selectUI } from '@features/ui/uiSlice';
import { ActionModalProps, ModalType } from '@features/ui/types';
import OilerTooltip from '@oilerKit/OilerTooltip';
import { selectOptions } from '@features/options/optionsSlice';
import OilerSkeleton from '@oilerKit/OilerSkeleton';
import { selectWallet } from '@features/wallet/walletSlice';
import { InteractionName } from '@features/contracts-interactions/steps-builder';
import OilerButton from '@oilerKit/OilerButton/OilerButton';

const StyledOilerButton = styled(OilerButton)`
    ${(props) => props.theme.breakpoints.up('sm')} {
        min-width: 180px;
    }
`;

const getModalContent = (
    type: ModalType | null,
    open: boolean,
    option: OilerOption,
    setOpenActionModal: (payload: ActionModalProps) => void,
) => {
    switch (type) {
        case ModalType.SWAP:
            return (
                <SwapModal
                    open={open}
                    setOpenActionModal={setOpenActionModal}
                    option={option}
                />
            );
        case ModalType.WRITE:
            return (
                <WriteModal
                    open={open}
                    setOpenActionModal={setOpenActionModal}
                    option={option}
                />
            );
        case ModalType.EXERCISE:
            return (
                <ExerciseModal
                    open={open}
                    setOpenActionModal={setOpenActionModal}
                    option={option}
                />
            );
        case ModalType.WITHDRAW:
            return (
                <WithdrawModal
                    open={open}
                    setOpenActionModal={setOpenActionModal}
                    option={option}
                />
            );
        case null:
            return <></>;
    }
};

interface UserActionsProps {
    option: OilerOption;
}

const UserActions = (props: UserActionsProps) => {
    const dispatch = useDispatch();

    const { blockNumber } = useSelector(selectWallet);
    const { modal } = useSelector(selectUI);
    const { displayOption, tradingOption } = useSelector(selectOptions);

    const setOpenActionModal = (payload: ActionModalProps) => {
        dispatch(openModal(payload));
    };

    const isExercisable =
        props.option?.exercisableAtBlock !== -1 &&
        blockNumber !== -1 &&
        blockNumber - props.option?.exercisableAtBlock < 256;

    // if (isExercisable && !displayOption?.status.includes('Exercisable')) {
    //     const exercisableOption = { ...displayOption };
    //     console.log(exercisableOption.status);
    //     exercisableOption.status.splice(1, 0, 'Exercisable');
    //     dispatch(addOrUpdateHistoricalOptions(exercisableOption));
    // } else if (
    //     !isExercisable &&
    //     displayOption?.status.includes('Exercisable')
    // ) {
    //     const nonExercisableOption = { ...displayOption };
    //     nonExercisableOption.status = nonExercisableOption.status.filter(
    //         (x) => x !== 'Exercisable',
    //     );
    //     dispatch(addOrUpdateHistoricalOptions(nonExercisableOption));
    // }

    const isActive = props.option?.status.includes('Active');
    const isWithdrawable = props.option?.status.includes('Withdrawable');
    const isExercised = displayOption?.status.includes('Exercised');
    const isExpired = displayOption?.status.includes('Expired');

    const placeholder = '---';
    const exercisedText = `The option has been EXERCISED`;
    const expiredText = `The option has EXPIRED`;

    const notExercisedPrefix = `If ${
        tradingOptionsInfo[tradingOption].displayName
    } is at or ${displayOption?.type === 'CALL' ? 'above' : 'below'}`;
    const notExercisedSuffix = ` then you can EXERCISE to get 1 ${process.env.REACT_APP_COLLATERAL_NAME}`;

    const notExercisedText = () => {
        return (
            <>
                {notExercisedPrefix}{' '}
                {displayOption?.strike === placeholder ? (
                    <OilerSkeleton
                        width={20}
                        style={{ display: 'inline-flex' }}
                    />
                ) : (
                    displayOption?.strike + displayOption?.strikeMetric
                )}{' '}
                {notExercisedSuffix}
            </>
        );
    };

    const exerciseTooltip = () => {
        return (
            <>
                {isExercised && exercisedText}
                {isExpired && expiredText}
                {!isExercised && !isExpired && notExercisedText()}
            </>
        );
    };

    const swapWriteTooltip = (actionType: InteractionName) => {
        // empty string forces to not show tooltip element
        if (isActive) {
            return '';
        } else if (isExercised) {
            return `You can’t ${actionType} an option because it was exercised`;
        } else if (isExpired) {
            return `You can’t ${actionType} an option because it expired`;
        } else {
            return '';
        }
    };

    const withdrawTooltip = () => {
        // empty string forces to not show tooltip element
        if (isWithdrawable) {
            return '';
        } else if (isExercised) {
            return `Option was exercised. You are not the holder of any options to be able to withdraw.`;
        } else if (isExpired) {
            return `Option expired. You are not the writer of any options to withdraw.`;
        } else if (isActive) {
            return 'You don’t hold any options to withdraw.';
        } else {
            return '';
        }
    };

    return (
        <>
            <OilerGrid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
            >
                <OilerGrid
                    spacing={3}
                    direction="row"
                    container
                    item
                    xs={12}
                    md={6}
                >
                    <OilerGrid spacing={2} xs={12} sm={6} item>
                        <OilerTooltip
                            title={`${swapWriteTooltip(InteractionName.SWAP)}`}
                        >
                            <div>
                                <StyledOilerButton
                                    onClick={() =>
                                        setOpenActionModal({
                                            state: true,
                                            type: ModalType.SWAP,
                                        })
                                    }
                                    color={'secondary'}
                                    size={'large'}
                                    disabled={!isActive}
                                    fullWidth
                                >
                                    SWAP
                                </StyledOilerButton>
                            </div>
                        </OilerTooltip>
                    </OilerGrid>
                    <OilerGrid spacing={2} xs={12} sm={6} item>
                        <OilerTooltip
                            title={`${swapWriteTooltip(InteractionName.WRITE)}`}
                        >
                            <div>
                                <StyledOilerButton
                                    onClick={() =>
                                        setOpenActionModal({
                                            state: true,
                                            type: ModalType.WRITE,
                                        })
                                    }
                                    color={'secondary'}
                                    size={'large'}
                                    disabled={!isActive}
                                    fullWidth
                                >
                                    WRITE
                                </StyledOilerButton>
                            </div>
                        </OilerTooltip>
                    </OilerGrid>
                </OilerGrid>

                <OilerGrid
                    spacing={3}
                    direction="row"
                    container
                    item
                    xs={12}
                    md={6}
                >
                    <OilerGrid spacing={2} xs={12} sm={6} item>
                        <OilerTooltip title={exerciseTooltip()}>
                            <div>
                                <StyledOilerButton
                                    onClick={() =>
                                        setOpenActionModal({
                                            state: true,
                                            type: ModalType.EXERCISE,
                                        })
                                    }
                                    color={'secondary'}
                                    size={'large'}
                                    disabled={!isActive || !isExercisable}
                                    fullWidth
                                >
                                    EXERCISE
                                </StyledOilerButton>
                            </div>
                        </OilerTooltip>
                    </OilerGrid>
                    <OilerGrid spacing={2} xs={12} sm={6} item>
                        <OilerTooltip title={`${withdrawTooltip()}`}>
                            <div>
                                <StyledOilerButton
                                    onClick={() =>
                                        setOpenActionModal({
                                            state: true,
                                            type: ModalType.WITHDRAW,
                                        })
                                    }
                                    color={'secondary'}
                                    size={'large'}
                                    disabled={!isWithdrawable}
                                    fullWidth
                                >
                                    WITHDRAW
                                </StyledOilerButton>
                            </div>
                        </OilerTooltip>
                    </OilerGrid>
                </OilerGrid>
            </OilerGrid>
            {getModalContent(
                modal.type,
                modal.state,
                props.option,
                setOpenActionModal,
            )}
        </>
    );
};

export default UserActions;
