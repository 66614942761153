import { css } from 'styled-components';

export const gap = (value: number, direction: 't' | 'r' | 'b' | 'l') =>
    css`
        & > * {
            &:last-of-type {
                margin: 0;
            }
            margin: ${() => {
                switch (direction) {
                    case 't':
                        return `${value}px ${0} ${0} ${0}`;
                    case 'r':
                        return `${0} ${value}px ${0} ${0}`;
                    case 'b':
                        return `${0} ${0} ${value}px ${0}`;
                    case 'l':
                        return `${0} ${0} ${0} ${value}px`;
                    default:
                        return 0;
                }
            }};
        }
    `;
