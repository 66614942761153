import React from 'react';
import { Hidden, HiddenProps } from '@material-ui/core';

interface OilerHiddenProps extends HiddenProps {
    children: React.ReactNode;
}

const OilerHidden = (props: OilerHiddenProps) => {
    return <Hidden {...props}>{props.children}</Hidden>;
};

export default OilerHidden;
