import { createMuiTheme } from '@material-ui/core';

export const DarkTheme = createMuiTheme({
    typography: {
        fontFamily: '"Montserrat", sans-serif',
    },
    palette: {
        common: { black: '#000', white: '#fff' },
        background: { paper: '#fff', default: '#F9F9FB' },
        type: 'dark',
        primary: {
            light: '#faf5dd',
            main: '#f3e7a9',
            dark: '#928b65',
            100: '#fdfaee',
            200: '#faf5dd',
            300: '#f8f1cb',
            400: '#f5ecba',
            500: '#f3e7a9',
            600: '#c2b987',
            700: '#928b65',
            800: '#615c44',
            900: '#312e22',
            contrastText: '#000',
        },
        secondary: {
            light: '#fac99e',
            main: '#f3790c',
            dark: '#924907',
            100: '#fde4ce',
            200: '#fac99e',
            300: '#f8af6d',
            400: '#f5943d',
            500: '#f3790c',
            600: '#c2610a',
            700: '#924907',
            800: '#613005',
            900: '#311802',
            contrastText: '#000',
        },
        error: {
            light: '#e8a5b6',
            main: '#c51e48',
            dark: '#4f0c1d',
            100: '#f3d2da',
            200: '#e8a5b6',
            300: '#dc7891',
            400: '#d14b6d',
            500: '#c51e48',
            600: '#9e183a',
            700: '#76122b',
            800: '#4f0c1d',
            900: '#27060e',
            contrastText: '#fff',
        },
        info: {
            light: '#faf5dd',
            main: '#f3e7a9',
            dark: '#928b65',
            100: '#fdfaee',
            200: '#faf5dd',
            300: '#f8f1cb',
            400: '#f5ecba',
            500: '#f3e7a9',
            600: '#c2b987',
            700: '#928b65',
            800: '#615c44',
            900: '#312e22',
            contrastText: '#fff',
        },
        success: {
            light: '#99e2b7',
            main: '#00B64B',
            dark: '#00491e',
            100: '#ccf0db',
            200: '#99e2b7',
            300: '#66d393',
            400: '#33c56f',
            500: '#00B64B',
            600: '#00923c',
            700: '#006d2d',
            800: '#00491e',
            900: '#00240f',
            contrastText: '#000000',
        },
        text: {
            primary: '#fff',
            secondary: '#000',
            disabled: 'rgba(0,0,0,0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*': {},
                '*::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px',
                },
                '*::-webkit-scrollbar-track': {
                    background: '#312e22',
                },
                '*::-webkit-scrollbar-thumb': {
                    background: '#f3e7a9',
                },
                '*::-webkit-scrollbar-thumb:hover': {
                    background: '#928b65',
                },
            },
        },
    },
});

export default DarkTheme;
