import React from 'react';
import styled from 'styled-components';
import {
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
} from '@material-ui/core';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import OilerTypography from '@oilerKit/OilerTypography';
import noise from '@oilerKit/assets/img/grain-noise.png';
import { alpha } from '@material-ui/core/styles';

const StyledModal = styled(Dialog)<OilerModalProps>`
    .MuiDialogContent-root {
        overflow-y: ${(props) => (props.noScroll ? 'unset' : 'auto')};
        padding: 24px;
        z-index: 1;
    }
    .MuiDialog-paper {
        overflow: hidden;
        border: 2px solid ${(props) => props.theme.palette.primary[500]};
        border-radius: 0;
    }
    .MuiBackdrop-root {
        background-color: ${(props) =>
            alpha(props.theme.palette.common.black, 0.85)};
    }
`;

const StyledDialogTitle = styled(DialogTitle)`
    background: ${(props) => props.theme.palette.primary[500]};
    color: ${(props) => props.theme.palette.common.black};
    padding: 10px 24px;
    display: flex;
    justify-content: space-between;
    ${(props) => props.theme.breakpoints.down('xs')} {
        flex-direction: column;
    }
    //z-index: 9999;

    &:after {
        animation: grain 4s steps(10) infinite;
        content: '';
        background-image: url('${noise}');
        height: 350%;
        left: -50%;
        opacity: 0.8;
        position: absolute;
        top: -90%;
        width: 250%;
    }

    @keyframes grain {
        0%,
        100% {
            transform: translate(0, 0);
        }
        10% {
            transform: translate(-2.5%, -5%);
        }
        20% {
            transform: translate(-7.5%, 2.5%);
        }
        30% {
            transform: translate(3.5%, -12.5%);
        }
        40% {
            transform: translate(-2.5%, 12.5%);
        }
        50% {
            transform: translate(-7.5%, 2.5%);
        }
        60% {
            transform: translate(7.5%, 0%);
        }
        70% {
            transform: translate(0%, 7.5%);
        }
        80% {
            transform: translate(1.5%, 17.5%);
        }
        90% {
            transform: translate(-5%, 5%);
        }
    }
`;

const StyledDialogContent = styled(DialogContent)`
    background: ${(props) => props.theme.palette.common.black};
`;

const StyledDialogActions = styled(DialogContent)`
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    background: ${(props) => props.theme.palette.common.black};
`;

const Action = styled.div`
    z-index: 9999;
`;

interface OilerModalProps extends DialogProps {
    title?: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    cancelButtonOnClick?: () => void;
    confirmButtonOnClick?: () => void;
    closeButton?: boolean;
    noScroll?: boolean;
    headerAction?: React.ReactNode;
}

const OilerModal = (props: OilerModalProps) => {
    const {
        children,
        title,
        cancelButtonText,
        confirmButtonText,
        cancelButtonOnClick,
        confirmButtonOnClick,
        headerAction,
    } = props;

    return (
        <StyledModal maxWidth={'sm'} fullWidth {...props}>
            {title ? (
                <StyledDialogTitle disableTypography {...props}>
                    <OilerTypography variant={'h5'} weight={'bold'}>
                        {title}
                    </OilerTypography>

                    {headerAction && <Action>{headerAction}</Action>}
                </StyledDialogTitle>
            ) : null}
            <StyledDialogContent>{children}</StyledDialogContent>
            {cancelButtonText || confirmButtonText ? (
                <StyledDialogActions>
                    {cancelButtonText ? (
                        <OilerButton onClick={cancelButtonOnClick}>
                            {cancelButtonText}
                        </OilerButton>
                    ) : null}
                    {confirmButtonText ? (
                        <OilerButton onClick={confirmButtonOnClick}>
                            {confirmButtonText}
                        </OilerButton>
                    ) : null}
                </StyledDialogActions>
            ) : null}
        </StyledModal>
    );
};

export default OilerModal;
