import OilerIcon from '@oilerKit/OilerIcon';
import fillingUpCircle from '@oilerKit/assets/gif/filling-up-circle.gif';
import { SpaceProps } from 'styled-system';
import React from 'react';

interface OilerSpinnerProps extends SpaceProps {
    height?: number;
}

const OilerSpinner = (props: OilerSpinnerProps) => {
    const { height = 30 } = props;
    return (
        <OilerIcon
            {...props}
            icon={fillingUpCircle}
            height={height}
            alt="Loading"
        />
    );
};

export default OilerSpinner;
