import React, { useState } from 'react';
import OilerToggleButtonGroup, {
    OilerToggleButton,
} from '@oilerKit/OilerToggleButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeAllowanceMode,
    selectOptions,
} from '@features/options/optionsSlice';
import styled from 'styled-components';

const StyledOilerToggleButton = styled(OilerToggleButton)`
    &.Mui-disabled {
        color: ${(props) => props.theme.palette.primary.main};
    }
`;

const ApprovalType = () => {
    const dispatch = useDispatch();
    const { limitedAllowance } = useSelector(selectOptions);

    const [type, setType] = useState(limitedAllowance ? 'limited' : 'infinite');

    const handleFormat = (event: any, newType: any) => {
        setType(newType);
    };

    return (
        <OilerToggleButtonGroup
            value={type}
            onChange={handleFormat}
            exclusive
            fullWidth
            color={'black'}
        >
            <StyledOilerToggleButton
                value="limited"
                disabled={limitedAllowance === true}
                onClick={() => dispatch(changeAllowanceMode(true))}
            >
                LIMITED
            </StyledOilerToggleButton>
            <StyledOilerToggleButton
                value="infinite"
                disabled={limitedAllowance === false}
                onClick={() => dispatch(changeAllowanceMode(false))}
            >
                INFINITE
            </StyledOilerToggleButton>
        </OilerToggleButtonGroup>
    );
};
export default ApprovalType;
