import { HeadCell } from '@oilerKit/OilerTable/OilerTableHead';
import { SortOrder } from '@constants/index';

export const tableColumns: HeadCell[] = [
    {
        id: 'owner',
        label: 'Owner',
    },
    {
        id: 'code',
        label: 'Code',
    },
    {
        id: 'status',
        label: 'Status',
    },
    {
        id: 'settlementDate',
        label: 'Settlement date (DD/MM/YYYY)',
    },
    {
        id: 'notional',
        label: 'Notional',
    },
    {
        id: 'strike',
        label: 'Strike',
    },
    {
        id: 'expectedPayout',
        label: 'Expected payout',
    },
    {
        id: 'healthIndex',
        label: 'Health index',
    },
    {
        id: 'lambda',
        label: 'Lambda',
    },
];

export const comparators = {
    owner: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? -a[orderBy]?.toString()?.localeCompare(b[orderBy])
            : a[orderBy]?.toString()?.localeCompare(b[orderBy]),
    code: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? -a[orderBy]
                  ?.toString()
                  ?.localeCompare(b[orderBy], 'en', { numeric: true })
            : a[orderBy]
                  ?.toString()
                  ?.localeCompare(b[orderBy], 'en', { numeric: true }),
    status: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? -a[orderBy]?.toString()?.localeCompare(b[orderBy])
            : a[orderBy]?.toString()?.localeCompare(b[orderBy]),
    settlementDate: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? -a[orderBy]?.toString()?.localeCompare(b[orderBy])
            : a[orderBy]?.toString()?.localeCompare(b[orderBy]),
    notional: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? -a[orderBy]
                  ?.toString()
                  ?.localeCompare(b[orderBy], 'en', { numeric: true })
            : a[orderBy]
                  ?.toString()
                  ?.localeCompare(b[orderBy], 'en', { numeric: true }),
    strike: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? -a[orderBy]
                  ?.toString()
                  ?.localeCompare(b[orderBy], 'en', { numeric: true })
            : a[orderBy]
                  ?.toString()
                  ?.localeCompare(b[orderBy], 'en', { numeric: true }),
    expectedPayout: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? -a[orderBy]
                  ?.toString()
                  ?.localeCompare(b[orderBy], 'en', { numeric: true })
            : a[orderBy]
                  ?.toString()
                  ?.localeCompare(b[orderBy], 'en', { numeric: true }),
    healthIndex: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? -a[orderBy]
                  ?.toString()
                  ?.localeCompare(b[orderBy], 'en', { numeric: true })
            : a[orderBy]
                  ?.toString()
                  ?.localeCompare(b[orderBy], 'en', { numeric: true }),
    lambda: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? -a[orderBy]
                  ?.toString()
                  ?.localeCompare(b[orderBy], 'en', { numeric: true })
            : a[orderBy]
                  ?.toString()
                  ?.localeCompare(b[orderBy], 'en', { numeric: true }),
};
