import React from 'react';
import styled from 'styled-components';
import MediaList from '@oilerKit/OilerNav/MediaList';
import OilerTypography from '@oilerKit/OilerTypography';
import OilerLink from '@oilerKit/OilerLink';
import { OILER_LANDING_PAGE_URL } from '@constants/index';

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
    gap: 5px;
`;

const FooterText = styled.div`
    display: flex;
    ${(props) => props.theme.breakpoints.down('xs')} {
        flex-direction: column;
    }
    background: ${(props) => props.theme.palette.common.white};
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    width: 100%;
    gap: 10px;

    a,
    a:hover {
        color: inherit;
        text-decoration: none;
    }
`;

const StyledMediaList = styled(MediaList)`
    ${(props) => props.theme.breakpoints.up(630)} {
        display: none !important;
    }
`;

const StyledLink = styled.span`
    &:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        margin-right: 10px;
        background: ${(props) => props.theme.palette.common.black};
        vertical-align: 10%;
    }
`;

interface OilerFooterProps {
    links?: any[];
}

const OilerFooter = (props: OilerFooterProps) => {
    const { links } = props;

    const currentYear = new Date().getUTCFullYear();
    return (
        <Footer>
            <StyledMediaList />
            <FooterText>
                <OilerTypography color={'textSecondary'} variant={'caption'}>
                    &#169; Copyright {currentYear} by{' '}
                    <OilerLink href={OILER_LANDING_PAGE_URL} target={'_blank'}>
                        Oiler
                    </OilerLink>
                </OilerTypography>
                {links
                    ? links?.map((link: any) => (
                          <OilerTypography
                              key={link.url}
                              color={'textSecondary'}
                              variant={'caption'}
                          >
                              <OilerLink
                                  href={link.url}
                                  key={link.url}
                                  target="_blank"
                              >
                                  {/*<span>&#8729;</span>*/}
                                  <StyledLink>{link.name}</StyledLink>
                              </OilerLink>
                          </OilerTypography>
                      ))
                    : null}
            </FooterText>
        </Footer>
    );
};

export default OilerFooter;
