import React from 'react';
import { Grid, GridProps } from '@material-ui/core';
import styled from 'styled-components';
import {
    flexbox,
    FlexboxProps,
    layout,
    LayoutProps,
    space,
    SpaceProps,
} from 'styled-system';

const StyledGrid = styled(Grid)`
    ${space}
    ${layout}
    ${flexbox}
`;

// @ts-ignore
interface OilerGridProps
    extends GridProps,
        SpaceProps,
        LayoutProps,
        FlexboxProps {}

const OilerGrid = (props: OilerGridProps) => {
    return <StyledGrid {...props}>{props.children}</StyledGrid>;
};

export default OilerGrid;
