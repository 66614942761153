export const FOOTER_LINKS = [
    {
        name: 'Terms of Service',
        url: 'https://docs.oiler.network/oiler-network/legal/terms-and-conditions',
    },
    {
        name: 'Legal & Risk Disclosure',
        url: 'https://docs.oiler.network/oiler-network/token/legal-notice-and-risk-disclosure-statement',
    },
    {
        name: 'Privacy Policy',
        url: 'https://docs.oiler.network/oiler-network/token/privacy-policy',
    },
];

// TODO: should be changed when gitbook is ready
export const OILER_GITBOOK_URL = 'https://docs.oiler.network';
export const TWITTER_URL = 'https://twitter.com/OilerNetwork';
export const TELEGRAM_URL = 'https://t.me/oilernetwork';
export const DISCORD_URL = 'https://discord.gg/qd5AAJPBsq';
export const EMAIL = 'support@oiler.network';
export const MEDIUM_URL = 'https://medium.com/oiler-network';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/oiler-network/';
export const DEFIPULSE_URL = 'https://defipulse.com/';
export const GITHUB_URL = 'https://github.com/oilernetwork';
export const ETHERSCAN_URL = 'https://ropsten.etherscan.io';

export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
}

interface AppsUrls {
    [netName: string]: string;
}

export const APPS_URLS = {
    Ropsten: 'https://ropsten.alpha.oiler.network',
    Mainnet: 'https://alpha.oiler.network',
} as AppsUrls;

export const METAMASK_SITE_URL = 'https://metamask.io/download.html';
export const OILER_LANDING_PAGE_URL = 'https://oiler.network';
