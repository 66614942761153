import React, { useState } from 'react';
import styled from 'styled-components';
import OilerTypography from '@oilerKit/OilerTypography';
import LaunchIcon from '@material-ui/icons/Launch';
import { space, SpaceProps } from 'styled-system';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import OilerIconButton from '@oilerKit/OilerButton/OilerIconButton';
import Slippage from '@oilerKit/OilerSettings/Slippage';
import ApprovalType from '@oilerKit/OilerSettings/ApprovalType';
import { useDispatch, useSelector } from 'react-redux';
import { makeApprovalTxAsync } from '@features/contracts-interactions/transaction-utils';
import { getContractsProvider } from '@services/contracts-provider';
import { ContractType } from '@services/contracts-types';
import TransactionsList from '@components/TransactionsList/TransactionsList';
import { Network } from '@utils/getNameFromNetId';
import { selectTransactions } from '@features/transactions/transactionsSlice';
import OilerTooltip from '@oilerKit/OilerTooltip';
import OilerIdenticon from '@oilerKit/OilerNav/WalletInfo/Identicon';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Row = styled.div``;

const Title = styled.div<SpaceProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${space}
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledButton = styled(OilerButton)`
    &.MuiButton-containedPrimary:disabled {
        cursor: unset;
        background: ${(props) => props.theme.palette.primary.main};
        color: ${(props) => props.theme.palette.primary.contrastText};
    }
`;

interface OilerSettingsProps {
    address: string;
    network: Network;
    disconnect: () => void;
}

const OilerSettings = (props: OilerSettingsProps) => {
    const { address, network, disconnect } = props;
    const { transactions = [] } = useSelector(selectTransactions);
    const noTransactions = transactions.length === 0;

    const dispatch = useDispatch();

    const revokeApproval = () => {
        const collateralContract = getContractsProvider().findOrCreate(
            ContractType.Collateral,
        );

        dispatch(
            makeApprovalTxAsync({
                contract: collateralContract,
                toAddress: process.env.REACT_APP_BALANCER_ROUTER_ADDRESS,
                allowanceAmount: 0,
            }),
        );
    };

    const [showTxns, setShowTxns] = useState<boolean>(false);

    return (
        <Wrapper>
            <Row>
                <Title mb={2}>
                    <OilerTypography
                        weight={'bold'}
                        variant={'h6'}
                        color={'textSecondary'}
                    >
                        Account
                    </OilerTypography>
                    <OilerTypography
                        weight={'bold'}
                        variant={'caption'}
                        color={'textSecondary'}
                    >
                        {network}
                    </OilerTypography>
                </Title>
                <Content>
                    <Title mb={2}>
                        <OilerTypography variant={'h6'} color={'textSecondary'}>
                            {address}
                        </OilerTypography>
                        <OilerIconButton onClick={disconnect}>
                            <OilerIdenticon height={24} />
                        </OilerIconButton>
                    </Title>

                    <StyledButton
                        size={'small'}
                        onClick={() => setShowTxns(true)}
                        disabled={noTransactions}
                    >
                        {noTransactions ? 'NO TRANSACTIONS' : 'TRANSACTIONS'}
                    </StyledButton>
                    <TransactionsList
                        show={showTxns}
                        onHide={() => setShowTxns(false)}
                    />
                </Content>
            </Row>
            <Row>
                <Title mb={2}>
                    <OilerTooltip title="Option trading can be volatile - make sure you set slippage high enough, otherwise your transaction will be reverted">
                        <div>
                            <OilerTypography
                                weight={'bold'}
                                variant={'caption'}
                                color={'textSecondary'}
                            >
                                Slippage
                            </OilerTypography>
                        </div>
                    </OilerTooltip>
                </Title>
                <Content>
                    <Slippage />
                </Content>
            </Row>
            <Row>
                <Title mb={2}>
                    <OilerTooltip title="With Infinite approval you will only have to approve once">
                        <div>
                            <OilerTypography
                                weight={'bold'}
                                variant={'caption'}
                                color={'textSecondary'}
                            >
                                Approval type
                            </OilerTypography>
                        </div>
                    </OilerTooltip>
                </Title>
                <Content>
                    <ApprovalType />
                    <OilerButton
                        color="primary"
                        endIcon={<LaunchIcon />}
                        size={'small'}
                        mt={2}
                        onClick={revokeApproval}
                    >
                        Revoke approval
                    </OilerButton>
                </Content>
            </Row>
        </Wrapper>
    );
};

export default OilerSettings;
