import { buildMintCollateralSteps, mintCollateralTxAsync } from './collateral';
import {
    fetchDynamicOptionsDataAsync,
    setDisplayOption,
} from './../options/optionsSlice';
import { buildSwapSteps, swapOptionTxAsync } from './swap';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from '@store/index';
import { ContractsInteractionsState, initialState } from './types';
import {
    buildWriteSteps,
    writeAndAddLiquidityTxAsync,
    writeOptionTxAsync,
} from './write';
import { openModal } from '@features/ui/uiSlice';
import {} from '@features/transactions/transactionsSlice';
import { getContractsProvider } from '@services/contracts-provider';
import { ContractType } from '@services/contracts-types';
import {
    buildAddLiquiditySteps,
    addLiquidityTxAsync,
    buildRemoveLiquiditySteps,
    removeLiquidityTxAsync,
} from './liquidity';
import { buildWithdrawSteps, withdrawTxAsync } from './withdraw';
import { makeApprovalTxAsync, dispatchTxStatus } from './transaction-utils';
import { buildExerciseSteps, exerciseTxAsync } from './exercise';

export enum InteractionName {
    WRITE = 'WRITE',
    SWAP = 'SWAP',
    ADD_LIQUIDITY = 'ADD_LIQUIDITY',
    REMOVE_LIQUIDITY = 'REMOVE_LIQUIDITY',
    EXERCISE = 'EXERCISE',
    WITHDRAW = 'WITHDRAW',
    MINT_COLLATERAL = 'MINT_COLLATERAL',
    CLEAR_STEPS = 'CLEAR_STEPS',
}

export const buildStepsAsync = createAsyncThunk<
    ContractsInteractionsState,
    { interactionName: InteractionName; [rest: string]: any },
    {
        dispatch: AppDispatch;
    }
>(
    'contracts-interactions/build-steps',
    async (payload, { dispatch, getState }) => {
        const { interactionName } = payload;

        let state;
        // only for requesting fake usdc
        if (interactionName === InteractionName.MINT_COLLATERAL) {
            state = await buildMintCollateralSteps(payload);
            dispatch(
                processStepsAsync({
                    steps: state.steps,
                    ...payload,
                }),
            );
            return state;
        }

        switch (interactionName) {
            case InteractionName.WRITE:
                state = await buildWriteSteps(payload);
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.SWAP:
                state = await buildSwapSteps(payload);
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.ADD_LIQUIDITY:
                state = await buildAddLiquiditySteps(payload);
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.REMOVE_LIQUIDITY:
                state = await buildRemoveLiquiditySteps(payload);
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.EXERCISE:
                state = await buildExerciseSteps(payload);
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.WITHDRAW:
                state = await buildWithdrawSteps(payload);
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        ...payload,
                    }),
                );
                return state;
            case InteractionName.CLEAR_STEPS:
                return initialState;
            default:
                console.error(`No such interaction: "${interactionName}"`);
                return initialState;
        }
    },
);

export const processStepsAsync = createAsyncThunk(
    'contracts-interactions/processSteps',
    async (payload: any, { dispatch, getState }) => {
        const { steps, optionAddress } = payload;

        const { account } = (getState() as any).wallet;
        const { displayOption, limitedAllowance } = (getState() as any).options;
        const { modal } = (getState() as any).ui;

        let res;
        // only for requesting fake usdc
        if (
            steps[0].name === `Request ${process.env.REACT_APP_COLLATERAL_NAME}`
        ) {
            res = await dispatch(
                mintCollateralTxAsync({
                    ...payload,
                }),
            );

            dispatchTxStatus(res, account, dispatch);
            steps.pop();
        }

        const address = optionAddress || displayOption.address;

        const collateralContract = getContractsProvider().findOrCreate(
            ContractType.Collateral,
        );
        const optionContract = getContractsProvider().findOrCreate(
            ContractType.Option,
            address,
        );
        const bRouterContract = getContractsProvider().findOrCreate(
            ContractType.BalancerRouter,
        );

        const poolAddress = await bRouterContract.getPoolByTokens(
            address,
            process.env.REACT_APP_COLLATERAL_ADDRESS,
        );
        const bPoolContract = getContractsProvider().findOrCreate(
            ContractType.BalancerPool,
            poolAddress,
        );

        if (modal.state) {
            dispatch(openModal({ state: false, type: null }));
        }

        for (const step of steps) {
            switch (step.name) {
                case 'Approve Collateral':
                    res = await dispatch(
                        makeApprovalTxAsync({
                            contract: collateralContract,
                            toAddress: payload.allowanceAddress,
                            allowanceAmount: payload.allowanceAmount,
                            limitedAllowance,
                        }),
                    );
                    break;
                case 'Approve Option':
                    res = await dispatch(
                        makeApprovalTxAsync({
                            contract: optionContract,
                            toAddress: payload.allowanceAddress,
                            allowanceAmount: payload.allowanceAmount,
                            limitedAllowance,
                        }),
                    );
                    break;
                case 'Approve LP Tokens':
                    res = await dispatch(
                        makeApprovalTxAsync({
                            contract: bPoolContract,
                            toAddress: payload.allowanceAddress,
                            allowanceAmount: payload.allowanceAmount,
                            limitedAllowance,
                        }),
                    );
                    break;
                case 'Swap':
                    res = await dispatch(
                        swapOptionTxAsync({
                            ...payload,
                        }),
                    );
                    break;
                case 'Write':
                    res = await dispatch(
                        writeOptionTxAsync({
                            ...payload,
                        }),
                    );
                    break;
                case 'Write and Add Liquidity':
                    res = await dispatch(
                        writeAndAddLiquidityTxAsync({
                            ...payload,
                        }),
                    );
                    break;
                case 'Add Liquidity':
                    res = await dispatch(
                        addLiquidityTxAsync({
                            ...payload,
                        }),
                    );
                    break;
                case 'Remove Liquidity':
                    res = await dispatch(
                        removeLiquidityTxAsync({
                            ...payload,
                        }),
                    );
                    break;
                case 'Exercise':
                    res = await dispatch(
                        exerciseTxAsync({
                            ...payload,
                        }),
                    );
                    break;
                case 'Withdraw':
                    res = await dispatch(
                        withdrawTxAsync({
                            ...payload,
                        }),
                    );
                    break;
                default:
                    throw new Error(`No such step: "${step.name}"`);
            }
            dispatchTxStatus(res, account, dispatch);
        }

        await dispatch(fetchDynamicOptionsDataAsync([displayOption]));
        dispatch(setDisplayOption(displayOption.address));
    },
);
