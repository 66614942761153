import { BigNumberish } from '@ethersproject/bignumber';

export type DbOption = {
    name: string;
    code: string;
    address: string;
    family: string;
    type: string;
    expiration: number;
    expDate: string;
    expTime: string;
    strike: string;
    strikeMetric: string;
    exercisableAtBlock: number;
    isActive: boolean;
    unrealizedPnl: [string, string];
    realizedPnl: [string, string];
    fullPnl: [string, string];
};

export type OilerOption = {
    name: string;
    code: string;
    address: string;
    family: string;
    type: string;
    expiration: number;
    expDate: string;
    expTime: string;
    strike: string;
    strikeMetric: string;
    status: string[];
    held: string;
    written: string;
    withdrawable: string;
    optionsPoolReserves: string;
    collateralPoolReserves: string;
    lpTokensBalance: string;
    lpTokensBalanceFormatted: string;
    lpValueLocked: string;
    exercisableAtBlock: number;
    isActive: boolean;
    unrealizedPnl: [string, string];
    realizedPnl: [string, string];
    fullPnl: [string, string];
};

export const defaultOption: OilerOption = {
    name: '---',
    code: '---',
    address: '---',
    family: '---',
    type: '---',
    expiration: 0,
    expDate: '---',
    expTime: '---',
    strike: '---',
    strikeMetric: '---',
    status: ['---'],
    held: '---',
    written: '---',
    withdrawable: '---',
    optionsPoolReserves: '---',
    collateralPoolReserves: '---',
    lpTokensBalance: '---',
    lpTokensBalanceFormatted: '---',
    lpValueLocked: '---',
    exercisableAtBlock: -1,
    isActive: false,
    unrealizedPnl: ['0.00', '0.00'],
    realizedPnl: ['0.00', '0.00'],
    fullPnl: ['0.00', '0.00'],
};

export type SpotPrices = {
    [fromAddress: string]: {
        [toAddress: string]: {
            priceWithFee: BigNumberish;
            priceWithoutFee: BigNumberish;
            calculatedSwapAmount: BigNumberish;
        };
    };
};

export enum TradingOptionsFamily {
    HASHRATE = 'H',
    BASEFEE = 'F',
    FORWARDS = 'FWD',
}

export const tradingOptionsName = {
    H: 'HASHRATE',
    F: 'BASEFEE',
    FWD: 'FORWARDS',
};

export const tradingOptionsInfo = {
    H: {
        metric: 'H',
        name: 'HASHRATE',
        displayName: 'DIFFICULTY',
    },
    F: {
        metric: 'WEI',
        name: 'BASEFEE',
        displayName: 'BASEFEE',
    },
    FWD: {
        metric: 'WEI',
        name: 'FORWARDS',
        displayName: 'FORWARDS',
    },
};

export enum HistoricalOptionsState {
    Initial,
    DbDataFetching,
    DbDataSuccess,
    DbDataFail,
    DynamicDataFetching,
    DynamicDataSuccess,
    DynamicDataFail,
}

export type OptionsState = {
    historicalOptionsState: HistoricalOptionsState;
    historicalOptionsInitialFetch: boolean;
    historicalOptions: { [value in TradingOptionsFamily]: OilerOption[] };
    tradingOption: TradingOptionsFamily;
    displayOption: OilerOption;
    spotPrices: SpotPrices;
    limitedAllowance: boolean;
    slippage: number;
};

export const initialState: OptionsState = {
    historicalOptionsState: HistoricalOptionsState.Initial,
    historicalOptionsInitialFetch: false,
    historicalOptions: Object.values(TradingOptionsFamily).reduce(
        (acc, key) => ({ ...acc, [key]: [] }),
        {} as { [value in TradingOptionsFamily]: OilerOption[] },
    ),
    tradingOption:
        Number(process.env.REACT_APP_CHAIN_ID) !== 1
            ? TradingOptionsFamily.FORWARDS
            : TradingOptionsFamily.BASEFEE,
    displayOption: defaultOption,
    spotPrices: {},
    limitedAllowance: true,
    slippage: 1,
};
