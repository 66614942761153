import { Divider, DividerProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const StyledDivider = styled(Divider)<OilerDividerProps>`
    &.MuiDivider-root {
        ${(props) =>
            props.color
                ? 'background-color:' + props.theme.palette[props.color][900]
                : 'background-color:' + props.theme.palette.primary[900]};
    }
`;

const SmallDividerWrapper = styled.div`
    height: 50px;
    display: flex;
`;

interface OilerDividerProps extends DividerProps {
    color?: 'primary' | 'secondary';
    small?: boolean;
}

const OilerDivider = (props: OilerDividerProps) => {
    const { small } = props;
    return small ? (
        <SmallDividerWrapper>
            <StyledDivider {...props} />
        </SmallDividerWrapper>
    ) : (
        <StyledDivider {...props} />
    );
};

export default OilerDivider;
