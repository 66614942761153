import { NFTEntry } from '@components/NFTsTable/NFTEntry';
import { addTransactionAsync } from '@features/transactions/transactionsSlice';
import { TxType } from '@features/transactions/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    ContractsInteractionsState,
    InteractionState,
    Step,
    StepState,
} from '../types';
import { getContractsProvider } from '@services/contracts-provider';
import { BigNumber } from 'ethers';
import { ContractType } from '@services/contracts-types';
import { ForwardStatus } from '@services/types';

export const getForwardsData = async () => {
    // const forwardsSDK = await getContractsProvider().findOrCreate(
    //     ContractType.ForwardsUI,
    // );

    // const { bidLambda, askLambda, bidStrike, askStrike } =
    //     await forwardsSDK.getBidAskLambdaStrike(BigNumber.from(1));

    const { bidLambda, askLambda, bidStrike, askStrike } = {
        askLambda: BigNumber.from(42),
        bidLambda: BigNumber.from(42),
        bidStrike: BigNumber.from(42),
        askStrike: BigNumber.from(42),
    };

    // const {
    //     liquidity,
    //     lambda,
    //     shortContracts,
    //     longContracts,
    //     poolHealth,
    //     tvl,
    // } = await forwardsSDK.getForwardsValues();

    const {
        liquidity,
        lambda,
        shortContracts,
        longContracts,
        poolHealth,
        tvl,
    } = {
        liquidity: '42',
        lambda: '42',
        shortContracts: '42',
        longContracts: '42',
        poolHealth: '42',
        tvl: '42',
    };

    return {
        bidLambda,
        askLambda,
        bidStrike,
        askStrike,
        liquidity,
        lambda,
        shortContracts,
        longContracts,
        poolHealth,
        tvl,
    };
};

export const getSpecificForwardData = async (nft: NFTEntry) => {
    // const forwardsSDK = await getContractsProvider().findOrCreate(
    //     ContractType.ForwardsUI,
    // );

    // const {
    //     strike,
    //     collateral,
    //     mintTimestamp,
    //     settlementTimestamp,
    //     mintExpectedSettlementPayout,
    //     notional,
    //     mintTick,
    //     settlementTick,
    //     tenorInTicks,
    //     initialLambda,
    // } = (await forwardsSDK.getForward(nft?.code))?.value;

    // const status = await forwardsSDK.getForwardStatus(nft?.code);

    const {
        strike,
        collateral,
        mintTimestamp,
        settlementTimestamp,
        mintExpectedSettlementPayout,
        notional,
        mintTick,
        settlementTick,
        tenorInTicks,
        initialLambda,
        status,
    } = {
        strike: BigNumber.from(42),
        collateral: BigNumber.from(42),
        mintTimestamp: BigNumber.from(42),
        settlementTimestamp: BigNumber.from(42),
        mintExpectedSettlementPayout: BigNumber.from(42),
        notional: BigNumber.from(42),
        mintTick: BigNumber.from(42),
        settlementTick: BigNumber.from(42),
        tenorInTicks: BigNumber.from(42),
        initialLambda: BigNumber.from(42),
        status: ForwardStatus.ACTIVE,
    };

    return {
        strike,
        collateral,
        mintTimestamp,
        settlementTimestamp,
        mintExpectedSettlementPayout,
        notional,
        mintTick,
        settlementTick,
        tenorInTicks,
        initialLambda,
        status,
    };
};

export const buildBuySellSteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const { isLong } = payload;

    const name = isLong ? 'Go Long' : 'Go Short';
    const steps = [{ name, state: StepState.Idle }] as Step[];

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

export const goLongAsync = createAsyncThunk(
    'forwards/goLong',
    async (payload: any, { dispatch }) => {
        const forwardsSDK = await getContractsProvider().findOrCreate(
            ContractType.ForwardsUI,
        );

        const { inputAmount, account } = payload;

        const goLongTx = await forwardsSDK.goLong(
            account,
            BigNumber.from(inputAmount),
        );
        dispatch(addTransactionAsync({ ...goLongTx, type: TxType.GoLong }));

        await goLongTx.wait();
        return goLongTx?.hash;
    },
);

export const goShortAsync = createAsyncThunk(
    'forwards/goShort',
    async (payload: any, { dispatch }) => {
        const forwardsSDK = await getContractsProvider().findOrCreate(
            ContractType.ForwardsUI,
        );

        const { inputAmount, account } = payload;

        const goShortTx = await forwardsSDK.goShort(
            account,
            BigNumber.from(inputAmount),
        );

        dispatch(addTransactionAsync({ ...goShortTx, type: TxType.GoShort }));

        await goShortTx.wait();
        return goShortTx?.hash;
    },
);

export const buildLiquidateSteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const steps = [] as Step[];
    steps.push({ name: 'Liquidate', state: StepState.Idle });

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

export const liquidateTxAsync = createAsyncThunk(
    'forwards/liquidate',
    async (payload: any, { dispatch, getState }) => {
        const nftToken: NFTEntry = (getState() as any).firebase.displayNftToken;

        const forwardsSDK = await getContractsProvider().findOrCreate(
            ContractType.ForwardsUI,
        );

        const liquidateTx = await forwardsSDK.liquidate(nftToken?.code);

        dispatch(
            addTransactionAsync({ ...liquidateTx, type: TxType.Liquidate }),
        );

        await liquidateTx.wait();
        return liquidateTx?.hash;
    },
);

export const buildSettleSteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const steps = [] as Step[];
    steps.push({ name: 'Settle', state: StepState.Idle });

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

export const settleTxAsync = createAsyncThunk(
    'forwards/settle',
    async (payload: any, { dispatch, getState }) => {
        const nftToken: NFTEntry = (getState() as any).firebase.displayNftToken;

        const forwardsSDK = await getContractsProvider().findOrCreate(
            ContractType.ForwardsUI,
        );
        const settleTx = await forwardsSDK.settle(nftToken?.code);

        dispatch(addTransactionAsync({ ...settleTx, type: TxType.Settle }));

        await settleTx.wait();
        return settleTx?.hash;
    },
);

export const buildAddCollateralSteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const steps = [] as Step[];
    steps.push({ name: 'Add Collateral', state: StepState.Idle });

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

interface AddCollateralPayload {
    amount: string;
    tokenId: string;
}

export const addCollateralTxAsync = createAsyncThunk(
    'forwards/add-collateral',
    async (payload: AddCollateralPayload, { dispatch, getState }) => {
        const { amount, tokenId } = payload;

        const forwardsSDK = await getContractsProvider().findOrCreate(
            ContractType.ForwardsUI,
        );
        const addCollateralTx = await forwardsSDK.addCollateral(
            BigNumber.from(amount),
            tokenId,
        );
        dispatch(
            addTransactionAsync({
                ...addCollateralTx,
                type: TxType.AddCollateral,
            }),
        );
        await addCollateralTx.wait();
        return addCollateralTx?.hash;
    },
);

export const buildRemoveCollateralSteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const steps = [] as Step[];
    steps.push({ name: 'Remove Collateral', state: StepState.Idle });

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

interface RemoveCollateralPayload {
    account: string;
    amount: string;
    tokenId: string;
}

export const removeCollateralTxAsync = createAsyncThunk(
    'forwards/remove-collateral',
    async (payload: RemoveCollateralPayload, { dispatch, getState }) => {
        const { amount, tokenId /* account */ } = payload;

        const forwardsSDK = await getContractsProvider().findOrCreate(
            ContractType.ForwardsUI,
        );
        const removeCollateralTx = await forwardsSDK.removeCollateral(
            BigNumber.from(amount),
            tokenId,
        );

        dispatch(
            addTransactionAsync({
                ...removeCollateralTx,
                type: TxType.RemoveCollateral,
            }),
        );
        await removeCollateralTx.wait();
        return removeCollateralTx?.hash;
    },
);

export const buildAddLiquiditySteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const steps = [] as Step[];
    steps.push({ name: 'Add Liquidity', state: StepState.Idle });

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

interface AddLiquidityPayload {
    account: string;
    amount: string;
}

export const addLiquidityTxAsync = createAsyncThunk(
    'forwards/add-liquidity',
    async (payload: AddLiquidityPayload, { dispatch }) => {
        const { amount /* account */ } = payload;

        const forwardsSDK = await getContractsProvider().findOrCreate(
            ContractType.ForwardsUI,
        );
        const addLiquidityTx = await forwardsSDK.addLiquidity(
            BigNumber.from(amount),
            amount, // todo
        );

        dispatch(
            addTransactionAsync({
                ...addLiquidityTx,
                type: TxType.AddLiquidity,
            }),
        );
        await addLiquidityTx.wait();
        return addLiquidityTx?.hash;
    },
);

export const buildRemoveLiquiditySteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const steps = [] as Step[];
    steps.push({ name: 'Remove Liquidity', state: StepState.Idle });

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

interface RemoveLiquidityPayload {
    account: string;
    tokenId: string;
}

export const removeLiquidityTxAsync = createAsyncThunk(
    'forwards/remove-liquidity',
    async (payload: RemoveLiquidityPayload, { dispatch, getState }) => {
        const { tokenId /* account */ } = payload;

        const forwardsSDK = await getContractsProvider().findOrCreate(
            ContractType.ForwardsUI,
        );
        const removeLiquidityTx = await forwardsSDK.removeLiquidity(tokenId);

        dispatch(
            addTransactionAsync({
                ...removeLiquidityTx,
                type: TxType.RemoveLiquidity,
            }),
        );
        await removeLiquidityTx.wait();
        return removeLiquidityTx?.hash;
    },
);
