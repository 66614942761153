import React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';
import { space, SpaceProps } from 'styled-system';
import styled from 'styled-components';
import ConditionalLink from '@oilerKit/OilerButton/ConditionalLink';

const StyledIconButton = styled(IconButton)`
    &&&.MuiIconButton-root:hover {
        background-color: transparent;
        color: red;
    }
    ${space}
`;

interface OilerIconButtonProps extends IconButtonProps, SpaceProps {
    target?: '_blank';
    href?: string;
}

const OilerIconButton = (props: OilerIconButtonProps) => {
    const { target, href } = props;

    return (
        <ConditionalLink condition={!!target} target={target} href={href}>
            <StyledIconButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                {...props}
            >
                {props.children}
            </StyledIconButton>
        </ConditionalLink>
    );
};

export default OilerIconButton;
