import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';
import invariant from 'tiny-invariant';

invariant(
    process.env.REACT_APP_FIREBASE_API_KEY &&
        process.env.REACT_APP_FIREBASE_AUTH_DOMAIN &&
        process.env.REACT_APP_FIREBASE_DATABASE_URL &&
        process.env.REACT_APP_FIREBASE_PROJECT_ID,
    'Please set REACT_APP_FIREBASE_* in env',
);

const firebase = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
});

const auth = getAuth();
signInAnonymously(auth)
    .then(() => {})
    .catch((error) => {
        console.error(error);
        throw new Error('Could not init Firebase');
    });

export const database = getDatabase();
export const firestore = getFirestore();

export default firebase;
