import React from 'react';
import { flexbox, FlexboxProps, space, SpaceProps } from 'styled-system';
import { Typography, TypographyProps } from '@material-ui/core';
import styled from 'styled-components';

interface OilerTypographyProps
    extends SpaceProps,
        FlexboxProps,
        TypographyProps {
    uppercase?: boolean;
    weight?:
        | 'thin'
        | 'light'
        | 'regular'
        | 'semiBold'
        | 'bold'
        | 'extraBold'
        | 'black';
}

const mapFontNames = (userName: string | undefined): string => {
    switch (userName) {
        case 'light':
            return 'fontWeightLight';
        case 'regular':
            return 'fontWeightRegular';
        case 'medium':
            return 'fontWeightMedium';
        case 'bold':
            return 'fontWeightBold';
        default:
            return 'fontWeightMedium';
    }
};

const StyledTypography = styled(Typography)<OilerTypographyProps>`
    text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
    font-weight: ${(props) =>
        props.theme.typography[mapFontNames(props.weight)]};
    ${space} ${flexbox};
`;

const OilerTypography = (props: OilerTypographyProps) => {
    return <StyledTypography {...props}>{props.children}</StyledTypography>;
};

export default OilerTypography;
