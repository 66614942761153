import * as rlpEncoder from 'rlp';
import { Network } from './getNameFromNetId';

export const getBlockRLP = (block: any, network: Network): string => {
    let blockHeader = [
        block.parentHash,
        block.sha3Uncles,
        block.miner,
        block.stateRoot,
        block.transactionsRoot,
        block.receiptsRoot,
        block.logsBloom,
        block.difficulty,
        block.number,
        block.gasLimit,
        block.gasUsed,
        block.timestamp,
        block.extraData,
        block.mixHash,
        block.nonce,
    ];

    console.log('baseFeePerGas', block.baseFeePerGas);

    if (block.baseFeePerGas) {
        blockHeader.push(block.baseFeePerGas);
    }

    console.log('blockHeader', blockHeader);

    blockHeader = blockHeader.map((e) => (e === '0x0' ? '0x' : e));

    const rlpEncoded = rlpEncoder.encode(blockHeader);

    console.log('rlpEncoded', rlpEncoded);

    return rlpEncoded.toString('hex');
};
