import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { buildStepsAsync } from './steps-builder';
import {
    ContractsInteractionsState,
    initialState,
    InteractionState,
} from '../types';
import {
    addCollateralTxAsync,
    goLongAsync,
    goShortAsync,
    liquidateTxAsync,
    removeCollateralTxAsync,
    addLiquidityTxAsync,
    removeLiquidityTxAsync,
    settleTxAsync,
} from './actions';
import { moveContractInteractionStep } from '../blockchainMessengerSlice';

export const forwardsMessenger = createSlice({
    name: 'forwardsMessenger',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(buildStepsAsync.pending, (state, action) => {
                state = initialState;
            })
            .addCase(buildStepsAsync.rejected, (state, action) => {
                state = initialState;
            })
            .addCase(
                buildStepsAsync.fulfilled,
                (_, action: PayloadAction<ContractsInteractionsState>) => ({
                    ...action.payload,
                }),
            )

            .addCase(goLongAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(goLongAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })
            .addCase(goShortAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(goShortAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })
            .addCase(liquidateTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(liquidateTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })
            .addCase(settleTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(settleTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })
            .addCase(addCollateralTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(addCollateralTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })
            .addCase(removeCollateralTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(removeCollateralTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })
            .addCase(addLiquidityTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(addLiquidityTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            })
            .addCase(removeLiquidityTxAsync.fulfilled, (state, action) =>
                moveContractInteractionStep(state),
            )
            .addCase(removeLiquidityTxAsync.rejected, (state, action) => {
                state.state = InteractionState.Finished;
            });
    },
});

export const selectForwardsMessenger = (state: RootState) =>
    state.forwardsMessenger;

export default forwardsMessenger.reducer;
