import React from 'react';
import styled from 'styled-components';
import {
    Alert,
    AlertProps,
    AlertTitle,
    AlertTitleProps,
} from '@material-ui/lab';
import { space, SpaceProps } from 'styled-system';

const StyledAlert = styled(Alert)`
    &.MuiAlert-root {
        border-radius: 0;
    }
    ${space}
`;

interface OilerAlertProps extends AlertProps, SpaceProps {}

export const OilerAlert = (props: OilerAlertProps) => {
    return <StyledAlert {...props}>{props.children}</StyledAlert>;
};

export const OilerAlertTitle = (props: AlertTitleProps) => {
    return <AlertTitle {...props}>{props.children}</AlertTitle>;
};
