import styled from 'styled-components';
import { TableBody, TableBodyProps } from '@material-ui/core';
import React from 'react';

const StyledTableBody = styled(TableBody)``;

const OilerTableBody = (props: TableBodyProps) => {
    return <StyledTableBody {...props}>{props.children}</StyledTableBody>;
};

export default OilerTableBody;
