import React from 'react';
import {
    ToastProvider,
    ToastProviderProps,
    useToasts,
} from 'react-toast-notifications';
import OilerToast from '@oilerKit/OilerToast/OilerToast';
import { ToastListener } from './ToastListener';

export const OilerToastProvider = (props: ToastProviderProps) => {
    return (
        <ToastProvider {...props} components={{ Toast: OilerToast }}>
            <>
                <ToastListener />
                {props.children}
            </>
        </ToastProvider>
    );
};

export const useOilerToast = useToasts;
