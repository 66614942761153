import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import '@oilerKit/fonts.css';
import { Provider } from 'react-redux';
import { rootReducer } from './store';
import App from './App';
import {
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import OilerThemeProvider from '@oilerKit/themes/OilerThemeProvider';
import ErrorBoundary from '@components/ErrorBoundary';
import OilerIcon from '@oilerKit/OilerIcon';
import pumpJack from '@oilerKit/assets/gif/pump-jack.gif';
import { OilerToastProvider } from '@oilerKit/OilerToast/OilerToastProvider';
import styled from 'styled-components';
import { FOOTER_LINKS } from '@constants/index';
import OilerFooter from '@oilerKit/OilerFooter';
import OilerContainer from '@oilerKit/OilerContainer';
import OilerNav from '@oilerKit/OilerNav/OilerNav';
import OilerIPblocker from '@oilerKit/OilerIPblocker/OilerIPblocker';
import ConnectedInfo from '@oilerKit/OilerNav/ConnectedInfo';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['transactions'],
};

const persistedReducer = persistReducer(
    persistConfig,
    combineReducers(rootReducer),
);
const storeToPersist = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

const persistor = persistStore(storeToPersist);

const AppWrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

ReactDOM.render(
    <React.StrictMode>
        <Provider store={storeToPersist}>
            <PersistGate loading={null} persistor={persistor}>
                <ErrorBoundary>
                    <OilerThemeProvider>
                        <Suspense
                            fallback={
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                >
                                    <OilerIcon icon={pumpJack} height={40} />
                                </div>
                            }
                        >
                            <OilerToastProvider>
                                <AppWrapper>
                                    <OilerContainer>
                                        <OilerNav />
                                        <OilerIPblocker>
                                            <ConnectedInfo walletBalance />
                                            <App />
                                        </OilerIPblocker>
                                    </OilerContainer>
                                    <OilerFooter links={FOOTER_LINKS} />
                                </AppWrapper>
                            </OilerToastProvider>
                        </Suspense>
                    </OilerThemeProvider>
                </ErrorBoundary>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
