import OilerGrid from '@oilerKit/OilerGrid';
import React, { useState } from 'react';
import OilerModal from '@oilerKit/OilerModal';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import styled from 'styled-components';
import OilerToggleButtonGroup, {
    OilerToggleButton,
} from '@oilerKit/OilerToggleButtonGroup';
import OilerStatistic from '@oilerKit/OilerStatistic';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import OilerInput from '@oilerKit/OilerInput';
import { useDispatch, useSelector } from 'react-redux';
import {
    buildStepsAsync,
    InteractionName,
} from '@features/contracts-interactions/forwards/steps-builder';
import { selectFirebase } from '@features/firebase/firebaseSlice';
import { selectWallet } from '@features/wallet/walletSlice';
import OilerDivider from '@oilerKit/OilerDivider';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { isValidNumber } from '@utils/isValidNumber';

const StyledOilerModal = styled(OilerGrid)`
    ${(props) => props.theme.breakpoints.up('sm')} {
        width: 272px;
    }
    margin: auto;
`;

interface CollateralModalProps {
    open: boolean;
    setOpenActionModal: (flag: boolean) => void;
}

const SuffixWrapper = styled.div`
    display: flex;
`;

interface SuffixProps {
    possiblePositionHealth: number;
    units: string;
}

const Suffix = (props: SuffixProps) => {
    return (
        <SuffixWrapper>
            <ArrowForwardIcon fontSize={'small'} />{' '}
            {props.possiblePositionHealth}
            {props.units}
        </SuffixWrapper>
    );
};

interface StyledOilerStatisticProps {
    addCollateral: boolean;
    theme: any;
}

const StyledOilerStatistic = styled(OilerStatistic)`
    .MuiTypography-h6,
    .MuiTypography-colorPrimary.MuiTypography-caption {
        color: ${(props: StyledOilerStatisticProps) =>
            props.addCollateral
                ? props.theme.palette.success.main
                : props.theme.palette.error[400]};
    }
    .MuiTypography-colorPrimary.MuiTypography-caption {
        color: ${(props: StyledOilerStatisticProps) =>
            props.addCollateral
                ? props.theme.palette.error[400]
                : props.theme.palette.success.main};
    }
`;

const CollateralModal = (props: CollateralModalProps) => {
    const { open, setOpenActionModal } = props;
    const theme = useTheme();
    const isXsmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const justify = isXsmallScreen ? 'flex-start' : 'center';

    const [addCollateralMode, setAddCollateralMode] = useState<string>(
        InteractionName.ADD_COLLATERAL,
    );
    const [collateralInputAmount, setCollateralInputAmount] =
        useState<string>('0');

    const { displayNftToken } = useSelector(selectFirebase);
    const { account } = useSelector(selectWallet);

    const handleCollateralMode = (
        event: React.MouseEvent<HTMLElement>,
        collateralMode: string,
    ) => setAddCollateralMode(collateralMode);

    const dispatch = useDispatch();

    const handleAddCollateral = async () => {
        setOpenActionModal(false);
        dispatch(
            buildStepsAsync({
                interactionName: InteractionName.ADD_COLLATERAL,
                amount: collateralInputAmount,
                tokenId: displayNftToken?.code,
            }),
        );
    };

    const handleRemoveCollateral = async () => {
        setOpenActionModal(false);
        dispatch(
            buildStepsAsync({
                interactionName: InteractionName.REMOVE_COLLATERAL,
                amount: collateralInputAmount,
                tokenId: displayNftToken?.code,
                account,
            }),
        );
    };

    const HeaderAction = (
        <OilerToggleButtonGroup
            value={addCollateralMode}
            onChange={handleCollateralMode}
            exclusive
            color={'black'}
            fullWidth
        >
            <OilerToggleButton value={InteractionName.ADD_COLLATERAL}>
                ADD
            </OilerToggleButton>
            <OilerToggleButton value={InteractionName.REMOVE_COLLATERAL}>
                REMOVE
            </OilerToggleButton>
        </OilerToggleButtonGroup>
    );

    const handleCollateralInputChange = (value: string) => {
        setCollateralInputAmount(value);
    };

    return (
        <OilerModal
            open={open}
            onClose={() => setOpenActionModal(false)}
            title={'COLLATERAL'}
            headerAction={HeaderAction}
        >
            <OilerGrid item md container direction={'row'} mb={4}>
                <OilerGrid item sm container justifyContent={justify}>
                    <StyledOilerStatistic
                        label="POSITION HEALTH"
                        value="95%"
                        suffix={
                            <Suffix possiblePositionHealth={10} units="%" />
                        }
                        addCollateral
                    />
                </OilerGrid>
                <OilerGrid item>
                    <OilerDivider orientation={'vertical'} />
                </OilerGrid>
                <OilerGrid item sm container justifyContent={justify}>
                    <OilerStatistic
                        label="POSITION "
                        value="100 USDC"
                        suffix={
                            <Suffix possiblePositionHealth={443} units="USDC" />
                        }
                    />
                </OilerGrid>
            </OilerGrid>

            <StyledOilerModal
                direction="column"
                justify="center"
                alignItems="center"
            >
                <OilerGrid item md>
                    <OilerInput
                        fullWidth
                        label={`Collateral ${process.env.REACT_APP_COLLATERAL_NAME}`}
                        handleInputChange={handleCollateralInputChange}
                        inputAmount={collateralInputAmount}
                    />
                </OilerGrid>

                <OilerButton
                    fullWidth
                    size={'large'}
                    mt={4}
                    color={'secondary'}
                    disabled={
                        !isValidNumber(collateralInputAmount) ||
                        Number(collateralInputAmount) === 0
                    }
                    onClick={
                        addCollateralMode === InteractionName.ADD_COLLATERAL
                            ? handleAddCollateral
                            : handleRemoveCollateral
                    }
                >
                    {addCollateralMode === InteractionName.ADD_COLLATERAL
                        ? 'ADD'
                        : 'REMOVE'}{' '}
                    COLLATERAL
                </OilerButton>
            </StyledOilerModal>
        </OilerModal>
    );
};

export default CollateralModal;
