import { ReactNode } from 'react';
import { Options } from 'react-toast-notifications';

export interface Toast {
    content?: ReactNode;
    options?: Options;
    callback?: (id: string) => void;
}

export enum ModalType {
    SWAP,
    WRITE,
    EXERCISE,
    WITHDRAW,
    LIQUIDITY,
    TRADE,
}

export interface Modal {
    state: boolean;
    type: ModalType | null;
}

export type UiState = {
    toast: Toast;
    modal: ActionModalProps;
};

export const initialState: UiState = {
    toast: {},
    modal: { state: false, type: null },
};

export interface ActionModalProps {
    state: boolean;
    type: ModalType | null;
}
