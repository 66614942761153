export enum TxState {
    Idle = 'IDLE',
    Pending = 'PENDING',
    Success = 'SUCCESS',
    Fail = 'FAIL',
    Rejected = 'REJECTED',
}

export enum TxType {
    Approval = 'Approval',
    Swap = 'Swap',
    Write = 'Write',
    WriteAndAddLiquidity = 'Write and Add Liquidity',
    AddLiquidity = 'Add Liquidity',
    RemoveLiquidity = 'Remove Liquidity',
    Withdraw = 'Withdraw',
    Exercise = 'Exercise',
    MintCollateral = 'MintCollateral',
    Unknown = 'Unknown',
    GoLong = 'Open Long Position',
    GoShort = 'Open Short Position',
    Liquidate = 'Liquidate',
    Settle = 'Settle',
    AddCollateral = 'Add Collateral',
    RemoveCollateral = 'Remove Collateral',
}

export interface Transaction {
    type: TxType;
    state: TxState;
    url?: string;
    hash?: string;
    nonce?: number;
    chainId?: number;
}

export interface TransactionsState {
    transactions: Transaction[];
}

export const initialState: TransactionsState = {
    transactions: [],
};
