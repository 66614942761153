import { addTransactionAsync } from '@features/transactions/transactionsSlice';
import { TxType } from '@features/transactions/types';
import { makeUIToast } from '@features/ui/uiSlice';
import { fetchBalancesAsync } from '@features/wallet/walletSlice';
import { createAsyncThunk, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { utils, ethers } from 'ethers';
import invariant from 'invariant';
import { InteractionName } from './steps-builder';

export const makeApprovalTxAsync = createAsyncThunk(
    'transactions/approval-tx',
    async (payload: any, { dispatch, getState }) => {
        const { contract, toAddress, allowanceAmount, limitedAllowance } =
            payload;

        const { account } = (getState() as any).wallet;
        invariant(account, 'No account specified');

        const allowance = limitedAllowance
            ? utils.parseEther(allowanceAmount.toString())
            : ethers.constants.MaxUint256;

        const approvalTx = await contract.approve(toAddress, allowance);

        dispatch(addTransactionAsync({ ...approvalTx, type: TxType.Approval }));

        await approvalTx.wait();
        return approvalTx?.hash;
    },
);

export const dispatchTxStatus = (
    res: any,
    account: string,
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
    const dispatchToast = (payload: any) => dispatch(makeUIToast(payload));
    const dispatchFetchBalances = () => dispatch(fetchBalancesAsync(account));

    if (res?.hasOwnProperty('error')) {
        const interactionName = res?.meta?.arg?.interactionName;

        if (interactionName === InteractionName.WRITE) {
            dispatchToast({
                content: 'Invalid write amount!',
                options: { autoDismiss: true, appearance: 'error' },
            });
        } else if (interactionName === InteractionName.SWAP) {
            dispatchToast({
                content: 'Invalid swap amount!',
                options: { autoDismiss: true, appearance: 'error' },
            });
        } else if (interactionName === InteractionName.ADD_LIQUIDITY) {
            dispatchToast({
                content: 'Invalid liquidity amount!',
                options: { autoDismiss: true, appearance: 'error' },
            });
        } else if (interactionName === InteractionName.REMOVE_LIQUIDITY) {
            dispatchToast({
                content: 'Invalid lp tokens amount!',
                options: { autoDismiss: true, appearance: 'error' },
            });
        } else if (interactionName === InteractionName.EXERCISE) {
            dispatchToast({
                content: 'The option is not exercisable!',
                options: { autoDismiss: true, appearance: 'error' },
            });
        } else if (interactionName === InteractionName.WITHDRAW) {
            dispatchToast({
                content: 'Invalid withdraw amount!',
                options: { autoDismiss: true, appearance: 'error' },
            });
        } else if (interactionName === InteractionName.MINT_COLLATERAL) {
            dispatchToast({
                content: 'Tokens can be requested every 24hrs!',
                options: { autoDismiss: true, appearance: 'error' },
            });
        } else {
            dispatchToast({
                content: 'Transaction failed!',
                options: { autoDismiss: true, appearance: 'error' },
            });
        }

        throw new Error('Tx failed');
    } else {
        dispatchToast({
            content: 'Transaction succeeded!',
            options: { autoDismiss: true, appearance: 'success' },
        });

        dispatchFetchBalances();
    }
};
