import OilerInput from '@oilerKit/OilerInput';
import OilerGrid from '@oilerKit/OilerGrid';
import React, { useEffect, useState } from 'react';
import OilerModal from '@oilerKit/OilerModal';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import OilerIconButton from '@oilerKit/OilerButton/OilerIconButton';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import styled from 'styled-components';
import { selectWallet } from '@features/wallet/walletSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAmountIn,
    getAmountOut,
    initiateSwapPricingData,
    selectOptions,
} from '@features/options/optionsSlice';
import { ethers } from 'ethers';
import {
    buildStepsAsync,
    InteractionName,
} from '@features/contracts-interactions/steps-builder';
import { OilerOption } from '@features/options/types';
import { isValidNumber } from '@utils/isValidNumber';
import { ActionModalProps } from '@features/ui/types';

const StyledOilerModal = styled(OilerGrid)`
    ${(props) => props.theme.breakpoints.up('sm')} {
        width: 272px;
        margin: auto;
    }
`;

interface SwapModalProps {
    open: boolean;
    option: OilerOption;
    // eslint-disable-next-line
    setOpenActionModal: ({}: ActionModalProps) => void;
}

const SwapModal = (props: SwapModalProps) => {
    const { open, setOpenActionModal, option } = props;

    const dispatch = useDispatch();

    const { balances } = useSelector(selectWallet);
    const { spotPrices, slippage } = useSelector(selectOptions);

    const [reverseSwap, setReverseSwap] = useState<boolean>(false);
    const [assetInterestTop, setAssetInterestTop] = useState<boolean>(true);
    const [topInputAmount, setTopInputAmount] = useState<string>('0');
    const [bottomInputAmount, setBottomInputAmount] = useState<string>('0');

    const placeholder = '---';

    useEffect(() => {
        dispatch(
            initiateSwapPricingData({
                from: process.env.REACT_APP_COLLATERAL_ADDRESS!,
                to: option.address,
            }),
        );
    }, [dispatch, option.address]);

    useEffect(() => {
        const optionPricer = reverseSwap
            ? spotPrices[option.address]?.[
                  process.env.REACT_APP_COLLATERAL_ADDRESS!
              ]
            : spotPrices[process.env.REACT_APP_COLLATERAL_ADDRESS!]?.[
                  option.address
              ];

        if (!assetInterestTop) {
            const poolReserves = reverseSwap
                ? option.optionsPoolReserves
                : option.collateralPoolReserves;

            if (
                Number(optionPricer?.calculatedSwapAmount) >
                Number(poolReserves) / 2
            ) {
                setTopInputAmount(placeholder);
                return;
            }

            setTopInputAmount(optionPricer?.calculatedSwapAmount?.toString());
        } else {
            const poolReserves = reverseSwap
                ? option.collateralPoolReserves
                : option.optionsPoolReserves;

            if (
                Number(optionPricer?.calculatedSwapAmount) >
                Number(poolReserves) / 2
            ) {
                setBottomInputAmount(placeholder);
                return;
            }

            setBottomInputAmount(
                optionPricer?.calculatedSwapAmount?.toString(),
            );
        }
    }, [spotPrices, assetInterestTop, reverseSwap, option]);

    const handleTopInputChange = (
        value: string,
        reverse: boolean = reverseSwap,
    ) => {
        dispatch(
            getAmountOut({
                from: reverse
                    ? option.address
                    : process.env.REACT_APP_COLLATERAL_ADDRESS!,
                to: reverse
                    ? process.env.REACT_APP_COLLATERAL_ADDRESS!
                    : option.address,
                amountIn: value,
            }),
        );

        setAssetInterestTop(true);
        setTopInputAmount(value);
        setBottomInputAmount(placeholder);
    };

    const handleBottomInputChange = (
        value: string,
        reverse: boolean = reverseSwap,
    ) => {
        dispatch(
            getAmountIn({
                from: reverse
                    ? option.address
                    : process.env.REACT_APP_COLLATERAL_ADDRESS!,
                to: reverse
                    ? process.env.REACT_APP_COLLATERAL_ADDRESS!
                    : option.address,
                amountOut: value,
            }),
        );

        setAssetInterestTop(false);
        setBottomInputAmount(value);
        setTopInputAmount(placeholder);
    };

    const handleTopInputMaxClick = () => {
        reverseSwap
            ? handleTopInputChange(option.held)
            : handleTopInputChange(
                  ethers.utils.formatUnits(balances['USDC'], 6),
              );
    };

    const handleBottomInputMaxClick = () => {
        reverseSwap
            ? handleBottomInputChange(
                  ethers.utils.formatUnits(balances['USDC'], 6),
              )
            : handleBottomInputChange(option.held);
    };

    const handleReverseSwap = () => {
        setReverseSwap(!reverseSwap);

        if (reverseSwap) {
            handleTopInputChange(bottomInputAmount, !reverseSwap);
        } else {
            handleBottomInputChange(topInputAmount, !reverseSwap);
        }
    };

    const handleSwapClick = () => {
        if (topInputAmount !== '0') {
            dispatch(
                buildStepsAsync({
                    interactionName: InteractionName.SWAP,
                    reverseSwap,
                    from: reverseSwap
                        ? option.address
                        : process.env.REACT_APP_COLLATERAL_ADDRESS,
                    to: reverseSwap
                        ? process.env.REACT_APP_COLLATERAL_ADDRESS
                        : option.address,
                    swapAmount: topInputAmount,
                    optionAddress: option.address,
                    allowanceAmount: topInputAmount,
                    allowanceAddress:
                        process.env.REACT_APP_BALANCER_ROUTER_ADDRESS,
                    slippage,
                }),
            );
        }
    };

    return (
        <OilerModal
            open={open}
            onClose={() => setOpenActionModal({ state: false, type: null })}
            title={'SWAP'}
        >
            <StyledOilerModal
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <OilerInput
                    fullWidth
                    balanceValue={Number(
                        reverseSwap
                            ? ethers.utils.formatUnits(balances['USDC'], 6)
                            : option.held,
                    )}
                    label={`Swap from ${
                        reverseSwap
                            ? `${option.code}`
                            : process.env.REACT_APP_COLLATERAL_NAME
                    }`}
                    handleInputChange={handleTopInputChange}
                    inputAmount={topInputAmount}
                    handleMaxClick={handleTopInputMaxClick}
                    disabled={topInputAmount === placeholder}
                />

                <OilerIconButton my={10} onClick={handleReverseSwap}>
                    <SwapVertIcon
                        style={{ color: 'white' }}
                        fontSize={'large'}
                    />
                </OilerIconButton>

                <OilerInput
                    fullWidth
                    balanceValue={Number(
                        reverseSwap
                            ? option.held
                            : ethers.utils.formatUnits(balances['USDC'], 6),
                    )}
                    label={`Swap to ${
                        reverseSwap
                            ? process.env.REACT_APP_COLLATERAL_NAME
                            : `${option.code}`
                    }`}
                    handleInputChange={handleBottomInputChange}
                    inputAmount={bottomInputAmount}
                    handleMaxClick={handleBottomInputMaxClick}
                    disabled={bottomInputAmount === placeholder}
                />

                <OilerButton
                    fullWidth
                    size={'large'}
                    disabled={!isValidNumber(topInputAmount)}
                    mt={4}
                    onClick={handleSwapClick}
                    color={'secondary'}
                >
                    SWAP
                </OilerButton>
            </StyledOilerModal>
        </OilerModal>
    );
};

export default SwapModal;
