import OilerBox from '@oilerKit/OilerBox';
import OilerGrid from '@oilerKit/OilerGrid';
import OilerStatistic from '@oilerKit/OilerStatistic';
import { useDispatch, useSelector } from 'react-redux';
import { selectOptions } from '@features/options/optionsSlice';
import OilerTooltip from '@oilerKit/OilerTooltip';
import OilerDivider from '@oilerKit/OilerDivider';
import OilerIconButton from '@oilerKit/OilerButton/OilerIconButton';
import { ReactComponent as FillingIcon } from '@oilerKit/assets/icons/liquidity.svg';
import { openModal, selectUI } from '@features/ui/uiSlice';
import { ActionModalProps, ModalType } from '@features/ui/types';
import LiquidityModal from '@components/TradingActions/LiquidityModal';
import OilerHidden from '@oilerKit/OilerHidden';
import { SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';
import { formatNbToSI } from '@utils/formatNbToSI';
import TradingActions from '@components/TradingActions/TradingActions';
import React from 'react';
import styled from 'styled-components';

//TODO export proper custom Theme interface
interface StyledOilerStatisticProps {
    isExpired: boolean;
    isExercised: boolean;
    theme: any;
}

const StyledOilerStatistic = styled(OilerStatistic)`
    .MuiTypography-colorTextPrimary {
        color: ${(props: StyledOilerStatisticProps) =>
            props.isExpired
                ? props.theme.palette.error[400]
                : props.isExercised
                ? props.theme.palette.success.main
                : 'inherit'};
        font-weight: ${(props: StyledOilerStatisticProps) =>
            props.isExpired || props.isExercised
                ? props.theme.typography.fontWeightBold
                : 'initial'};
    }
    .MuiTypography-h6 {
        color: ${(props: StyledOilerStatisticProps) =>
            props.isExpired
                ? props.theme.palette.error[400]
                : props.isExercised
                ? props.theme.palette.success.main
                : props.theme.palette.primary.main};
    }

    .MuiTypography-colorPrimary.MuiTypography-caption {
        color: ${(props: StyledOilerStatisticProps) =>
            props.isExpired
                ? props.theme.palette.error[400]
                : props.isExercised
                ? props.theme.palette.success.main
                : props.theme.palette.primary.main};
    }
`;
// TODO transform OilerIcon to a SvgIcon functionality and create OilerImage component
const HandleLiquidity = React.forwardRef((props: any, ref: any) => {
    return (
        <span ref={ref}>
            <OilerIconButton {...props} color={'primary'} size={'small'}>
                <SvgIcon
                    color={props.disabled ? 'disabled' : 'action'}
                    component={FillingIcon}
                />
            </OilerIconButton>
        </span>
    );
});

const Statistics = () => {
    const dispatch = useDispatch();
    const { displayOption, spotPrices } = useSelector(selectOptions);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const justify = isSmallScreen ? 'flex-start' : 'center';
    const spacing = isSmallScreen ? 2 : 0;

    const { modal } = useSelector(selectUI);
    const setOpenActionModal = (payload: ActionModalProps) => {
        dispatch(openModal(payload));
    };
    const {
        expDate,
        expTime,
        type,
        code,
        strike,
        strikeMetric,
        collateralPoolReserves,
        optionsPoolReserves,
        address,
    } = displayOption || {};

    const placeholder = '---';

    const optionsSpotPrice =
        spotPrices[process.env.REACT_APP_COLLATERAL_ADDRESS!]?.[address]
            ?.priceWithoutFee;

    const poolLiquidity =
        Number(optionsPoolReserves) * Number(optionsSpotPrice) +
        Number(collateralPoolReserves);

    const optionPrice = optionsSpotPrice
        ? Number(optionsSpotPrice).toFixed(2)
        : placeholder;

    const siPoolLiquidity = Number.isNaN(poolLiquidity)
        ? placeholder
        : formatNbToSI(poolLiquidity);

    const isExpired = displayOption?.status.includes('Expired');
    const isExercised = displayOption?.status.includes('Exercised');
    return (
        <>
            <OilerBox p={3} mb={5} title="Option info">
                <OilerGrid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <OilerGrid spacing={spacing} container item sm={12} md={5}>
                        <OilerGrid item sm container justify={justify}>
                            <OilerTooltip title="Describe how option code is encoded (we have it in docs)">
                                <div>
                                    <OilerStatistic
                                        value={code}
                                        label={'CODE'}
                                        suffix={type}
                                        skeletonWidth={70}
                                        loading={code === placeholder}
                                    />
                                </div>
                            </OilerTooltip>
                        </OilerGrid>

                        <OilerHidden smDown>
                            <OilerDivider
                                orientation="vertical"
                                flexItem
                                small
                            />
                        </OilerHidden>

                        <OilerGrid item sm container justify={justify}>
                            <StyledOilerStatistic
                                isExpired={isExpired}
                                isExercised={isExercised}
                                value={expDate}
                                suffix={expDate === placeholder ? '' : expTime}
                                label={
                                    isExpired
                                        ? 'EXPIRED AT'
                                        : isExercised
                                        ? 'EXERCISED AT'
                                        : 'EXPIRATION'
                                }
                                loading={expDate === placeholder}
                                skeletonWidth={70}
                            />
                        </OilerGrid>
                    </OilerGrid>
                    <OilerGrid spacing={spacing} container item sm={12} md={7}>
                        <OilerHidden smDown>
                            <OilerDivider
                                orientation="vertical"
                                flexItem
                                small
                            />
                        </OilerHidden>

                        <OilerGrid item sm container justify={justify}>
                            <OilerStatistic
                                value={strike}
                                suffix={strikeMetric}
                                label={'STRIKE'}
                                loading={strike === placeholder}
                            />
                        </OilerGrid>

                        <OilerHidden smDown>
                            <OilerDivider
                                orientation="vertical"
                                flexItem
                                small
                            />
                        </OilerHidden>

                        <OilerGrid item sm={4} container justify={justify}>
                            <OilerStatistic
                                value={optionPrice}
                                suffix={process.env.REACT_APP_COLLATERAL_NAME}
                                label={'PRICE'}
                                loading={optionPrice === placeholder}
                            />
                        </OilerGrid>

                        <OilerHidden smDown>
                            <OilerDivider
                                orientation="vertical"
                                flexItem
                                small
                            />
                        </OilerHidden>

                        <OilerGrid item sm={5} container justify={justify}>
                            <OilerStatistic
                                value={siPoolLiquidity}
                                suffix={
                                    <>
                                        {process.env.REACT_APP_COLLATERAL_NAME}
                                        <OilerTooltip title="Add/remove liquidity">
                                            <HandleLiquidity
                                                disabled={
                                                    siPoolLiquidity ===
                                                    placeholder
                                                }
                                                onClick={() =>
                                                    setOpenActionModal({
                                                        state: true,
                                                        type: ModalType.LIQUIDITY,
                                                    })
                                                }
                                            />
                                        </OilerTooltip>
                                    </>
                                }
                                label={
                                    <OilerTooltip
                                        title={
                                            (Number.isNaN(poolLiquidity)
                                                ? 0
                                                : poolLiquidity) +
                                            ' ' +
                                            process.env
                                                .REACT_APP_COLLATERAL_NAME
                                        }
                                    >
                                        <span>POOL LIQUIDITY</span>
                                    </OilerTooltip>
                                }
                                loading={siPoolLiquidity === placeholder}
                                skeletonWidth={50}
                            />
                        </OilerGrid>
                    </OilerGrid>
                </OilerGrid>
                <TradingActions />
            </OilerBox>

            {modal.type === ModalType.LIQUIDITY && (
                <LiquidityModal
                    open={modal.state}
                    option={displayOption}
                    setOpenActionModal={setOpenActionModal}
                />
            )}
        </>
    );
};
export default Statistics;
