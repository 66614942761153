import {
    AsyncThunkPayloadCreator,
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { getNameFromNetId, Network } from '@utils/getNameFromNetId';
import { initialState, WalletState } from './types';
import { ContractType } from '@services/contracts-types';
import { getContractsProvider } from '@services/contracts-provider';

export const requestAccountsAsync = createAsyncThunk(
    'wallet/request-accounts',
    async () => {
        const accounts = await window?.ethereum?.request({
            method: 'eth_requestAccounts',
        });
        if (accounts.length && typeof accounts[0] === 'string') {
            return accounts[0];
        }
        return '';
    },
);

export const changeAccountsAsync = createAsyncThunk(
    'wallet/change-account',
    async () => {
        return await window?.ethereum?.request({
            method: 'wallet_requestPermissions',
            params: [
                {
                    eth_accounts: {},
                },
            ],
        });
    },
);

const initHandler: AsyncThunkPayloadCreator<Network, void, {}> = async () => {
    const provider = getContractsProvider()?.getProvider();
    if (!provider) return Network.Unknown;
    const { chainId } = await provider.getNetwork();
    return (
        Network[getNameFromNetId(chainId) as keyof typeof Network] ??
        Network.Unsupported
    );
};

export const initNetworkAsync = createAsyncThunk(
    'wallet/init-network',
    initHandler,
);

export const fetchBalancesAsync = createAsyncThunk(
    'wallet/fetch-balances',
    async (address: string, { getState }) => {
        const contractsProvider = getContractsProvider();

        const collateralContract = contractsProvider.findOrCreate(
            ContractType.Collateral,
        );

        const USDCBalance = await collateralContract.balanceOf(address);

        return {
            USDC: USDCBalance?.toString() || '0',
        };
    },
);

export const wallet = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        setIsWrongNetwork: (walletState: WalletState, action) => {
            walletState.isWrongNetwork = action.payload;
        },
        setBlockNumber: (walletState: WalletState, action) => {
            walletState.blockNumber = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(requestAccountsAsync.pending, (state) => {
                state.loading.account = true;
            })
            .addCase(requestAccountsAsync.fulfilled, (state, action) => {
                state.loading.account = false;
                state.isConnected = true;
                state.account = action.payload;
            })
            .addCase(initNetworkAsync.pending, (state) => {
                state.loading.network = true;
            })
            .addCase(initNetworkAsync.rejected, (state) => {
                state.loading.network = false;
                state.network = Network.Unknown;
            })
            .addCase(initNetworkAsync.fulfilled, (state, action) => {
                state.loading.network = false;
                state.network = action.payload;
            })
            .addCase(fetchBalancesAsync.rejected, (state) => {
                state.loading.balances = false;
                state.balances.USDC = '0';
            })
            .addCase(fetchBalancesAsync.fulfilled, (state, action) => {
                state.loading.balances = false;
                state.balances.USDC = action.payload.USDC;
            });
    },
});

export const { setIsWrongNetwork, setBlockNumber } = wallet.actions;

export const selectWallet = (state: RootState) => state.wallet;

export default wallet.reducer;
