import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { FirebaseState, initialState } from './types';

export const firebase = createSlice({
    name: 'firebase',
    initialState,
    reducers: {
        setPagination: (firebaseState: FirebaseState, action: any) => {
            firebaseState.pagination = action.payload;
        },
        setPositionsCount: (firebaseState: FirebaseState, action: any) => {
            firebaseState.positionsCount = action.payload;
        },
        setForwardsValues: (firebaseState: FirebaseState, action: any) => {
            firebaseState.forwardsValues.lambda = action.payload?.lambda;
            firebaseState.forwardsValues.strike = action.payload?.strike;
        },
        setNftCollectionTokens: (firebaseState: FirebaseState, action: any) => {
            firebaseState.nftCollectionTokens = action.payload;
        },
        setDisplayNftToken: (firebaseState: FirebaseState, action: any) => {
            firebaseState.displayNftToken = action.payload;
        },
    },
});

export const {
    setPagination,
    setPositionsCount,
    setDisplayNftToken,
    setForwardsValues,
    setNftCollectionTokens,
} = firebase.actions;

export const selectFirebase = (state: RootState) => state.firebase;

export default firebase.reducer;
