import { MenuItem, MenuItemProps } from '@material-ui/core';
import styled from 'styled-components';

const StyledMenuItem = styled(MenuItem)`
    &.MuiListItem-button:hover {
        background-color: ${(props) => props.theme.palette.primary[900]};
    }
    &.MuiListItem-root.Mui-selected {
        background-color: ${(props) => props.theme.palette.secondary.main};
        color: ${(props) => props.theme.palette.secondary[900]};
    }
`;

const OilerMenuItem = (props: MenuItemProps) => {
    return (
        // @ts-ignore
        <StyledMenuItem disableRipple {...props}>
            {props.children}
        </StyledMenuItem>
    );
};

export default OilerMenuItem;
