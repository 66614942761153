import React from 'react';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@material-ui/icons/CheckBoxSharp';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
    &.MuiCheckbox-root {
        color: unset;
    }
`;

const OilerCheckbox = (props: CheckboxProps) => {
    return (
        <StyledCheckbox
            {...props}
            disableRipple
            disableFocusRipple
            color={'primary'}
            icon={<CheckBoxOutlineBlankSharpIcon />}
            checkedIcon={<CheckBoxSharpIcon />}
        />
    );
};

export default OilerCheckbox;
