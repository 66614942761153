import {
    IChartingLibraryWidget,
    IExecutionLineAdapter,
    IOrderLineAdapter,
    ResolutionString,
    widget,
} from '@components/Chart/charting_library';
import React, { useEffect, useState } from 'react';
import OilerBox from '@oilerKit/OilerBox';
import {
    chartOptions,
    KeyT,
    SUPPORTED_RESOLUTIONS,
    widgetOptions,
} from '@components/Chart/chartConfig';
import { useSelector } from 'react-redux';
import { selectOptions } from '@features/options/optionsSlice';
// @ts-ignore
import { unformatSI } from 'format-si-prefix';
import { TradingOptionsFamily } from '@features/options/types';
import { getNameFromNetId } from '@utils/getNameFromNetId';
import { selectFirebase } from '@features/firebase/firebaseSlice';
import OilerSpinner from '@oilerKit/OilerSpinner';
import OilerBackdrop from '@oilerKit/OilerBackdrop';
import styled from 'styled-components';
import { formatNbToSI } from '@utils/formatNbToSI';

let tvWidget: IChartingLibraryWidget;
let strike: IOrderLineAdapter;
let expDate: IExecutionLineAdapter;

interface ChartProps {
    family: TradingOptionsFamily;
}

const net = getNameFromNetId(process.env.REACT_APP_CHAIN_ID!)?.toUpperCase();

const SYMBOLS = {
    F: {
        name: 'baseFeePerGas',
        defaultInterval: '15' as ResolutionString,
    },
    H: {
        name: 'DIFFICULTY',
        defaultInterval: '15' as ResolutionString,
    },
    FWD: {
        name: 'DIFFICULTY',
        defaultInterval: '15' as ResolutionString,
    },
};

const StyledBackdrop = styled(OilerBackdrop)`
    & {
        position: absolute;
        z-index: 1;
    }
`;

const Chart = ({ family }: ChartProps) => {
    const { displayOption } = useSelector(selectOptions);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const [curInterval, curSymbol] =
            // @ts-ignore
            tvWidget && tvWidget['_ready']
                ? [
                      tvWidget.symbolInterval().interval,
                      `${SYMBOLS[family].name}${net}`,
                  ]
                : [
                      SYMBOLS[family].defaultInterval,
                      `${SYMBOLS[family].name}${net}`,
                  ];
        // If the widget is not instantiated yet...
        if (!tvWidget) {
            // Then we create it...
            tvWidget = new widget({
                ...widgetOptions,
                symbol: curSymbol,
                interval: curInterval,
            });
        } else {
            tvWidget.setSymbol(curSymbol, curInterval, () => {
                setLoading(false);
            }); // Else we update it
        }
    }, [family]);

    useEffect(() => {
        tvWidget.onChartReady(() => {
            tvWidget.headerReady().then(() => {
                setLoading(false);
                let buttons: HTMLElement[] = [];
                const addButtonColor = (): void => {
                    for (let button of buttons) {
                        const interval = button.getAttribute('interval');
                        if (interval === tvWidget.activeChart().resolution()) {
                            button.style.color = '#f3790c';
                            button.style.fontWeight = '900';
                        } else {
                            button.style.color = '#f3e7a9';
                            button.style.fontWeight = '';
                        }
                    }
                };

                const setChartResolution = (key: string) => {
                    setLoading(true);
                    tvWidget!
                        .activeChart()
                        .setResolution(key as ResolutionString, () => {
                            addButtonColor();
                            setLoading(false);
                        });
                };

                const resolutionClickListener = (
                    key: string,
                    button: HTMLElement,
                ) => {
                    button.addEventListener('click', () => {
                        if (key === tvWidget.activeChart().resolution()) {
                            return;
                        }
                        setChartResolution(key);
                        tvWidget
                            .chart()
                            .onVisibleRangeChanged()
                            .subscribe(null, () => setLoading(false));
                    });
                };

                for (let key in SUPPORTED_RESOLUTIONS) {
                    const item = SUPPORTED_RESOLUTIONS[key as KeyT];
                    const button = tvWidget.createButton();
                    button.setAttribute('interval', key);
                    button.textContent = item;
                    button.style.cursor = 'pointer';
                    resolutionClickListener(key, button);
                    buttons.push(button);
                }
                addButtonColor();
            });
        });
        // eslint-disable-next-line
    }, []);

    const {
        forwardsValues: { strike: sPrice },
    } = useSelector(selectFirebase);

    useEffect(() => {
        tvWidget.onChartReady(() => {
            tvWidget.headerReady().then(async () => {
                setLoading(false);
                // tvWidget.activeChart().setTimezone('Europe/Paris');
                if (
                    (family === 'FWD' && sPrice) ||
                    (displayOption && displayOption.address !== '---')
                ) {
                    if (strike) strike.remove();

                    strike = tvWidget.activeChart().createOrderLine();
                    if (family === 'FWD') {
                        strike
                            .setText('FWD STRIKE')
                            .setBodyBackgroundColor('#f3790c')
                            .setBodyBorderColor('#fde4ce')
                            .setBodyTextColor('#fde4ce')
                            .setQuantityBackgroundColor('#924907')
                            .setQuantityBorderColor('#fde4ce')
                            .setQuantityTextColor('#fde4ce')
                            .setLineColor('#f3790c')
                            .setLineStyle(0)
                            .setLineLength(400)
                            .setLineWidth(1);
                        if (!isNaN(Number(sPrice))) {
                            strike.setPrice(Number(sPrice));
                            strike.setQuantity(formatNbToSI(Number(sPrice)));
                        }
                    } else {
                        strike
                            .setText('STRIKE')
                            .setBodyBackgroundColor('#f3790c')
                            .setBodyBorderColor('#fde4ce')
                            .setBodyTextColor('#fde4ce')
                            .setQuantityBackgroundColor('#924907')
                            .setQuantityBorderColor('#fde4ce')
                            .setQuantityTextColor('#fde4ce')
                            .setLineColor('#f3790c')
                            .setLineStyle(0)
                            .setLineLength(400)
                            .setLineWidth(1);

                        const s = parseFloat(
                            unformatSI(`${displayOption.strike}`),
                        );
                        if (!isNaN(s)) {
                            strike.setPrice(s);
                            strike.setQuantity(
                                `${displayOption.strike}${displayOption?.strikeMetric}`,
                            );
                        }
                    }
                    if (displayOption && displayOption.expDate) {
                        const [d, m, y] = displayOption.expDate.split('/');
                        const expirationDate = new Date(
                            `${m}/${d}/${y} ${displayOption.expTime}`,
                        );

                        if (
                            expirationDate instanceof Date &&
                            !isNaN(expirationDate.valueOf()) &&
                            expirationDate.getTime() <=
                                tvWidget.activeChart().getVisibleRange().to
                        ) {
                            if (expDate) expDate.remove();

                            expDate = tvWidget
                                .activeChart()
                                .createExecutionShape();
                            expDate
                                .setText('EXPIRATION')
                                .setTooltip('Expiration')
                                .setTextColor('#fde4ce')
                                .setArrowColor('#fde4ce')
                                .setDirection('buy')
                                .setArrowHeight(16)
                                .setTime(expirationDate.getTime() / 1000);
                        }
                    }
                }
            });
        });
        // eslint-disable-next-line
    }, [displayOption, sPrice]);

    return (
        <OilerBox height={500} mb={5} position={'relative'}>
            <StyledBackdrop open={loading}>
                <OilerSpinner />
            </StyledBackdrop>
            <div
                id={chartOptions.containerId}
                style={{ height: '100%', width: '100%' }}
            />
        </OilerBox>
    );
};

export default Chart;
