import { HeadCell } from '@oilerKit/OilerTable/OilerTableHead';
import { SortOrder } from '@constants/index';

export const tableColumns: HeadCell[] = [
    {
        id: 'code',
        label: 'Code',
    },
    {
        id: 'status',
        label: 'Status',
    },
    {
        id: 'type',
        label: 'Type',
    },
    {
        id: 'expDate',
        label: 'Expiration',
    },
    {
        id: 'strike',
        label: 'Strike',
    },
    {
        id: 'held',
        label: 'Held',
    },
    {
        id: 'written',
        label: 'Written',
    },
    {
        id: 'withdrawable',
        label: 'Withdrawable',
    },
    // {
    //     id: 'lpTokens',
    //     label: 'LP Tokens',
    // },
    // {
    //     id: 'unrealizedPnl',
    //     label: 'Unrealized PnL',
    // },
    // {
    //     id: 'realizedPnl',
    //     label: 'Realized PnL',
    // },
    // {
    //     id: 'totalPnl',
    //     label: 'Total PnL',
    // },
];

export const comparators = {
    code: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? a[orderBy].localeCompare(b[orderBy])
            : -a[orderBy].localeCompare(b[orderBy]),
    status: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? a[orderBy].join(', ').localeCompare(b[orderBy].join(', '))
            : -a[orderBy].join(', ').localeCompare(b[orderBy].join(', ')),
    type: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? a[orderBy].localeCompare(b[orderBy])
            : -a[orderBy].localeCompare(b[orderBy]),
    expDate: (order: SortOrder, orderBy: string) => (a: any, b: any) => {
        return order === SortOrder.DESC
            ? b.expiration - a.expiration
            : -(b.expiration - a.expiration);
    },
    strike: (order: SortOrder, orderBy: string) => (a: any, b: any) => {
        const numA = a[orderBy].substring(0, a[orderBy].length - 2);
        const numB = b[orderBy].substring(0, b[orderBy].length - 2);

        return order === SortOrder.DESC
            ? Number(numA) - Number(numB)
            : -(Number(numA) - Number(numB));
    },
    held: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? Number(a[orderBy]) - Number(b[orderBy])
            : -(Number(a[orderBy]) - Number(b[orderBy])),
    written: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? Number(a[orderBy]) - Number(b[orderBy])
            : -(Number(a[orderBy]) - Number(b[orderBy])),
    withdrawable: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? Number(a[orderBy]) - Number(b[orderBy])
            : -(Number(a[orderBy]) - Number(b[orderBy])),
    lpTokens: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? Number(a.lpTokensBalance) - Number(b.lpTokensBalance)
            : -(Number(a.lpTokensBalance) - Number(b.lpTokensBalance)),
    unrealizedPnl: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? Number(a[orderBy][0]) - Number(b[orderBy][0])
            : -(Number(a[orderBy][0]) - Number(b[orderBy][0])),
    realizedPnl: (order: SortOrder, orderBy: string) => (a: any, b: any) =>
        order === SortOrder.DESC
            ? Number(a[orderBy][0]) - Number(b[orderBy][0])
            : -(Number(a[orderBy][0]) - Number(b[orderBy][0])),
    totalPnl: (order: SortOrder, orderBy: string) => (a: any, b: any) => {
        return order === SortOrder.DESC
            ? Number(a.fullPnl[0]) - Number(b.fullPnl[0])
            : -(Number(a.fullPnl[0]) - Number(b.fullPnl[0]));
    },
};
