import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        console.error(error);
        return { hasError: true };
    }

    componentDidCatch(error: any) {
        console.error(error);
        this.setState({ hasError: true });
    }

    render() {
        // @ts-ignore
        if (this.state.hasError) {
            // TODO: display a beautiful error page.
            return <div>An error occurred. Please refresh the page.</div>;
        }

        // eslint-disable-next-line
        return this.props.children;
    }
}

export default ErrorBoundary;
