export const BLOCKED_COUNTRIES = {
    // CN: 'CN',
    // US: 'US',
    GB: 'GB',
    // CU: 'CU',
    // KP: 'KP',
    // IR: 'IR',
    // LY: 'LY',
    // SS: 'SS',
    // SD: 'SD',
    // SY: 'SY',
    // VG: 'VG',
    // KY: 'KY',
    // UA: 'UA-43',
    // IND: 'IND',
    // TR: 'TR',
    // ID: 'ID',
    // NP: 'NP',
    // DZ: 'DZ',
    // MA: 'MA',
};

// http://www.geonames.org/countries/
