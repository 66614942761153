import { BigNumberish } from 'ethers';
import { Network } from '@utils/getNameFromNetId';

export interface Loaders {
    account: boolean;
    network: boolean;
    balances: boolean;
}

export interface Balances {
    [asset: string]: BigNumberish;
}

export interface WalletState {
    loading: Loaders;
    isConnected: boolean;
    account: string; // Ethereum address
    network: Network;
    isWrongNetwork: boolean;
    blockNumber: number;
    balances: Balances;
}

export const initialState: WalletState = {
    loading: {
        account: false,
        network: false,
        balances: false,
    },
    isConnected: false,
    account: '',
    network: Network.Unknown,
    isWrongNetwork: false,
    blockNumber: -1,
    balances: {} as Balances,
};
