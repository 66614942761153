import { useEffect } from 'react';
import { firestore } from '@lib/firebase-client';
import {
    collection,
    onSnapshot,
    query,
    where,
    orderBy,
    getDocs,
} from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectFirebase,
    // setForwardsValues,
    setNftCollectionTokens,
    setPositionsCount,
} from '@features/firebase/firebaseSlice';
import { NftCollectionTokens, ROWS_PER_PAGE } from '@features/firebase/types';
import { selectWallet } from '@features/wallet/walletSlice';

export const FirebaseListener = () => {
    const dispatch = useDispatch();
    const { blockNumber, account } = useSelector(selectWallet);
    const { nftCollectionTokens, pagination } = useSelector(selectFirebase);

    useEffect(() => {
        async function retrieveFirebaseValues() {
            // onSnapshot(
            //     doc(
            //         firestore,
            //         getNameFromNetId(
            //             process.env.REACT_APP_CHAIN_ID!,
            //         ).toLowerCase(),
            //         `forwards/${process.env.REACT_APP_FORWARDS_UI_ADDRESS!}/${
            //             blockNumber - 1
            //         }`,
            //     ),
            //     (doc) => {
            //         if (doc.exists()) dispatch(setForwardsValues(doc.data()));
            //     },
            // );

            console.log(account);

            const end = ROWS_PER_PAGE * (pagination + 1) - 1;
            if (end > nftCollectionTokens.length) {
                onSnapshot(
                    query(
                        collection(
                            firestore,
                            'forwards_nfts_collection_tokens',
                        ),
                        where('owner', '==', account),
                        orderBy('tokenId'),
                        // endAt(end),
                        // limit(ROWS_PER_PAGE),
                    ),
                    async (snapshot) => {
                        console.log('snapshot', snapshot);

                        const _nftCollectionTokens =
                            [] as NftCollectionTokens[];
                        snapshot.forEach((doc) =>
                            _nftCollectionTokens.push(
                                doc.data() as NftCollectionTokens,
                            ),
                        );
                        if (
                            JSON.stringify(nftCollectionTokens) !==
                            JSON.stringify(_nftCollectionTokens)
                        ) {
                            // We need the total number of positions for pagination
                            const count = await getDocs(
                                query(
                                    collection(
                                        firestore,
                                        'forwards_nfts_collection_tokens',
                                    ),
                                    where('owner', '==', account),
                                ),
                            );
                            dispatch(setPositionsCount(count.size));
                            dispatch(
                                setNftCollectionTokens(_nftCollectionTokens),
                            );
                        }
                    },
                );
            }
        }

        if (blockNumber && blockNumber > 0) retrieveFirebaseValues();
    }, [dispatch, blockNumber, nftCollectionTokens, account, pagination]);

    return null;
};
