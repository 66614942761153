import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContractsProvider } from '@services/contracts-provider';
import { ContractType } from '@services/contracts-types';
import {
    ContractsInteractionsState,
    InteractionState,
    Step,
    StepState,
} from './types';
import invariant from 'tiny-invariant';
import { BigNumber, ethers, utils } from 'ethers';
import { addTransactionAsync } from '@features/transactions/transactionsSlice';
import { TxType } from '@features/transactions/types';

export const buildWithdrawSteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const { allowanceAmount } = payload;

    const contractsProvider = getContractsProvider();

    const collateralContract = getContractsProvider().findOrCreate(
        ContractType.Collateral,
    );
    invariant(collateralContract, 'No collateral contract set');

    const userAddr = await contractsProvider.getAccount();
    invariant(userAddr, 'No user address set');

    const givenAllowance = await collateralContract.allowance(
        userAddr,
        process.env.REACT_APP_BALANCER_ROUTER_ADDRESS,
    );

    const minRequired = utils.parseEther(allowanceAmount.toString());
    const needsApproval = BigNumber.from(givenAllowance.toString()).lt(
        minRequired,
    );

    const steps = [] as Step[];

    if (needsApproval) {
        steps.push({ name: 'Approve Collateral', state: StepState.Idle });
    }
    steps.push({ name: 'Withdraw', state: StepState.Idle });

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

interface WithdrawPayload {
    optionAddress: string;
    withdrawAmount: string;
}

export const withdrawTxAsync = createAsyncThunk(
    'withdraw/tx',
    async (payload: WithdrawPayload, { dispatch, getState }) => {
        const { withdrawAmount, optionAddress } = payload;

        const optionContract = getContractsProvider().findOrCreate(
            ContractType.Option,
            optionAddress,
        );
        invariant(optionContract, 'No option contract set');

        const withdrawTx = await optionContract.withdraw(
            ethers.utils.parseUnits(withdrawAmount, 6),
            {
                gasLimit: process.env.REACT_APP_GAS_LIMIT,
            },
        );

        dispatch(addTransactionAsync({ ...withdrawTx, type: TxType.Withdraw }));

        await withdrawTx.wait();
        return withdrawTx?.hash;
    },
);
