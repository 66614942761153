import React, { ReactElement } from 'react';
import styled from 'styled-components';
import OilerTypography from '@oilerKit/OilerTypography';
import { space, SpaceProps } from 'styled-system';
import OilerSkeleton from '@oilerKit/OilerSkeleton';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${space}
`;

const ValueWrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface OilerStatisticProps extends SpaceProps {
    label: string | ReactElement;
    value: string | number | undefined;
    suffix?: string | number | ReactElement;
    large?: boolean;
    loading?: boolean;
    skeletonWidth?: number;
    formatter?: () => string;
}

const OilerStatistic = (props: OilerStatisticProps) => {
    const { label, value, suffix, large, loading, skeletonWidth = 20 } = props;
    return (
        <Wrapper {...props}>
            <OilerTypography
                variant={large ? 'h6' : 'caption'}
                weight={'regular'}
                color={'textPrimary'}
            >
                {label}
            </OilerTypography>
            <ValueWrapper>
                <OilerTypography
                    variant={large ? 'h5' : 'h6'}
                    weight={'bold'}
                    color={'primary'}
                >
                    {loading ? <OilerSkeleton width={skeletonWidth} /> : value}
                </OilerTypography>
                {suffix ? (
                    <OilerTypography
                        variant={'caption'}
                        weight={'semiBold'}
                        ml={1}
                        color={'primary'}
                    >
                        {suffix}
                    </OilerTypography>
                ) : null}
            </ValueWrapper>
        </Wrapper>
    );
};

export default OilerStatistic;
