import React, { ReactNode } from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import {
    createGlobalStyle,
    ThemeProps,
    ThemeProvider as StyledThemeProvider,
} from 'styled-components';
import { DarkTheme } from './theme';
import backgroundBlack from '@oilerKit/themes/img/dark-bg.png';

const GlobalStyles = createGlobalStyle`
    body { 
      background-image: url(${backgroundBlack});
      overflow-x: hidden;
      font-family: 'Montserrat', sans-serif;
      font-weight: ${(props: ThemeProps<any>) =>
          props.theme.typography.fontWeightMedium};
    }
`;

interface OilerThemeProviderProps {
    children: ReactNode;
}

const OilerThemeProvider = (props: OilerThemeProviderProps) => {
    return (
        <StyledThemeProvider theme={DarkTheme}>
            <ThemeProvider theme={DarkTheme}>
                <GlobalStyles />
                <CssBaseline />
                {props.children}
            </ThemeProvider>
        </StyledThemeProvider>
    );
};

export default OilerThemeProvider;
