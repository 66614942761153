import React from 'react';
import styled from 'styled-components';
import { Popover, PopoverProps } from '@material-ui/core';

const StyledPopover = styled(Popover)`
    .MuiPopover-paper {
        padding: 8px 16px;
        border-radius: 0;
        background: ${(props) => props.theme.palette.primary['500']};
        border: 1px solid ${(props) => props.theme.palette.primary['900']};
    }
`;

const OilerPopover = (props: PopoverProps) => {
    const { children } = props;
    return <StyledPopover {...props}>{children}</StyledPopover>;
};

export default OilerPopover;
