import React, { InputHTMLAttributes, RefObject } from 'react';
import styled, { css } from 'styled-components';
import OilerTypography from '@oilerKit/OilerTypography';
import { space, SpaceProps } from 'styled-system';
import { alpha } from '@material-ui/core/styles';

const MaxLabel = styled.span`
    cursor: pointer;
    background-color: ${(props) => props.theme.palette.primary['500']};
    color: ${(props) => props.theme.palette.common.black};
    padding: 0px 10px;
    font-size: 12px;

    &:active {
        background-color: ${(props) => props.theme.palette.primary['600']};
    }
`;

const Label = styled.div`
    margin-bottom: 10px;
`;

const Labels = styled.div<{ balanceValue?: number }>`
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: ${(props) =>
        props.balanceValue !== undefined ? 'space-between' : 'flex-end'};
`;

const disabledProperties = css`
    border-color: ${(props) => props.theme.palette.primary['800']};
    background-color: ${(props) => props.theme.palette.primary['900']};
    color: ${(props) => props.theme.palette.primary['700']};
`;

const errorProperties = css`
    border-color: ${(props) => props.theme.palette.error.main};
`;

const Input = styled.input<OilerInputProps>`
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.palette.primary['500']};
    border-color: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary['500']};
    text-align: right;
    font-size: 24px;
    font-weight: 500;
    padding: 5px 15px 5px 5px;
    ${(props) => props.fullWidth && 'width: 100%'};
    min-height: 50px;
    padding-right: ${(props) => (props.prefix ? '25px !important' : null)};

    ${(props) => (props.disabled ? disabledProperties : '')};
    ${(props) => (props.error ? errorProperties : '')};

    &:focus {
        border-color: ${(props) => props.theme.palette.common.white};
        outline: thin;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield;
    }
    &::placeholder {
        color: ${(props) => props.theme.palette.primary[700]};
    }
`;

const Wrapper = styled.div<SpaceProps & { fullWidth?: boolean }>`
    ${(props) => props.fullWidth && 'width: 100%'};
    ${space}
`;

const Prefix = styled.span`
    position: relative;
    right: 20px;
    top: 8px;
`;

const HelperText = styled(OilerTypography)<any>`
    margin-left: 14px;
    display: flex;
    color: ${(props) =>
        props.error
            ? props.theme.palette.error.main
            : alpha(
                  props.theme.palette.primary.main,
                  props.theme.palette.action.disabledOpacity,
              )};
`;

//TODO implement lib TextField
/*const StyledInput = styled(TextField)<TextFieldProps>`
    &&& {
        .MuiOutlinedInput-notchedOutline {
            border-color: ${(props) => props.theme.palette.primary.main};
        }
        .MuiInputBase-input {
            text-align: right;
        }
        .MuiOutlinedInput-root {
            border-radius: 0;

            color: ${(props) => props.theme.palette.primary['500']};

            font-size: 24px;
            font-weight: 500;
            padding: 5px 15px 5px 5px;
            width: 100%;
            min-height: 50px;
            &.Mui-focused {
                border-color: ${(props) => props.theme.palette.common.white};
            }
            &:hover {
                border-color: ${(props) => props.theme.palette.common.white};
                outline: thin;
            }
        }
    }
`;*/

interface OilerInputProps
    extends InputHTMLAttributes<HTMLInputElement>,
        SpaceProps {
    balanceValue?: number;
    handleMaxClick?: () => void;
    handleInputChange: (value: string) => void;
    inputAmount?: string;
    ref?: RefObject<HTMLInputElement>;
    prefix?: string;
    label?: string;
    error?: boolean;
    helperText?: string;
    fullWidth?: boolean;
}

const OilerInput = (props: OilerInputProps) => {
    const {
        balanceValue,
        handleMaxClick,
        handleInputChange,
        inputAmount,
        prefix,
        label,
        helperText,
        mt,
        mb,
        fullWidth,
    } = props;

    return (
        <Wrapper mb={mb} mt={mt} fullWidth={fullWidth}>
            {label && (
                <Label {...props}>
                    <OilerTypography>{label}</OilerTypography>
                </Label>
            )}
            {balanceValue !== undefined || handleMaxClick ? (
                <Labels balanceValue={balanceValue}>
                    {balanceValue !== undefined ? (
                        <OilerTypography color={'primary'} variant={'caption'}>
                            Balance: {balanceValue?.toFixed(2)}
                        </OilerTypography>
                    ) : null}
                    {handleMaxClick ? (
                        <MaxLabel onClick={handleMaxClick}>
                            <OilerTypography>MAX</OilerTypography>
                        </MaxLabel>
                    ) : null}
                </Labels>
            ) : null}
            {/*<StyledInput variant={'outlined'} color={'primary'} />*/}
            {/*<TextField variant={'outlined'} color={'primary'} />*/}
            <Input
                {...props}
                value={inputAmount}
                onChange={({ target: { value } }) => handleInputChange(value)}
            />
            {prefix && <Prefix>%</Prefix>}
            {helperText && (
                <HelperText {...props} variant={'caption'}>
                    {helperText}
                </HelperText>
            )}
        </Wrapper>
    );
};

export default OilerInput;
