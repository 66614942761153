import styled from 'styled-components';
import { TableHead, TableHeadProps } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import OilerTableCell from '@oilerKit/OilerTable/OilerTableCell';
import OilerTableRow from '@oilerKit/OilerTable/OilerTableRow';
import { OilerTableSortLabel } from '@oilerKit/OilerTable/OilerTable';
import { SortOrder } from '@constants/index';

const StyledTableHead = styled(TableHead)`
    .MuiTableRow-root,
    .MuiTableCell-stickyHeader {
        background: ${(props) => props.theme.palette.primary.main};
    }
    .MuiTableCell-head {
        color: ${(props) => props.theme.palette.common.black};
    }
`;

interface OilerTableHeadProps extends TableHeadProps {
    columns: any;
    order?: SortOrder;
    orderBy?: string;
    setOrder?: Dispatch<SetStateAction<SortOrder>>;
    setOrderBy?: Dispatch<SetStateAction<string>>;
}

export interface HeadCell {
    id: string;
    label: string;
    orderComparator?: (order: SortOrder) => (a: any, b: any) => number;
}

const getComparator = <Key extends keyof any>(
    order: SortOrder,
    orderBy: Key,
): ((
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number) => {
    return order === SortOrder.DESC
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

/**
 * If page and rowsPerPage are provided, table data will be paginated
 */
export const oilerSortRows = <T,>(
    array: T[],
    order: SortOrder,
    orderBy: string,
    comparators: any,
    page?: number,
    rowsPerPage?: number,
) => {
    if (page !== undefined && rowsPerPage !== undefined) {
        array = array.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        );
    }
    const comparator =
        comparators[orderBy]?.(order, orderBy) || getComparator(order, orderBy);

    const stabilizedThis = array.map((el, index) => [el, index] as any);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);

        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
};

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

interface OilerSortCellProps {
    headCell: HeadCell;
    order: SortOrder;
    orderBy: string;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}

const OilerSortCell = (props: OilerSortCellProps) => {
    const { headCell, order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <OilerTableCell>
            <OilerTableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : SortOrder.ASC}
                onClick={createSortHandler(headCell.id)}
            >
                {headCell.label}
            </OilerTableSortLabel>
        </OilerTableCell>
    );
};

const OilerTableHead = (props: OilerTableHeadProps) => {
    const { columns, order, orderBy, setOrderBy, setOrder } = props;

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        const isAsc = orderBy === property && order === SortOrder.ASC;
        if (setOrder) {
            setOrder(isAsc ? SortOrder.DESC : SortOrder.ASC);
        }
        if (setOrderBy) {
            setOrderBy(property);
        }
    };

    return (
        <StyledTableHead {...props}>
            <OilerTableRow>
                {columns.map((headCell: HeadCell) => {
                    return order && orderBy ? (
                        <OilerSortCell
                            key={headCell.id}
                            headCell={headCell}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                    ) : (
                        <OilerTableCell>{headCell.label}</OilerTableCell>
                    );
                })}
            </OilerTableRow>
        </StyledTableHead>
    );
};

export default OilerTableHead;
