import React from 'react';
import styled from 'styled-components';

import twitter from '@oilerKit/assets/icons/social/twitter.svg';
import telegram from '@oilerKit/assets/icons/social/telegram.svg';
import discord from '@oilerKit/assets/icons/social/discord.svg';
import github from '@oilerKit/assets/icons/social/github.svg';
import medium from '@oilerKit/assets/icons/social/medium.svg';
import gitbook from '@oilerKit/assets/icons/social/gitbook.svg';
import OilerIcon from '../OilerIcon';
import {
    DISCORD_URL,
    GITHUB_URL,
    OILER_GITBOOK_URL,
    MEDIUM_URL,
    TELEGRAM_URL,
    TWITTER_URL,
} from '@constants/index';
import OilerLink from '@oilerKit/OilerLink';

const Wrapper = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin: 40px 0;
    width: 100%;
    align-items: center;

    img {
        height: 15px;
    }

    a {
        &:last-of-type {
            margin-right: 0;
        }
    }
`;

const MediaList = (props: any) => {
    return (
        <Wrapper {...props}>
            <OilerLink href={TWITTER_URL} target="_blank">
                <OilerIcon icon={twitter} height={15} alt="Twitter logo" />
            </OilerLink>
            <OilerLink href={TELEGRAM_URL} target="_blank">
                <OilerIcon icon={telegram} height={15} alt="Telegram logo" />
            </OilerLink>
            <OilerLink href={DISCORD_URL} target="_blank">
                <OilerIcon icon={discord} height={15} alt="Discord logo" />
            </OilerLink>
            <OilerLink href={GITHUB_URL} target="_blank">
                <OilerIcon icon={github} height={15} alt="GitHub logo" />
            </OilerLink>
            <OilerLink href={MEDIUM_URL} target="_blank">
                <OilerIcon icon={medium} height={15} alt="Medium logo" />
            </OilerLink>
            <OilerLink href={OILER_GITBOOK_URL} target="_blank">
                <OilerIcon icon={gitbook} height={15} alt="Gitbook logo" />
            </OilerLink>
            {/*<a href={DEFIPULSE_URL} target="_blank" rel="noopener noreferrer">*/}
            {/*    <OilerIcon icon={defipulse} alt="DeFi Pulse logo" />*/}
            {/*</a>*/}
        </Wrapper>
    );
};

export default MediaList;
