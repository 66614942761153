import OilerBox from '@oilerKit/OilerBox';
import OilerGrid from '@oilerKit/OilerGrid';
import OilerStatistic from '@oilerKit/OilerStatistic';
import OilerTooltip from '@oilerKit/OilerTooltip';
import OilerDivider from '@oilerKit/OilerDivider';
import OilerHidden from '@oilerKit/OilerHidden';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { formatNbToSI } from '@utils/formatNbToSI';
import PoolActions from '@components/TradingActions/Forwards/PoolActions';
import ForwardsPoolMiniCharts from './ForwardsMiniCharts/ForwardsPoolMiniCharts';
import { useEffect, useState } from 'react';
import { getForwardsData } from '@features/contracts-interactions/forwards/actions';
import { BigNumber } from 'ethers/lib/ethers';

// interface StyledOilerStatisticProps {
//     marketOpen: boolean;
//     theme: any;
// }

// const StyledOilerStatistic = styled(OilerStatistic)`
//     .MuiTypography-h6,
//     .MuiTypography-colorPrimary.MuiTypography-caption {
//         color: ${(props: StyledOilerStatisticProps) =>
//             props.marketOpen
//                 ? props.theme.palette.success.main
//                 : props.theme.palette.error[400]};
//     }
// `;

const PoolStatistics = () => {
    const theme = useTheme();
    const isXsmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const justify = isXsmallScreen ? 'flex-start' : 'center';
    const spacing = isXsmallScreen ? 2 : 0;
    const marginBottom = isSmallScreen ? 4 : 0;
    // const {
    //     forwardsValues: { lambda, strike },
    // } = useSelector(selectFirebase);

    const [forwardsData, setForwardsData] = useState<{
        bidLambda: BigNumber;
        askLambda: BigNumber;
        bidStrike: BigNumber;
        askStrike: BigNumber;
        liquidity: string;
        lambda: string;
        shortContracts: string;
        longContracts: string;
        poolHealth: string;
        tvl: string;
    } | null>(null);

    useEffect(() => {
        getForwardsData().then(setForwardsData);
    }, []);

    const placeholder = '---';

    return (
        <>
            <OilerBox p={3} mb={5} title="Pool info">
                <OilerGrid container direction="row" justify={'space-around'}>
                    <OilerGrid
                        container
                        direction="column"
                        justifyContent={'space-between'}
                        item
                        mb={4}
                    >
                        <OilerGrid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <OilerGrid
                                spacing={spacing}
                                container
                                justifyContent={'center'}
                                item
                                sm={12}
                            >
                                <OilerGrid item md container justify={justify}>
                                    <OilerTooltip title="Lambda">
                                        <div>
                                            <OilerStatistic
                                                value={
                                                    forwardsData?.lambda
                                                        ? formatNbToSI(
                                                              forwardsData?.lambda,
                                                          )
                                                        : placeholder
                                                }
                                                label="LAMBDA"
                                            />
                                        </div>
                                    </OilerTooltip>
                                </OilerGrid>

                                <OilerHidden smDown>
                                    <OilerDivider
                                        orientation="vertical"
                                        flexItem
                                        small
                                    />
                                </OilerHidden>

                                <OilerHidden smDown>
                                    <OilerDivider
                                        orientation="vertical"
                                        flexItem
                                        small
                                    />
                                </OilerHidden>

                                <OilerGrid item md container justify={justify}>
                                    <OilerStatistic
                                        value={
                                            forwardsData?.liquidity
                                                ? formatNbToSI(
                                                      forwardsData?.liquidity,
                                                  )
                                                : placeholder
                                        }
                                        label="LIQUIDITY"
                                        skeletonWidth={70}
                                    />
                                </OilerGrid>

                                <OilerHidden smDown>
                                    <OilerDivider
                                        orientation="vertical"
                                        flexItem
                                        small
                                    />
                                </OilerHidden>

                                <OilerGrid item md container justify={justify}>
                                    <OilerStatistic
                                        value={
                                            forwardsData?.longContracts
                                                ? formatNbToSI(
                                                      forwardsData?.longContracts,
                                                  )
                                                : placeholder
                                        }
                                        label="LONG POSITIONS"
                                        skeletonWidth={70}
                                    />
                                </OilerGrid>

                                <OilerHidden smDown>
                                    <OilerDivider
                                        orientation="vertical"
                                        flexItem
                                        small
                                    />
                                </OilerHidden>

                                <OilerGrid item md container justify={justify}>
                                    <OilerStatistic
                                        value={
                                            forwardsData?.shortContracts
                                                ? formatNbToSI(
                                                      forwardsData?.shortContracts,
                                                  )
                                                : placeholder
                                        }
                                        label="SHORT POSITIONS"
                                        skeletonWidth={70}
                                    />
                                </OilerGrid>
                            </OilerGrid>
                        </OilerGrid>
                    </OilerGrid>
                </OilerGrid>

                <OilerGrid
                    container
                    direction={'row'}
                    item
                    width="100%"
                    justifyContent={'space-between'}
                >
                    <OilerGrid
                        alignSelf={'center'}
                        mb={marginBottom}
                        sm={12}
                        xs={12}
                        md={7}
                        item
                    >
                        <PoolActions />
                    </OilerGrid>
                    <OilerGrid
                        item
                        md={5}
                        sm={12}
                        width="100%"
                        justifySelf={'flex-end'}
                    >
                        <ForwardsPoolMiniCharts />
                    </OilerGrid>
                </OilerGrid>
            </OilerBox>
        </>
    );
};
export default PoolStatistics;
