import { ethers } from 'ethers';
import { Provider } from 'ethers-multicall';

export const provider = window?.ethereum
    ? new ethers.providers.Web3Provider(
          // @ts-ignore
          window?.ethereum,
      )
    : ethers.providers.getDefaultProvider();

export const multiCallProvider = new Provider(provider);
