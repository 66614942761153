import styled from 'styled-components';
import { TableRow, TableRowProps } from '@material-ui/core';
import React from 'react';

const StyledTableRow = styled(TableRow)`
    &.MuiTableRow-root {
        cursor: ${(props) => (props.onClick ? 'pointer' : null)};
    }
    &&&:hover {
        background-color: ${(props) => props.theme.palette.primary[900]};
    }

    &&&.Mui-selected {
        background-color: ${(props) => props.theme.palette.secondary.main};
        color: ${(props) => props.theme.palette.secondary[900]};
        font-weight: ${(props) => props.theme.typography.fontWeightBold};
        &:hover {
            background-color: ${(props) => props.theme.palette.secondary[600]};
        }
    }
`;

const OilerTableRow = (props: TableRowProps) => {
    return <StyledTableRow {...props}>{props.children}</StyledTableRow>;
};

export default OilerTableRow;
