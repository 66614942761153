import OilerInput from '@oilerKit/OilerInput';
import OilerGrid from '@oilerKit/OilerGrid';
import React, { useState } from 'react';
import OilerModal from '@oilerKit/OilerModal';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import styled from 'styled-components';
import OilerCheckbox from '@oilerKit/OilerCheckbox';
import OilerFormControlLabel from '@oilerKit/OilerFormControlLabel';
import { Fade } from '@material-ui/core';
import {
    buildStepsAsync,
    InteractionName,
} from '@features/contracts-interactions/steps-builder';
import { useDispatch, useSelector } from 'react-redux';
import { selectWallet } from '@features/wallet/walletSlice';
import { ethers } from 'ethers';
import { OilerOption } from '@features/options/types';
import OilerTooltip from '@oilerKit/OilerTooltip';
import { isValidNumber } from '@utils/isValidNumber';
import OilerTypography from '@oilerKit/OilerTypography';
import { selectOptions } from '@features/options/optionsSlice';
import { ActionModalProps } from '@features/ui/types';

const StyledOilerModal = styled(OilerGrid)`
    ${(props) => props.theme.breakpoints.up('sm')} {
        width: 272px;
    }
    margin: auto;
`;

interface WriteModalProps {
    open: boolean;
    option: OilerOption;
    // eslint-disable-next-line
    setOpenActionModal: ({}: ActionModalProps) => void;
}

const WriteModal = (props: WriteModalProps) => {
    const { open, setOpenActionModal, option } = props;

    const dispatch = useDispatch();

    const { balances } = useSelector(selectWallet);
    const { spotPrices } = useSelector(selectOptions);

    const [writeAmount, setWriteAmount] = useState<string>('0');
    const [liquidityAmount, setLiquidityAmount] = useState<string>('0');

    const optionsSpotPrice =
        spotPrices[process.env.REACT_APP_COLLATERAL_ADDRESS!]?.[option.address]
            ?.priceWithoutFee;

    const collateralForLiquidity = (
        Number(liquidityAmount) * Number(optionsSpotPrice)
    ).toFixed(2);

    const [checked, setChecked] = useState(false);

    const handleChange = (e: any) => {
        setChecked(e.target.checked);
    };

    const handleWriteInputChange = (value: string) => {
        setWriteAmount(value);
    };

    const handleWriteMaxClick = () => {
        setWriteAmount(ethers.utils.formatUnits(balances['USDC'], 6));
    };

    const handleLiquidityInputChange = (value: string) => {
        setLiquidityAmount(value);
    };

    const handleLiquidityMaxClick = () => {
        setLiquidityAmount(writeAmount);
    };

    const handleWriteClick = () => {
        if (writeAmount !== '0') {
            dispatch(
                buildStepsAsync({
                    interactionName: InteractionName.WRITE,
                    writeAmount,
                    collateralAmount: collateralForLiquidity,
                    addLiquidity: checked,
                    optionAddress: option.address,
                    allowanceAmount: (
                        Number(writeAmount) + Number(collateralForLiquidity)
                    ).toString(),
                    allowanceAddress:
                        process.env.REACT_APP_OILER_OPTIONS_ROUTER_ADDRESS,
                }),
            );
        }
    };

    return (
        <OilerModal
            open={open}
            onClose={() => setOpenActionModal({ state: false, type: null })}
            title={'WRITE'}
        >
            <StyledOilerModal container direction="column" alignItems="stretch">
                <OilerInput
                    fullWidth
                    balanceValue={Number(
                        ethers.utils.formatUnits(balances['USDC'], 6),
                    )}
                    label={`Write ${option.code}`}
                    handleInputChange={handleWriteInputChange}
                    inputAmount={writeAmount}
                    handleMaxClick={handleWriteMaxClick}
                    helperText={`${
                        process.env.REACT_APP_COLLATERAL_NAME
                    } Needed as Collateral: 
                        ${
                            writeAmount
                                ? Number(writeAmount).toFixed(2)
                                : (0).toFixed(2)
                        }`}
                />
                <OilerGrid item margin={'auto'}>
                    <OilerTooltip title="You can add the written options to the liquidity pool automatically">
                        <div>
                            <OilerFormControlLabel
                                label={'Add to Liquidity Pool'}
                                labelPlacement="start"
                                control={<OilerCheckbox />}
                                onChange={handleChange}
                            />
                        </div>
                    </OilerTooltip>
                </OilerGrid>

                {checked && (
                    <Fade in={checked}>
                        <>
                            <OilerGrid item mt={4}>
                                <OilerInput
                                    fullWidth
                                    balanceValue={Number(writeAmount)}
                                    label={`Options to be Added to Liquidity`}
                                    handleInputChange={
                                        handleLiquidityInputChange
                                    }
                                    inputAmount={liquidityAmount}
                                    handleMaxClick={handleLiquidityMaxClick}
                                    helperText={`${
                                        process.env.REACT_APP_COLLATERAL_NAME
                                    } Needed for Liquidity:
                                    ${
                                        Number.isNaN(+collateralForLiquidity)
                                            ? 0
                                            : collateralForLiquidity
                                    }`}
                                />
                            </OilerGrid>

                            <OilerGrid
                                item
                                minWidth={'292px'}
                                justifyContent={'space-between'}
                                display={'flex'}
                                mt={4}
                            >
                                <OilerTypography weight={'bold'}>
                                    Total{' '}
                                    {process.env.REACT_APP_COLLATERAL_NAME}{' '}
                                    needed:
                                </OilerTypography>
                                <OilerTypography weight={'bold'}>
                                    {(
                                        Number(writeAmount) +
                                        Number(collateralForLiquidity)
                                    ).toFixed(2)}
                                </OilerTypography>
                            </OilerGrid>
                        </>
                    </Fade>
                )}

                <OilerButton
                    size={'large'}
                    disabled={
                        checked
                            ? !isValidNumber(writeAmount) ||
                              !isValidNumber(liquidityAmount)
                            : !isValidNumber(writeAmount)
                    }
                    mt={4}
                    onClick={handleWriteClick}
                    color={'secondary'}
                >
                    WRITE
                </OilerButton>
            </StyledOilerModal>
        </OilerModal>
    );
};

export default WriteModal;
