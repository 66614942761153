import React, { ReactElement } from 'react';
import OilerLink from '@oilerKit/OilerLink';

interface ConditionalLinkProps {
    condition: boolean;
    children: ReactElement;
    href?: string;
    target?: '_blank';
}

const ConditionalLink = (props: ConditionalLinkProps) => {
    return props.condition ? (
        <OilerLink href={props.href} target={props.target}>
            {props.children}
        </OilerLink>
    ) : (
        props.children
    );
};

export default ConditionalLink;
