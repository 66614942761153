import OilerInput from '@oilerKit/OilerInput';
import OilerGrid from '@oilerKit/OilerGrid';
import React, { useState } from 'react';
import OilerModal from '@oilerKit/OilerModal';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
    buildStepsAsync,
    InteractionName,
} from '@features/contracts-interactions/steps-builder';
import { OilerOption } from '@features/options/types';
import { ActionModalProps } from '@features/ui/types';

const StyledOilerModal = styled(OilerGrid)`
    ${(props) => props.theme.breakpoints.up('sm')} {
        width: 272px;
    }
    margin: auto;
`;

interface WithdrawModalProps {
    open: boolean;
    option: OilerOption;
    // eslint-disable-next-line
    setOpenActionModal: ({}: ActionModalProps) => void;
}

const WithdrawModal = (props: WithdrawModalProps) => {
    const { open, setOpenActionModal, option } = props;

    const dispatch = useDispatch();

    const [inputAmount, setInputAmount] = useState<string>('0');

    const handleInputChange = (value: string) => {
        setInputAmount(value);
    };

    const handleMaxClick = () => {
        setInputAmount(option.withdrawable);
    };

    const handleWithdrawClick = () => {
        if (inputAmount !== '0') {
            dispatch(
                buildStepsAsync({
                    interactionName: InteractionName.WITHDRAW,
                    withdrawAmount: inputAmount,
                    optionAddress: option.address,
                    allowanceAmount: inputAmount,
                    allowanceAddress:
                        process.env.REACT_APP_BALANCER_ROUTER_ADDRESS,
                }),
            );
        }
    };

    return (
        <OilerModal
            open={open}
            onClose={() => setOpenActionModal({ state: false, type: null })}
            title={'WITHDRAW'}
        >
            <StyledOilerModal>
                <OilerInput
                    fullWidth
                    balanceValue={Number(option.withdrawable)}
                    label={`Withdraw Collateral from ${option.code}`}
                    handleInputChange={handleInputChange}
                    inputAmount={inputAmount}
                    handleMaxClick={handleMaxClick}
                />

                <OilerButton
                    fullWidth
                    size={'large'}
                    disabled={inputAmount === '0'}
                    mt={4}
                    onClick={handleWithdrawClick}
                    color={'secondary'}
                >
                    WITHDRAW
                </OilerButton>
            </StyledOilerModal>
        </OilerModal>
    );
};

export default WithdrawModal;
