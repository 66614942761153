import React, { MouseEvent, useEffect, useState } from 'react';
import OilerBox from '@oilerKit/OilerBox';
import OilerTableHead, {
    oilerSortRows,
} from '@oilerKit/OilerTable/OilerTableHead';
import OilerTableRow from '@oilerKit/OilerTable/OilerTableRow';
import OilerTableCell from '@oilerKit/OilerTable/OilerTableCell';
import OilerTableBody from '@oilerKit/OilerTable/OilerTableBody';
import OilerToggleButtonGroup, {
    OilerToggleButton,
} from '@oilerKit/OilerToggleButtonGroup';
import {
    OilerTable,
    OilerTableContainer,
} from '@oilerKit/OilerTable/OilerTable';
import { SortOrder } from '@constants/index';
import { useDispatch, useSelector } from 'react-redux';
import '@features/options/optionsSlice';
import { OilerAlert } from '@oilerKit/OilerAlert';
import styled from 'styled-components';
import OilerChip from '@oilerKit/OilerChip';
import { OilerForward } from '@features/forwards/types';
import {
    comparators,
    tableColumns,
} from '@components/NFTsTable/nftsTableConfig';
import {
    selectFirebase,
    setDisplayNftToken,
    setPagination,
} from '@features/firebase/firebaseSlice';
import { NFTEntry, populateNftEntry } from './NFTEntry';
import { NftCollectionTokens, ROWS_PER_PAGE } from '@features/firebase/types';
import { getContractsProvider } from '@services/contracts-provider';
import invariant from 'tiny-invariant';
import { selectWallet } from '@features/wallet/walletSlice';
import OilerTableFooter from '@oilerKit/OilerTable/OilerTableFooter';
import OilerTablePagination from '@oilerKit/OilerTable/OilerTablePagination';
import { formatAddr } from '@utils/formatAddr';
import OilerIconButton from '@oilerKit/OilerButton/OilerIconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import OilerTooltip from '@oilerKit/OilerTooltip';
import { multiCallProvider } from '@services/web3-provider';
import { ContractType } from '@services/contracts-types';

const StyledOilerToggleButtonGroup = styled(OilerToggleButtonGroup)`
    position: relative;
    top: 2px;
    overflow-x: auto;
    width: 100%;
`;

const StyledOwnerOilerTableCell = styled(OilerTableCell)`
    display: flex;
    align-items: center;
`;

const OpenInEtherscan = ({ ownerAddr }: { ownerAddr: string }) => {
    return (
        <OilerTooltip title="Open in Etherscan">
            <div>
                <OilerIconButton
                    href={`${process.env.REACT_APP_ETHERSCAN_URL}/address/${ownerAddr}`}
                    target={'_blank'}
                    size={'small'}
                >
                    <LaunchIcon color={'disabled'} fontSize={'small'} />
                </OilerIconButton>
            </div>
        </OilerTooltip>
    );
};

enum ActiveFilters {
    ALL = 'All',
    SETTLED = 'Settled',
    LIQUIDATED = 'Liquidated',
}

const NFTsTable = () => {
    const { nftCollectionTokens, displayNftToken, positionsCount } =
        useSelector(selectFirebase);
    const [NFTEntries, setNFTEntries] = useState<NFTEntry[]>([]);
    const { blockNumber } = useSelector(selectWallet);
    const [page, setPage] = useState<number>(0);

    const [activeFilter, setActiveFilter] = useState<string>(ActiveFilters.ALL);
    const [orderBy, setOrderBy] = useState('Status');
    const [order, setOrder] = useState<SortOrder>(SortOrder.DESC);

    const dispatch = useDispatch();

    const placeholder = '---';

    useEffect(() => {
        async function populateNFTTableData() {
            const forwardsSdk = await getContractsProvider().findOrCreate(
                ContractType.ForwardsUI,
            );
            invariant(forwardsSdk, 'No forwards pool contract set');

            await multiCallProvider.init();

            const calls = nftCollectionTokens.map(
                (nft: NftCollectionTokens) => {
                    // forwardsSdk.getForward(nft.tokenId),

                    return {
                        value: {
                            collateral: {
                                rawValue: 42,
                            },
                            mintTimestamp: {
                                rawValue: 42,
                            },
                            notional: {
                                rawValue: 42,
                            },
                            settlementTimestamp: {
                                rawValue: 42,
                            },
                            strike: {
                                rawValue: 42,
                            },
                        },
                    };
                },
            );

            const metadata = (await Promise.all(calls)).map((x) => ({
                collateral: x.value.collateral.rawValue,
                mintTimestamp: x.value.mintTimestamp.rawValue,
                notional: x.value.notional.rawValue,
                settlementTimestamp: x.value.settlementTimestamp.rawValue,
                strike: x.value.strike.rawValue,
            }));
            const entries = nftCollectionTokens.map(
                (nft: NftCollectionTokens, idx: number) =>
                    populateNftEntry(nft, metadata[idx]),
            );

            setNFTEntries(entries);
            dispatch(setDisplayNftToken(displayNftToken || entries[0]));
        }

        if (nftCollectionTokens.length) {
            populateNFTTableData();
        }
        // eslint-disable-next-line
    }, [nftCollectionTokens, blockNumber]);

    const handleChangePage = (
        event: MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        dispatch(setPagination(newPage));
        setPage(newPage);
    };

    const byStatusFilter = (row: OilerForward) => {
        return activeFilter === 'All'
            ? row
            : row.status?.find(
                  (_status: string) => _status === activeFilter.toUpperCase(),
              );
    };

    return (
        <>
            <StyledOilerToggleButtonGroup color={'primary'} exclusive>
                <OilerToggleButton
                    value={ActiveFilters.ALL}
                    selected={activeFilter === ActiveFilters.ALL}
                    onClick={() => {
                        setActiveFilter(ActiveFilters.ALL);
                    }}
                >
                    ALL
                </OilerToggleButton>
                <OilerToggleButton
                    value={ActiveFilters.SETTLED}
                    selected={activeFilter === ActiveFilters.SETTLED}
                    onClick={() => {
                        setActiveFilter(ActiveFilters.SETTLED);
                    }}
                >
                    SETTLED
                </OilerToggleButton>
                <OilerToggleButton
                    value={ActiveFilters.LIQUIDATED}
                    selected={activeFilter === ActiveFilters.LIQUIDATED}
                    onClick={() => {
                        setActiveFilter(ActiveFilters.LIQUIDATED);
                    }}
                >
                    LIQUIDATED
                </OilerToggleButton>
            </StyledOilerToggleButtonGroup>
            <OilerBox>
                <OilerTableContainer>
                    <OilerTable stickyHeader size={'small'}>
                        <OilerTableHead
                            columns={tableColumns}
                            order={order}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            setOrder={setOrder}
                        />
                        {
                            <OilerTableBody>
                                {oilerSortRows(
                                    NFTEntries,
                                    order,
                                    orderBy,
                                    comparators,
                                    page,
                                    ROWS_PER_PAGE,
                                )
                                    .filter(byStatusFilter)
                                    .map((row: OilerForward, idx: number) => (
                                        <OilerTableRow
                                            hover
                                            key={`${row.code}-${idx}`}
                                            onClick={() => {
                                                dispatch(
                                                    setDisplayNftToken(
                                                        NFTEntries[
                                                            row.code as number
                                                        ],
                                                    ),
                                                );
                                            }}
                                            selected={
                                                displayNftToken?.code ===
                                                row.code
                                            }
                                        >
                                            <StyledOwnerOilerTableCell
                                                loading={
                                                    row.owner === placeholder
                                                }
                                            >
                                                {formatAddr(row.owner)}
                                                <OpenInEtherscan
                                                    ownerAddr={row.owner}
                                                />
                                            </StyledOwnerOilerTableCell>
                                            <OilerTableCell
                                                loading={
                                                    row.code === placeholder
                                                }
                                            >
                                                {row.code}
                                            </OilerTableCell>
                                            <OilerTableCell
                                                loading={
                                                    row.status[0] ===
                                                    placeholder
                                                }
                                            >
                                                <OilerChip
                                                    size={'small'}
                                                    label={row.status.join(
                                                        ' | ',
                                                    )}
                                                />
                                            </OilerTableCell>
                                            <OilerTableCell
                                                loading={
                                                    row.settlementDate ===
                                                    placeholder
                                                }
                                            >
                                                {row.settlementDate}
                                            </OilerTableCell>
                                            <OilerTableCell
                                                loading={
                                                    row.notional === placeholder
                                                }
                                            >
                                                {row.notional}
                                            </OilerTableCell>
                                            <OilerTableCell
                                                loading={
                                                    row.strike === placeholder
                                                }
                                            >
                                                {row.strike}
                                            </OilerTableCell>
                                            <OilerTableCell
                                                loading={
                                                    row.expectedPayout ===
                                                    placeholder
                                                }
                                            >
                                                {row.expectedPayout}
                                            </OilerTableCell>
                                            <OilerTableCell
                                                loading={
                                                    row.healthIndex ===
                                                    placeholder
                                                }
                                            >
                                                {row.healthIndex}
                                            </OilerTableCell>
                                            <OilerTableCell
                                                loading={
                                                    row.lambda === placeholder
                                                }
                                            >
                                                {row.lambda}
                                            </OilerTableCell>
                                        </OilerTableRow>
                                    ))}
                            </OilerTableBody>
                        }
                    </OilerTable>
                    {NFTEntries?.filter(byStatusFilter).length === 0 && (
                        <OilerAlert severity="info">
                            No {activeFilter} positions found
                        </OilerAlert>
                    )}
                    <OilerTableFooter>
                        <OilerTablePagination
                            count={positionsCount}
                            onPageChange={handleChangePage}
                            page={page}
                            rowsPerPage={ROWS_PER_PAGE}
                            rowsPerPageOptions={[]}
                        />
                    </OilerTableFooter>
                </OilerTableContainer>
            </OilerBox>
        </>
    );
};

export default NFTsTable;
