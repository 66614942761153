import OilerBox from '@oilerKit/OilerBox';
import UserStatistics from '@components/TradingActions/UserStatistics';
import UserActions from '@components/TradingActions/UserActions';
import { useSelector } from 'react-redux';
import { selectWallet } from '@features/wallet/walletSlice';
import { selectOptions } from '@features/options/optionsSlice';
import OilerDivider from '@oilerKit/OilerDivider';
import OilerHidden from '@oilerKit/OilerHidden';
import React from 'react';

const TradingActions = () => {
    const { balances } = useSelector(selectWallet);
    const { spotPrices, displayOption } = useSelector(selectOptions);

    return (
        <OilerBox mt={3} noBorder>
            <OilerBox noBorder mb={5}>
                <UserActions option={displayOption} />
            </OilerBox>
            <OilerHidden smDown>
                <OilerDivider />
            </OilerHidden>
            <UserStatistics
                balances={balances}
                spotPrices={spotPrices}
                option={displayOption}
            />
        </OilerBox>
    );
};
export default TradingActions;
