import OilerSelect from '@oilerKit/OilerSelect';
import OilerMenuItem from '@oilerKit/OilerMenu/OilerMenuItem';
import { ChangeEvent, useState } from 'react';
import OilerTypography from '@oilerKit/OilerTypography';
import OilerBox from '@oilerKit/OilerBox';

export interface NTFSelectItem {
    title: string;
    value: any;
    size: any;
}

interface NFTsSelectProps {
    items: NTFSelectItem[];
}

const Item = (props: { title: any; size: any }) => {
    return (
        <OilerBox noBorder noBackground display={'flex'}>
            <OilerTypography mr={2}>{props.title}</OilerTypography>
            <OilerTypography weight={'light'}>{props.size}</OilerTypography>
        </OilerBox>
    );
};

const NFTsSelect = (props: NFTsSelectProps) => {
    const [NFT, setNFT] = useState<any>('c');

    const handleChange = (
        event: ChangeEvent<{ name?: string; value: unknown }>,
    ) => {
        setNFT(event.target.value);
    };

    return (
        <OilerSelect
            label="Your Liquidity NFTs"
            fullWidth
            placeholder="Choose NFT"
            value={NFT}
            onChange={handleChange}
        >
            {props.items.map((item: NTFSelectItem) => (
                <OilerMenuItem value={item.value}>
                    <Item {...item} />
                </OilerMenuItem>
            ))}
        </OilerSelect>
    );
};

export default NFTsSelect;
