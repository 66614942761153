import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import walletReducer from '@features/wallet/walletSlice';
import uiReducer from '@features/ui/uiSlice';
import optionsReducer from '@features/options/optionsSlice';
import blockchainMessenger from '@features/contracts-interactions/blockchainMessengerSlice';
import forwardsMessengerReducer from '@features/contracts-interactions/forwards/forwardsMessengerSlice';
import transactionsReducer from '@features/transactions/transactionsSlice';
import firebaseReducer from '@features/firebase/firebaseSlice';

export const rootReducer = {
    blockchainMessenger,
    wallet: walletReducer,
    ui: uiReducer,
    options: optionsReducer,
    transactions: transactionsReducer,
    firebase: firebaseReducer,
    forwardsMessenger: forwardsMessengerReducer,
};

export const store = configureStore({
    reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
