import React from 'react';
import styled from 'styled-components';
import {
    ToggleButton,
    ToggleButtonGroup,
    ToggleButtonGroupProps,
    ToggleButtonProps,
} from '@material-ui/lab';
import { space, SpaceProps } from 'styled-system';

const StyledToggleButtonGroup = styled(
    ToggleButtonGroup,
)<OilerToggleButtonGroupProps>`
    ${(props) => props.fullWidth && `width: 100%`};
    &.MuiToggleButtonGroup-root {
        border-radius: 0;
        color: ${(props) =>
            props.color === 'black'
                ? props.theme.palette.primary.contrastText
                : props.theme.palette.primary.contrastText};
    }
    .MuiToggleButton-root {
        border-radius: 0;
        padding: ${(props) => {
                // @ts-ignore
                return props.size === 'xsmall' ? 0 : '4px';
            }}
            8px;
        font-weight: ${(props) => props.theme.typography.fontWeightBold};
        color: ${(props) =>
            props.color === 'black'
                ? props.theme.palette.primary.contrastText
                : props.theme.palette.primary.main};

        border: 2px solid
            ${(props) =>
                props.color === 'black'
                    ? props.theme.palette.primary.contrastText
                    : props.theme.palette.primary.main};
        flex-grow: ${(props) => (props.fullWidth ? '1' : 'unset')};
        &:hover {
            background: ${(props) => props.theme.palette.primary[700]};
            color: ${(props) => props.theme.palette.primary.contrastText};
        }
        ${(props) => props.fullWidth && `width: 100%`};
    }
    .Mui-selected {
        background: ${(props) => props.theme.palette.primary.contrastText};
        background: ${(props) =>
            props.color === 'black'
                ? props.theme.palette.primary.contrastText
                : props.theme.palette.primary.main};

        color: ${(props) =>
            props.color === 'black'
                ? props.theme.palette.primary.main
                : props.theme.palette.primary.contrastText};
        &:hover {
            background: ${(props) =>
                props.color === 'black'
                    ? props.theme.palette.primary.contrastText
                    : props.theme.palette.primary.main};
            color: ${(props) =>
                props.color === 'black'
                    ? props.theme.palette.primary.main
                    : props.theme.palette.primary.contrastText};
        }
    }
    .Mui-disabled {
        color: rgba(255, 255, 255, 0.3);
    }
    ${space}
`;

// TODO fix size property interface extending inheritance
interface OilerToggleButtonGroupProps
    extends Omit<ToggleButtonGroupProps, 'size'>,
        SpaceProps {
    fullWidth?: boolean;
    color?: 'primary' | 'black';
    size?: 'xsmall';
}

const OilerToggleButtonGroup = (props: OilerToggleButtonGroupProps) => {
    const { children } = props;

    return (
        // @ts-ignore
        <StyledToggleButtonGroup {...props}>{children}</StyledToggleButtonGroup>
    );
};

export const OilerToggleButton = (props: ToggleButtonProps) => (
    <ToggleButton
        disableTouchRipple
        disableRipple
        disableFocusRipple
        {...props}
    >
        {props.children}
    </ToggleButton>
);

export default OilerToggleButtonGroup;
