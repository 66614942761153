import React from 'react';
import styled from 'styled-components';
import TransactionDescription from './TransactionDescription';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import { Transaction } from '@features/transactions/types';

const StyledTransactionRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    margin-right: 8px;
`;
interface TransactionRowProps {
    tx: Transaction;
}

const TransactionRow = ({ tx, ...props }: TransactionRowProps) => {
    return (
        <StyledTransactionRow {...props}>
            <TransactionDescription tx={tx} />
            <OilerButton ml={2} href={tx.url} target={'_blank'}>
                VIEW
            </OilerButton>
        </StyledTransactionRow>
    );
};

export default TransactionRow;
