import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContractsProvider } from '@services/contracts-provider';
import { ContractType } from '@services/contracts-types';
import {
    ContractsInteractionsState,
    InteractionState,
    Step,
    StepState,
} from './types';
import invariant from 'tiny-invariant';
import { addTransactionAsync } from '@features/transactions/transactionsSlice';
import { TxType } from '@features/transactions/types';

export const buildMintCollateralSteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const steps = [] as Step[];

    steps.push({
        name: `Request ${process.env.REACT_APP_COLLATERAL_NAME}`,
        state: StepState.Idle,
    });

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

export const mintCollateralTxAsync = createAsyncThunk(
    'collateral/mint-tx',
    async (payload: any, { dispatch }) => {
        const faucetContract = getContractsProvider().findOrCreate(
            ContractType.Faucet,
        );
        invariant(faucetContract, 'No faucet contract set');

        const mintCollateralTx = await faucetContract.request({
            gasLimit: process.env.REACT_APP_GAS_LIMIT,
        });

        dispatch(
            addTransactionAsync({
                ...mintCollateralTx,
                type: TxType.MintCollateral,
            }),
        );

        await mintCollateralTx.wait();
        return mintCollateralTx?.hash;
    },
);
