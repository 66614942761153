import styled from 'styled-components';
import { TableCell, TableCellProps } from '@material-ui/core';
import React from 'react';
import OilerSkeleton from '@oilerKit/OilerSkeleton';

const StyledTableCell = styled(TableCell)`
    &.MuiTableCell-body {
        font-weight: inherit;
    }
    &.MuiTableCell-body {
        color: inherit;
    }
`;

interface OilerTableCellProps extends TableCellProps {
    loading?: boolean;
}

const OilerTableCell = (props: OilerTableCellProps) => {
    const { loading } = props;
    return (
        <StyledTableCell {...props}>
            {loading ? <OilerSkeleton /> : props.children}
        </StyledTableCell>
    );
};

export default OilerTableCell;
