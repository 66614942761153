import OilerInput from '@oilerKit/OilerInput';
import OilerGrid from '@oilerKit/OilerGrid';
import React, { useState } from 'react';
import OilerModal from '@oilerKit/OilerModal';
import { useDispatch, useSelector } from 'react-redux';
import { isValidNumber } from '@utils/isValidNumber';
import { parseEther } from 'ethers/lib/utils';
import {
    buildStepsAsync,
    InteractionName,
} from '@features/contracts-interactions/forwards/steps-builder';
import { ActionModalProps } from '@features/ui/types';
import Table from '@components/TradingActions/Forwards/TradeModal/Table';
import { selectWallet } from '@features/wallet/walletSlice';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import { useMediaQuery, useTheme } from '@material-ui/core';

interface TradeModalProps {
    open: boolean;
    // eslint-disable-next-line
    setOpenActionModal: ({}: ActionModalProps) => void;
}

const TradeModal = (props: TradeModalProps) => {
    const { open, setOpenActionModal } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const alignSelf = !isSmallScreen ? 'stretch' : 'center';

    const [inputAmount, setInputAmount] = useState<string>('0');
    const { account } = useSelector(selectWallet);

    const handleInputChange = (value: string) => {
        setInputAmount(value);
    };

    // const handleWithdrawClick = () => {};

    const modalName = 'LONG DIFFICULTY 1M FWD';

    const dispatch = useDispatch();
    const handleLong = async () => {
        setOpenActionModal({ state: false, type: null });
        dispatch(
            buildStepsAsync({
                interactionName: InteractionName.GO_LONG,
                inputAmount: parseEther(inputAmount),
                account,
            }),
        );
    };

    const handleShort = async () => {
        setOpenActionModal({ state: false, type: null });
        dispatch(
            buildStepsAsync({
                interactionName: InteractionName.GO_SHORT,
                inputAmount: parseEther(inputAmount),
                account,
            }),
        );
    };

    return (
        <OilerModal
            open={open}
            onClose={() => setOpenActionModal({ state: false, type: null })}
            title={modalName}
        >
            <OilerGrid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
            >
                <OilerGrid item alignSelf={alignSelf}>
                    <OilerInput
                        fullWidth
                        width={!isSmallScreen ? 272 : undefined}
                        mb={4}
                        label={`Notional`}
                        handleInputChange={handleInputChange}
                        inputAmount={inputAmount}
                    />
                </OilerGrid>
                <OilerGrid item>
                    <Table inputAmount={inputAmount} />
                </OilerGrid>

                <OilerGrid spacing={2} item container mt={4}>
                    <OilerGrid xs={6} item>
                        <OilerButton
                            fullWidth
                            color={'secondary'}
                            size={'large'}
                            disabled={
                                !(
                                    isValidNumber(inputAmount) &&
                                    parseFloat(inputAmount) > 0
                                )
                            }
                            onClick={handleShort}
                        >
                            SELL
                        </OilerButton>
                    </OilerGrid>
                    <OilerGrid xs={6} item>
                        <OilerButton
                            fullWidth
                            color={'secondary'}
                            size={'large'}
                            disabled={
                                !(
                                    isValidNumber(inputAmount) &&
                                    parseFloat(inputAmount) > 0
                                )
                            }
                            onClick={handleLong}
                        >
                            BUY
                        </OilerButton>
                    </OilerGrid>
                </OilerGrid>
            </OilerGrid>
        </OilerModal>
    );
};

export default TradeModal;
