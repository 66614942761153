import OilerInput from '@oilerKit/OilerInput';
import OilerGrid from '@oilerKit/OilerGrid';
import React, { useState } from 'react';
import OilerModal from '@oilerKit/OilerModal';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import styled from 'styled-components';
import { selectWallet } from '@features/wallet/walletSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ethers } from 'ethers';
import {
    buildStepsAsync,
    InteractionName,
} from '@features/contracts-interactions/steps-builder';
import { OilerOption } from '@features/options/types';
import OilerToggleButtonGroup, {
    OilerToggleButton,
} from '@oilerKit/OilerToggleButtonGroup';
import { isValidNumber } from '@utils/isValidNumber';
import { selectOptions } from '@features/options/optionsSlice';
import { ActionModalProps } from '@features/ui/types';

const StyledOilerModal = styled(OilerGrid)`
    min-height: 322px;
    ${(props) => props.theme.breakpoints.up('sm')} {
        width: 272px;
        margin: auto;
    }
`;

interface LiquidityModalProps {
    open: boolean;
    option: OilerOption;
    // eslint-disable-next-line
    setOpenActionModal: ({}: ActionModalProps) => void;
}

const LiquidityModal = (props: LiquidityModalProps) => {
    const { open, setOpenActionModal, option } = props;

    const dispatch = useDispatch();

    const { balances } = useSelector(selectWallet);
    const { spotPrices } = useSelector(selectOptions);

    const [addLiquidityMode, setAddLiquidityMode] = useState<string>(
        InteractionName.ADD_LIQUIDITY,
    );

    const [optionsInputAmount, setOptionsInputAmount] = useState<string>('0');
    const [collateralInputAmount, setCollateralInputAmount] =
        useState<string>('0');
    const [lpTokensInputAmount, setLpTokensInputAmount] = useState<string>('0');

    const handleOptionsInputChange = (value: string) => {
        const optionsSpotPrice =
            spotPrices[process.env.REACT_APP_COLLATERAL_ADDRESS!]?.[
                option.address
            ]?.priceWithoutFee;

        const collateralForLiquidity = (
            Number(value) * Number(optionsSpotPrice)
        ).toFixed(2);

        setOptionsInputAmount(value);
        setCollateralInputAmount(collateralForLiquidity);
    };

    const handleCollateralInputChange = (value: string) => {
        const optionsSpotPrice =
            spotPrices[process.env.REACT_APP_COLLATERAL_ADDRESS!]?.[
                option.address
            ]?.priceWithoutFee;

        const optionsForLiquidity = (
            Number(value) / Number(optionsSpotPrice)
        ).toFixed(2);

        setOptionsInputAmount(optionsForLiquidity);
        setCollateralInputAmount(value);
    };

    const handleLpTokensInputChange = (value: string) => {
        setLpTokensInputAmount(value);
    };

    const handleOptionsMaxClick = () => {
        setOptionsInputAmount(option.held === '0.00' ? '0' : option.held);
    };

    const handleCollateralMaxClick = () => {
        setCollateralInputAmount(ethers.utils.formatUnits(balances['USDC'], 6));
    };

    const handleLpTokensMaxClick = () => {
        setLpTokensInputAmount(
            option.lpTokensBalance === '0.0' ? '0' : option.lpTokensBalance,
        );
    };

    const handleAddLiquidityClick = () => {
        dispatch(
            buildStepsAsync({
                interactionName: InteractionName.ADD_LIQUIDITY,
                optionAddress: option.address,
                allowanceAddress: process.env.REACT_APP_BALANCER_ROUTER_ADDRESS,
                allowanceAmount: collateralInputAmount,
                optionsAmount: optionsInputAmount,
                collateralAmount: collateralInputAmount,
            }),
        );
    };

    const handleRemoveLiquidityClick = () => {
        dispatch(
            buildStepsAsync({
                interactionName: InteractionName.REMOVE_LIQUIDITY,
                optionAddress: option.address,
                allowanceAddress: process.env.REACT_APP_BALANCER_ROUTER_ADDRESS,
                allowanceAmount: lpTokensInputAmount,
                lpTokensAmount: lpTokensInputAmount,
            }),
        );
    };

    const handleLiquidityMode = (
        event: React.MouseEvent<HTMLElement>,
        liquidityMode: string,
    ) => setAddLiquidityMode(liquidityMode);

    const HeaderAction = (
        <OilerToggleButtonGroup
            value={addLiquidityMode}
            onChange={handleLiquidityMode}
            exclusive
            color={'black'}
            fullWidth
        >
            <OilerToggleButton value={InteractionName.ADD_LIQUIDITY}>
                ADD
            </OilerToggleButton>
            <OilerToggleButton value={InteractionName.REMOVE_LIQUIDITY}>
                REMOVE
            </OilerToggleButton>
        </OilerToggleButtonGroup>
    );

    return (
        <OilerModal
            open={open}
            onClose={() => setOpenActionModal({ state: false, type: null })}
            title={'LIQUIDITY'}
            headerAction={HeaderAction}
        >
            {addLiquidityMode === InteractionName.ADD_LIQUIDITY ? (
                <StyledOilerModal
                    container
                    direction="column"
                    alignItems="center"
                >
                    <OilerInput
                        fullWidth
                        balanceValue={Number(
                            option.held === '0.00' ? '0' : option.held,
                        )}
                        label={`Provide ${option.code} Liquidity`}
                        handleInputChange={handleOptionsInputChange}
                        inputAmount={optionsInputAmount}
                        handleMaxClick={handleOptionsMaxClick}
                        mb={4}
                    />

                    <OilerInput
                        fullWidth
                        balanceValue={Number(
                            ethers.utils.formatUnits(balances['USDC'], 6),
                        )}
                        label={`Provide ${process.env.REACT_APP_COLLATERAL_NAME} Liquidity`}
                        handleInputChange={handleCollateralInputChange}
                        inputAmount={collateralInputAmount}
                        handleMaxClick={handleCollateralMaxClick}
                    />

                    <OilerButton
                        fullWidth
                        size={'large'}
                        mt={4}
                        disabled={
                            !isValidNumber(optionsInputAmount) ||
                            !isValidNumber(collateralInputAmount) ||
                            (parseFloat(option.held) <
                                parseFloat(optionsInputAmount) &&
                                parseFloat(optionsInputAmount) > 0) ||
                            (parseFloat(
                                ethers.utils.formatUnits(balances['USDC'], 6),
                            ) < parseFloat(collateralInputAmount) &&
                                parseFloat(collateralInputAmount) > 0)
                        }
                        onClick={handleAddLiquidityClick}
                        color={'secondary'}
                    >
                        ADD LIQUIDITY
                    </OilerButton>
                </StyledOilerModal>
            ) : (
                <StyledOilerModal
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                >
                    <OilerInput
                        mt={70}
                        fullWidth
                        balanceValue={Number(
                            option.lpTokensBalance === '0.0'
                                ? '0'
                                : option.lpTokensBalance,
                        )}
                        label={`Remove LP Tokens to Withdraw Liquidity`}
                        handleInputChange={handleLpTokensInputChange}
                        inputAmount={lpTokensInputAmount}
                        handleMaxClick={handleLpTokensMaxClick}
                    />

                    <OilerButton
                        fullWidth
                        size={'large'}
                        disabled={
                            !isValidNumber(lpTokensInputAmount) ||
                            parseFloat(option.lpTokensBalance) <= 0 ||
                            parseFloat(option.lpTokensBalance) <
                                parseFloat(lpTokensInputAmount)
                        }
                        mt={4}
                        onClick={handleRemoveLiquidityClick}
                        color={'secondary'}
                    >
                        REMOVE LIQUIDITY
                    </OilerButton>
                </StyledOilerModal>
            )}
        </OilerModal>
    );
};

export default LiquidityModal;
