import React, { useState } from 'react';
import {
    Bar,
    BarChart,
    Cell,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
} from 'recharts';
import { useTheme } from '@material-ui/core';
import styled from 'styled-components';

const StyledTooltip = styled.div`
    background: white;
    border: 1px solid black;
    color: black;
    padding: 4px 8px;
    font-size: 0.625rem;
`;

const ResponsiveWrapper = styled.div`
    width: 100%;
    height: 82px;
    position: relative;
`;

const CustomTooltip = ({ active, payload, label }: TooltipProps) => {
    const value = payload && payload[0] ? payload[0].value : '';

    return <StyledTooltip>{value}%</StyledTooltip>;
};

interface ForwardsMiniChartProps {
    data: any[];
    dataKey: string;
}

const ForwardsMiniChart = (props: ForwardsMiniChartProps) => {
    const theme = useTheme();
    const { data, dataKey } = props;
    const primaryColor = theme.palette.primary.main;
    const primaryColor700 = theme.palette.primary.dark;
    const [focusBar, setFocusBar] = useState(null);

    return (
        <ResponsiveWrapper>
            <ResponsiveContainer width="100%">
                <BarChart
                    data={data}
                    margin={{
                        top: 0,
                        right: -4,
                        left: -4,
                        bottom: 0,
                    }}
                    onMouseEnter={(state: any) => {
                        setFocusBar(state.activeTooltipIndex);
                    }}
                    onMouseLeave={() => {
                        setFocusBar(null);
                    }}
                    onMouseMove={(state: any) => {
                        if (state.isTooltipActive) {
                            setFocusBar(state.activeTooltipIndex);
                        } else {
                            setFocusBar(null);
                        }
                    }}
                >
                    <Tooltip content={<CustomTooltip />} cursor={false} />
                    <Bar dataKey={dataKey} fill={primaryColor}>
                        {data.map((entry, index: number) => (
                            <Cell
                                fill={
                                    focusBar === index
                                        ? primaryColor700
                                        : primaryColor
                                }
                            />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </ResponsiveWrapper>
    );
};

export default ForwardsMiniChart;
