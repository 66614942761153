import React from 'react';
import OilerModal from '@oilerKit/OilerModal';
import TransactionRow from './TransactionRow';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectTransactions } from '@features/transactions/transactionsSlice';
import { Transaction } from '@features/transactions/types';

const Wrapper = styled.div<any>`
    max-height: 250px;
    overflow-y: auto;
`;

const TransactionsList = (props: any) => {
    const { show, onHide } = props;
    const { transactions = [] } = useSelector(selectTransactions);

    return (
        <OilerModal
            // centered
            open={show}
            onClose={() => onHide(false)}
            closeButton
            title={'TRANSACTIONS'}
        >
            <Wrapper>
                {transactions.map((tx: Transaction, idx: number) => (
                    <TransactionRow tx={tx} key={`${tx}-${idx}`} />
                ))}
            </Wrapper>
        </OilerModal>
    );
};

export default TransactionsList;
