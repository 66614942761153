// @ts-ignore
import jazzicon from 'jazzicon';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { useSelector } from 'react-redux';
import { selectWallet } from '@features/wallet/walletSlice';

const StyledIdenticon = styled.div`
    ${space}
`;

interface OilerIdenticonProps extends SpaceProps {
    height?: number;
}

const OilerIdenticon = (props: OilerIdenticonProps) => {
    const { height = 24 } = props;
    const ref = useRef<HTMLDivElement>();
    const { account } = useSelector(selectWallet);

    useEffect(() => {
        // @ts-ignore
        ref.current.innerHTML = '';
        // @ts-ignore
        ref.current.appendChild(
            jazzicon(height, parseInt(account.slice(2, 10), 16)),
        );
    }, [account, height]);

    return <StyledIdenticon {...props} ref={ref as any} />;
};

export default OilerIdenticon;
