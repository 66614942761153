import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContractsProvider } from '@services/contracts-provider';
import { ContractType } from '@services/contracts-types';
import {
    ContractsInteractionsState,
    InteractionState,
    Step,
    StepState,
} from './types';
import invariant from 'tiny-invariant';
import { utils, ethers, BigNumber } from 'ethers';
import { addTransactionAsync } from '@features/transactions/transactionsSlice';
import { TxType } from '@features/transactions/types';

export const buildSwapSteps = async (
    payload: any,
): Promise<ContractsInteractionsState> => {
    const { optionAddress, allowanceAmount, reverseSwap } = payload;

    const contractsProvider = getContractsProvider();

    const collateralContract = getContractsProvider().findOrCreate(
        ContractType.Collateral,
    );
    invariant(collateralContract, 'No collateral contract set');

    const optionContract = getContractsProvider().findOrCreate(
        ContractType.Option,
        optionAddress,
    );
    invariant(optionContract, 'No option contract set');

    const userAddr = await contractsProvider.getAccount();
    invariant(userAddr, 'No user address set');

    const contractAllowance = reverseSwap
        ? await optionContract.allowance(
              userAddr,
              process.env.REACT_APP_BALANCER_ROUTER_ADDRESS,
          )
        : await collateralContract.allowance(
              userAddr,
              process.env.REACT_APP_BALANCER_ROUTER_ADDRESS,
          );

    const minRequired = utils.parseEther(allowanceAmount.toString());
    const needsApproval = BigNumber.from(contractAllowance.toString()).lt(
        minRequired,
    );

    const steps = [] as Step[];

    if (needsApproval) {
        steps.push({
            name: reverseSwap ? 'Approve Option' : 'Approve Collateral',
            state: StepState.Idle,
        });
    }
    steps.push({ name: 'Swap', state: StepState.Idle });

    return {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
    } as ContractsInteractionsState;
};

interface SwapPayload {
    from: string;
    to: string;
    swapAmount: string;
    slippage: number;
}

export const swapOptionTxAsync = createAsyncThunk(
    'swap/tx',
    async (payload: SwapPayload, { dispatch, getState }) => {
        const { from, to, swapAmount } = payload;

        const { account } = (getState() as any).wallet;
        const { spotPrices, slippage } = (getState() as any).options;

        invariant(account, 'No user address set');

        const routerContract = getContractsProvider().findOrCreate(
            ContractType.BalancerRouter,
        );
        invariant(routerContract, 'No router contract set');

        const currentPrice = ethers.utils.parseEther(
            spotPrices[from][to].priceWithFee,
        );
        const maxPrice = currentPrice.add(currentPrice.mul(slippage).div(100));

        const swapTx = await routerContract[
            'swapExactTokensForTokens(uint256,uint256,address[],address,uint256,uint256)'
        ](
            ethers.utils.parseUnits(swapAmount, 6),
            0,
            [from, to],
            account,
            ethers.constants.MaxUint256,
            maxPrice,
            { gasLimit: process.env.REACT_APP_GAS_LIMIT },
        );

        dispatch(addTransactionAsync({ ...swapTx, type: TxType.Swap }));

        await swapTx.wait();
        return swapTx?.hash;
    },
);
