import invariant from 'tiny-invariant';
import { Contract, ethers } from 'ethers';
import OilerOptionsRouter from '@abis/OilerOptionsRouter.json';
import { Contracts, ContractType, MultiCallContracts } from './contracts-types';
import BRouter from '@abis/BRouter.json';
import BPool from '@abis/BPool.json';
import OilerOptionDifficulty from '@abis/OilerOptionDifficulty.json';
import OilerRegistry from '@abis/OilerRegistry.json';
import FaucetAbi from '@abis/Faucet.json';
import { Contract as MultiCallContract } from 'ethers-multicall';
import { JsonFragment } from '@ethersproject/abi';
import ForwardsUIMock from '@abis/ForwardsUIMock.json';

class ContractsProvider {
    private chainId: number;
    private readonly contracts: Contracts;
    private readonly multicallContracts: MultiCallContracts;

    private provider: ethers.providers.Web3Provider | undefined;
    private signer: ethers.providers.JsonRpcSigner | undefined;

    constructor() {
        invariant(
            process.env.REACT_APP_CHAIN_ID,
            'REACT_APP_CHAIN_ID is required in env',
        );
        this.chainId = parseInt(process.env.REACT_APP_CHAIN_ID!);
        this.contracts = {
            [this.chainId]: {},
        };
        this.multicallContracts = {
            [this.chainId]: {},
        };
        if (window?.ethereum) {
            this.provider = new ethers.providers.Web3Provider(window?.ethereum);
            this.signer = this.provider.getSigner();
        }
    }

    public getProvider(): ethers.providers.Web3Provider | undefined {
        return this.provider;
    }

    public async getChainId(): Promise<number> {
        return this.chainId;
    }

    private _findOrCreate(
        addressOrName: string,
        contractInterface: ethers.ContractInterface,
    ) {
        if (!this.contracts[this.chainId][addressOrName]) {
            this.contracts[this.chainId][addressOrName] = new ethers.Contract(
                addressOrName,
                contractInterface,
                this.signer,
            );
        }
        return this.contracts[this.chainId][addressOrName];
    }

    private _findOrCreateMulticall(
        addressOrName: string,
        contractInterface: string[] | JsonFragment[] | ethers.utils.Fragment[],
    ) {
        if (!this.multicallContracts[this.chainId][addressOrName]) {
            this.multicallContracts[this.chainId][addressOrName] =
                new MultiCallContract(addressOrName, contractInterface);
        }
        return this.multicallContracts[this.chainId][addressOrName];
    }

    public findOrCreate(
        ct: ContractType,
        address?: string,
        multicall = false,
    ): Contract | MultiCallContract {
        const getContract = multicall
            ? this._findOrCreateMulticall.bind(this)
            : this._findOrCreate.bind(this);

        switch (ct) {
            case ContractType.Collateral:
                // invariant(
                //     process.env.REACT_APP_COLLATERAL_ADDRESS!,
                //     'Contract address is required',
                // );

                // return this._findOrCreate(
                //     process.env.REACT_APP_COLLATERAL_ADDRESS!,
                //     ERC20.abi,
                //     this.signer,
                // );

                invariant(
                    process.env.REACT_APP_FAUCET_ADDRESS,
                    'Faucet address is required',
                );
                return getContract(
                    process.env.REACT_APP_FAUCET_ADDRESS,
                    FaucetAbi,
                );

            case ContractType.BalancerRouter:
                invariant(
                    process.env.REACT_APP_BALANCER_ROUTER_ADDRESS!,
                    'Contract address is required',
                );

                return getContract(
                    process.env.REACT_APP_BALANCER_ROUTER_ADDRESS!,
                    BRouter.abi,
                );

            case ContractType.BalancerPool:
                invariant(address, 'Contract address is required');

                return getContract(address, BPool.abi);

            case ContractType.OilerRegistry:
                invariant(
                    process.env.REACT_APP_OILER_REGISTRY_ADDRESS!,
                    'Contract address is required',
                );

                return getContract(
                    process.env.REACT_APP_OILER_REGISTRY_ADDRESS!,
                    OilerRegistry.abi,
                );

            case ContractType.Option:
                invariant(address, 'Contract address is required');

                return getContract(address, OilerOptionDifficulty.abi);

            case ContractType.OptionsRouter:
                invariant(
                    process.env.REACT_APP_OILER_OPTIONS_ROUTER_ADDRESS,
                    'Router address is required',
                );
                return getContract(
                    process.env.REACT_APP_OILER_OPTIONS_ROUTER_ADDRESS,
                    OilerOptionsRouter.abi,
                );

            case ContractType.Faucet:
                invariant(
                    process.env.REACT_APP_FAUCET_ADDRESS,
                    'Faucet address is required',
                );
                return getContract(
                    process.env.REACT_APP_FAUCET_ADDRESS,
                    FaucetAbi,
                );

            case ContractType.ForwardsUI:
                invariant(
                    process.env.REACT_APP_FORWARDS_UI_ADDRESS,
                    'Forwards ui address is required',
                );
                return getContract(
                    process.env.REACT_APP_FORWARDS_UI_ADDRESS!,
                    ForwardsUIMock.abi,
                );

            default:
                throw new Error(`Invalid contract type: ${ct}`);
        }
    }

    // public async getForwardsSDK(): Promise<ForwardsProvider> {
    //     const provider = this.getProvider();
    //     const forwardsProvider = await getForwardsProvider(
    //         process.env.REACT_APP_FORWARDS_UI_ADDRESS,
    //         provider,
    //     );

    //     const signer = provider?.getSigner();
    //     if (signer) {
    //         forwardsProvider.connectSigner(signer);
    //     }
    //     invariant(forwardsProvider, 'No forwards SDK set');

    //     return forwardsProvider;
    // }

    public async getAccount(): Promise<string | undefined> {
        return this.signer?.getAddress();
    }
}

// Singleton instance
let instance: ContractsProvider | null = null;

export const getContractsProvider = () => {
    instance = instance ?? new ContractsProvider();
    return instance;
};
