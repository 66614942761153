import React from 'react';
import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { Button, ButtonProps } from '@material-ui/core';
import ConditionalLink from '@oilerKit/OilerButton/ConditionalLink';

const disabledProperties = css`
    background: ${(props) => props.theme.palette.primary[800]};
    border-color: ${(props) => props.theme.palette.primary[800]};
`;

const StyledButton = styled(Button)`
    &.MuiButton-primary {
        color: ${(props) => props.theme.palette.secondary.contrastText};
    }
    &.MuiButton-outlinedPrimary {
        border: 2px solid ${(props) => props.theme.palette.primary.main};
        &:hover {
            background: ${(props) => props.theme.palette.primary.main};
            color: ${(props) => props.theme.palette.primary.contrastText};
        }
    }
    &.MuiButton-outlinedSecondary {
        border: 2px solid ${(props) => props.theme.palette.secondary.main};
        &:hover {
            background: ${(props) => props.theme.palette.secondary.main};
            color: ${(props) => props.theme.palette.secondary.contrastText};
        }
    }
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
    border-radius: 0;
    &.Mui-disabled {
        ${disabledProperties}
    }

    ${space}
`;

export interface OilerButtonProps extends ButtonProps, SpaceProps {
    fullWidth?: boolean;
    target?: '_blank';
}

const OilerButton = (props: OilerButtonProps) => {
    const { color = 'primary', target, href } = props;

    return (
        <ConditionalLink condition={!!target} target={target} href={href}>
            <StyledButton
                href={undefined}
                color={color}
                variant={`contained`}
                {...props}
                disableElevation
                disableRipple
                disableFocusRipple
                disableTouchRipple
            >
                {props.children}
            </StyledButton>
        </ConditionalLink>
    );
};

export default OilerButton;
