import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import OilerIcon from '@oilerKit/OilerIcon';
import metamaskIcon from '@oilerKit/assets/icons/metamask.svg';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import { formatAddr } from '@utils/formatAddr';
import {
    changeAccountsAsync,
    fetchBalancesAsync,
    initNetworkAsync,
    requestAccountsAsync,
    selectWallet,
} from '@features/wallet/walletSlice';
import SettingsIcon from '@material-ui/icons/Settings';

import OilerStatistic from '@oilerKit/OilerStatistic';
import OilerPopover from '@oilerKit/OilerPopover';
import OilerIconButton from '@oilerKit/OilerButton/OilerIconButton';
import OilerSettings from '@oilerKit/OilerSettings/OilerSettings';
import OilerIdenticon from '@oilerKit/OilerNav/WalletInfo/Identicon';
import OilerModal from '@oilerKit/OilerModal';
import { OilerAlert } from '@oilerKit/OilerAlert';
import OilerTooltip from '@oilerKit/OilerTooltip';
import LaunchSharpIcon from '@material-ui/icons/LaunchSharp';
import { METAMASK_SITE_URL } from '@constants/index';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const ConnectWalletWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    ${(props) => props.theme.breakpoints.down('sm')} {
        justify-content: flex-start;
    }
`;

const MetaMaskWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
`;

const MetaMask = () => {
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const [isMetamask, setIsMetamask] = useState<boolean>(false);
    const [showNoMetamaskModal, setShowNoMetamaskModal] =
        useState<boolean>(false);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConnect = useCallback(async () => {
        isMetamask
            ? dispatch(requestAccountsAsync())
            : setShowNoMetamaskModal(true);
    }, [dispatch, isMetamask]);

    const handleDisconnect = useCallback(async () => {
        dispatch(changeAccountsAsync());
    }, [dispatch]);

    const { isConnected, loading, account, network, isWrongNetwork } =
        useSelector(selectWallet);

    useEffect(() => {
        setIsMetamask(window.ethereum !== undefined);
        window?.ethereum?.on('accountsChanged', () => window.location.reload());
        window?.ethereum?.on('chainChanged', () => window.location.reload());

        dispatch(requestAccountsAsync());
        dispatch(initNetworkAsync());
    }, [dispatch]);

    useEffect(() => {
        if (account && !isWrongNetwork) {
            dispatch(fetchBalancesAsync(account));
        }
    }, [dispatch, account, isWrongNetwork]);

    return (
        <MetaMaskWrapper>
            <Wrapper
                onClick={(e: any) => {
                    handleClick(e);
                }}
            >
                {loading.account && !isConnected ? null : (
                    <OilerIdenticon height={35} mr={10} />
                )}

                <ConnectWalletWrapper>
                    {isConnected && (
                        <>
                            <OilerStatistic
                                label={formatAddr(account)}
                                value={network}
                                skeletonWidth={80}
                                loading={!network}
                            />
                            <OilerTooltip title="Settings">
                                <div>
                                    <OilerIconButton pr={0}>
                                        <SettingsIcon
                                            style={{ color: 'white' }}
                                        />
                                    </OilerIconButton>
                                </div>
                            </OilerTooltip>
                        </>
                    )}
                </ConnectWalletWrapper>
            </Wrapper>
            {!isConnected && (
                <OilerButton
                    onClick={handleConnect}
                    fullWidth
                    mt={2}
                    endIcon={<OilerIcon icon={metamaskIcon} />}
                >
                    CONNECT WALLET
                </OilerButton>
            )}
            <OilerPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <OilerSettings
                    address={formatAddr(account)}
                    network={network}
                    disconnect={handleDisconnect}
                />
            </OilerPopover>
            {showNoMetamaskModal && (
                <OilerModal
                    open={showNoMetamaskModal}
                    onClose={() => setShowNoMetamaskModal(false)}
                    title="NO METAMASK"
                >
                    <OilerAlert
                        severity={'info'}
                        action={
                            <OilerButton
                                href={METAMASK_SITE_URL}
                                target="_blank"
                                size={'small'}
                                variant={'text'}
                                endIcon={<LaunchSharpIcon />}
                            >
                                DOWNLOAD
                            </OilerButton>
                        }
                    >
                        You don't have Metamask extension installed. Please
                        install Metamask extension in order to use app.
                    </OilerAlert>
                </OilerModal>
            )}
        </MetaMaskWrapper>
    );
};

export default MetaMask;
