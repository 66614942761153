import React from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '@material-ui/core';
import OilerTypography from '@oilerKit/OilerTypography';

const StyledBox = styled(Box)<OilerBoxProps>`
    border: ${(props) =>
        props.noBorder
            ? 'none'
            : `2px solid ${props.theme.palette.primary['500']}`};
    background: ${(props) =>
        props.noBackground ? 'transparent' : props.theme.palette.common.black};
`;

const StyledOilerTypography = styled(OilerTypography)`
    color: ${(props) => props.theme.palette.primary[800]};
    &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 8px;
        background: ${(props) => props.theme.palette.primary[800]};
    }
`;

interface OilerBoxProps extends BoxProps {
    noBorder?: boolean;
    noBackground?: boolean;
    title?: string;
}

const OilerBox = (props: OilerBoxProps) => {
    const { children, title } = props;
    return (
        <StyledBox {...props}>
            {title && (
                <Box mb={3}>
                    <StyledOilerTypography
                        variant={'h6'}
                        weight={'bold'}
                        mb={0}
                    >
                        {title}
                    </StyledOilerTypography>
                </Box>
            )}
            {children}
        </StyledBox>
    );
};

export default OilerBox;
