import React from 'react';
import styled from 'styled-components';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { Icon } from '@material-ui/core';

interface OilerIconProps extends SpaceProps, LayoutProps {
    icon: string;
    alt?: string;
}

const StyledIcon = styled(Icon)<OilerIconProps>`
    ${space}
    ${layout}
`;

const StyledImg = styled.img`
    display: flex;
    width: auto;
    height: inherit;
`;

const OilerIcon = (props: OilerIconProps) => {
    const { icon, height, alt } = props;
    const styles = height
        ? {
              width: 'auto',
          }
        : {};

    return (
        <StyledIcon {...props} style={styles}>
            <StyledImg src={icon} alt={alt} />
        </StyledIcon>
    );
};

export default OilerIcon;
