import styled, { keyframes } from 'styled-components';
import React from 'react';
import { ToastProps } from 'react-toast-notifications';
import { OilerAlert } from '@oilerKit/OilerAlert';

const slideLeft = keyframes`
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
`;

const StyledOilerAlert = styled(OilerAlert)`
    max-width: 350px;
    animation: ${slideLeft} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    margin-bottom: 16px;
    &.MuiAlert-filledInfo {
        color: ${(props) => props.theme.palette.primary.contrastText};
    }
`;
// TODO add close/dismiss handling
const OilerToast = (props: ToastProps) => {
    const { children, appearance } = props;
    return (
        <StyledOilerAlert variant={'filled'} severity={appearance}>
            {children}
        </StyledOilerAlert>
    );
};

export default OilerToast;
