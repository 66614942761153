import React from 'react';
import styled from 'styled-components';
import Balance from './Balance';
import MetaMask from './MetaMask';
import { gap } from '@oilerKit/OilerPolyfill';

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 38px;
    flex-direction: column;
    ${gap(40, 'b')}
    ${(props) => props.theme.breakpoints.up(630)} {
        flex-direction: row;
        ${gap(40, 'r')}
    }
    ${(props) => props.theme.breakpoints.down(630)} {
        margin-bottom: 24px;
    }
`;

interface WalletInfoProps {
    walletBalance?: boolean;
}

const WalletInfo = ({ walletBalance }: WalletInfoProps) => {
    return (
        <Wrapper>
            <Balance walletBalance={walletBalance} />
            <MetaMask />
        </Wrapper>
    );
};

export default WalletInfo;
