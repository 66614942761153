import { multiCallProvider } from './../../services/web3-provider';
import { formatNbToSI } from './../../utils/formatNbToSI';
import {
    ref,
    get,
    onChildAdded,
    onChildChanged,
    child,
    query,
    limitToFirst,
} from 'firebase/database';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import {
    DbOption,
    defaultOption,
    HistoricalOptionsState,
    initialState,
    OilerOption,
    OptionsState,
    TradingOptionsFamily,
    tradingOptionsInfo,
} from './types';
import { database } from '@lib/firebase-client';
import { BigNumber, BigNumberish, ethers } from 'ethers';
import invariant from 'tiny-invariant';
import { getContractsProvider } from '@services/contracts-provider';
import { ContractType } from '@services/contracts-types';

interface CalculateSpotPricePayload {
    from: string;
    to: string;
}

export const calculateSpotPriceAsync = createAsyncThunk(
    'options/calc-spot-price',
    async (payload: CalculateSpotPricePayload) => {
        const { from, to } = payload;

        const routerContract = getContractsProvider().findOrCreate(
            ContractType.BalancerRouter,
        );
        invariant(routerContract, 'No router contract set');

        const priceWithFee: BigNumberish =
            await routerContract.getSpotPriceWithFee(from, to, {
                gasLimit: process.env.REACT_APP_GAS_LIMIT,
            });

        const priceWithoutFee: BigNumberish =
            await routerContract.getSpotPriceSansFee(from, to, {
                gasLimit: process.env.REACT_APP_GAS_LIMIT,
            });

        return {
            from,
            to,
            priceWithFee: ethers.utils.formatEther(priceWithFee),
            priceWithoutFee: ethers.utils.formatEther(priceWithoutFee),
        };
    },
);

interface GetAmountInPayload {
    from: string;
    to: string;
    amountOut: string;
}

export const getAmountIn = createAsyncThunk(
    'options/get-amount-in',
    async (payload: GetAmountInPayload) => {
        const { from, to, amountOut } = payload;

        const routerContract = getContractsProvider().findOrCreate(
            ContractType.BalancerRouter,
        );
        invariant(routerContract, 'No router contract set');

        const amountIn: BigNumberish = await routerContract.getAmountIn(
            ethers.utils.parseUnits(amountOut, 6),
            from,
            to,
        );

        return {
            from,
            to,
            calculatedSwapAmount: ethers.utils.formatUnits(amountIn, 6),
        };
    },
);

interface GetAmountOutPayload {
    from: string;
    to: string;
    amountIn: string;
}

export const getAmountOut = createAsyncThunk(
    'options/get-amount-out',
    async (payload: GetAmountOutPayload) => {
        const { from, to, amountIn } = payload;

        const routerContract = getContractsProvider().findOrCreate(
            ContractType.BalancerRouter,
        );
        invariant(routerContract, 'No router contract set');

        const amountOut: BigNumberish = await routerContract.getAmountOut(
            ethers.utils.parseUnits(amountIn, 6),
            from,
            to,
        );

        return {
            from,
            to,
            calculatedSwapAmount: ethers.utils.formatUnits(amountOut, 6),
        };
    },
);

export const fetchDbOptionsDataAsync = createAsyncThunk(
    'options/db-data',
    async (optionsFamily: string, { dispatch }) => {
        dispatch(
            changeHistoricalOptionsState(HistoricalOptionsState.DbDataFetching),
        );

        const fireOptionsEventsRef = ref(database, 'optionsView');
        const optionsQuery = query(fireOptionsEventsRef, limitToFirst(10));
        const optionsSnapshot = await get(optionsQuery);

        const options = Object.values(optionsSnapshot.val());

        return formatBaseData(Object.values(options)).sort(
            (a, b) => b.expiration - a.expiration,
        );
    },
);

const formatBaseData = (options: any[]): any[] => {
    return options.map((option: any) => {
        const expirationNumber =
            BigNumber.from(option.expirationDate).toNumber() * 1000;

        const expiration = new Date(expirationNumber);
        const formattedExpiration =
            expiration.getUTCDate() +
            '/' +
            (expiration.getUTCMonth() + 1) +
            '/' +
            expiration.getUTCFullYear();

        const expirationHours = expiration.getUTCHours().toString();
        const expirationMinutes = expiration.getUTCMinutes().toString();

        const expirationTime =
            expirationHours.padStart(2, '0') +
            ':' +
            expirationMinutes.padStart(2, '0') +
            ' UTC';

        const formattedStrikePrice = formatNbToSI(
            BigNumber.from(option.strikePrice).toNumber(),
        );

        const strikePriceMetric = (tradingOptionsInfo as any)[option.optionType]
            .metric;

        const formattedExercisable = BigNumber.from(
            option.exercisableAtBlock,
        ).toNumber();

        return {
            name: option.optionName,
            family: option.optionType,
            address: option.optionAddress,
            code: option.optionSymbol,
            type: option.optionDirection,
            expiration: expirationNumber,
            expDate: formattedExpiration,
            expTime: expirationTime,
            strike: formattedStrikePrice,
            strikeMetric: strikePriceMetric,
            isActive: option.isActive,
            exercisableAtBlock: formattedExercisable,
        };
    });
};

export const fetchDynamicOptionsDataAsync = createAsyncThunk(
    'options/dynamic-data',
    async (optionsBaseData: DbOption[], { getState, dispatch }) => {
        dispatch(
            changeHistoricalOptionsState(
                HistoricalOptionsState.DynamicDataFetching,
            ),
        );

        const { account } = (getState() as any).wallet;
        invariant(account, 'No account set');

        const { displayOption } = (getState() as any).options;
        invariant(displayOption, 'No displayOption set');

        await multiCallProvider.init();

        const contractsProvider = getContractsProvider();

        const routerContract = contractsProvider.findOrCreate(
            ContractType.BalancerRouter,
            '',
            true,
        );
        invariant(routerContract, 'No router contract set');

        const optionsDynamicData = optionsBaseData.map(
            async (dbOption: DbOption, index) => {
                const { unrealizedPnl, realizedPnl, fullPnl, ...option } =
                    dbOption;

                const optionContract = contractsProvider.findOrCreate(
                    ContractType.Option,
                    option.address,
                    true,
                );

                const [
                    afterExpiration,
                    isExercised,
                    withdrawableCount,
                    written,
                    held,
                    poolAddress,
                ] = await multiCallProvider.all([
                    optionContract.isAfterExpirationDate(),
                    optionContract.hasBeenExercised(),
                    optionContract.getWithdrawable(account),
                    optionContract.writerBalances(account),
                    optionContract.balanceOf(account),
                    routerContract.getPoolByTokens(
                        option.address,
                        process.env.REACT_APP_COLLATERAL_ADDRESS,
                    ),
                ]);

                const poolContract = contractsProvider.findOrCreate(
                    ContractType.BalancerPool,
                    poolAddress,
                    true,
                );

                const [
                    [optionsReserves, collateralReserves],
                    lpTokens,
                    lpTokensSupply,
                ] = await multiCallProvider.all([
                    routerContract.getReserves(poolAddress),
                    poolContract.balanceOf(account),
                    poolContract.totalSupply(),
                ]);

                // const dynamicProps = [
                //     optionContract.isAfterExpirationDate(),
                //     optionContract.hasBeenExercised(),
                //     optionContract.getWithdrawable(account),
                //     optionContract.writerBalances(account),
                //     optionContract.balanceOf(account),

                //     routerContract['getReserves(address,address)'](
                //         option.address,
                //         process.env.REACT_APP_COLLATERAL_ADDRESS,
                //     ),
                //     routerContract
                //         .getPoolByTokens(
                //             option.address,
                //             process.env.REACT_APP_COLLATERAL_ADDRESS,
                //         )
                //         .then((poolAddress: string) => {
                //             const poolContract = contractsProvider.findOrCreate(
                //                 ContractType.BalancerPool,
                //                 poolAddress,
                //                 true,
                //             );

                //             return Promise.all([
                //                 poolContract.balanceOf(account),
                //                 poolContract.totalSupply(),
                //             ]);
                //         }),
                // ];

                // const [
                //     afterExpiration,
                //     isExercised,
                //     withdrawableCount,
                //     written,
                //     held,
                //     [optionsReserves, collateralReserves],
                //     [lpTokens, lpTokensSupply],
                // ] = await Promise.all(dynamicProps);

                const heldOptions = Number(
                    ethers.utils.formatUnits(held, 6),
                ).toFixed(2);
                const writtenOptions = Number(
                    ethers.utils.formatUnits(written, 6),
                ).toFixed(2);
                const withdrawableOptions = Number(
                    ethers.utils.formatUnits(withdrawableCount, 6),
                ).toFixed(2);

                const optionsPoolReserves = ethers.utils.formatUnits(
                    optionsReserves,
                    6,
                );

                const collateralPoolReserves = ethers.utils.formatUnits(
                    collateralReserves,
                    6,
                );

                const lpTokensBalance = ethers.utils.formatUnits(lpTokens, 18);
                const formattedLpTokensBalance = formatNbToSI(
                    Number(lpTokensBalance),
                );

                const lpValue = BigNumber.from(
                    collateralReserves.toString(),
                ).mul(2);
                const tvl = BigNumber.from(lpTokens.toString())
                    .mul(lpValue)
                    .div(BigNumber.from(lpTokensSupply.toString()));

                const tvlFormatted = Number(
                    ethers.utils.formatUnits(tvl, 6),
                ).toFixed(2);

                const active = !afterExpiration && !isExercised ? 'Active' : '';
                const exercisable =
                    option.exercisableAtBlock > -1 &&
                    !isExercised &&
                    !afterExpiration
                        ? 'Exercisable'
                        : '';
                const expired =
                    afterExpiration && !isExercised ? 'Expired' : '';
                const owned =
                    +heldOptions > 0 || +writtenOptions > 0 ? 'Owned' : '';
                const exercised = isExercised ? 'Exercised' : '';
                const withdrawable =
                    +withdrawableOptions > 0 ? 'Withdrawable' : '';
                const providedLiquidity = +lpTokensBalance > 0 ? 'LP' : '';

                const status = [
                    active,
                    owned,
                    exercisable,
                    expired,
                    exercised,
                    withdrawable,
                    providedLiquidity,
                ].filter((x) => x !== '');

                const fullOption = {
                    ...option,
                    status,
                    held: heldOptions,
                    written: writtenOptions,
                    withdrawable: withdrawableOptions,
                    optionsPoolReserves,
                    collateralPoolReserves,
                    lpTokensBalance,
                    lpTokensBalanceFormatted: formattedLpTokensBalance,
                    lpValueLocked: tvlFormatted,
                };

                dispatch(addOrUpdateHistoricalOptions(fullOption));

                return fullOption;
            },
        );

        return optionsDynamicData[0].then(() => {
            dispatch(setDisplayOption(displayOption.address));
            return Promise.all(optionsDynamicData.slice(1));
        });
    },
);

export const dbOptionsLiveDataListener = createAsyncThunk(
    'options/db-data-listener',
    async (payload: any, { dispatch, getState }) => {
        const { historicalOptions } = (getState() as any).options;

        const fireOptionsEventsRef = ref(database, 'optionsView');
        const optionsQuery = query(fireOptionsEventsRef, limitToFirst(10));

        const addresses = Object.values(historicalOptions).flatMap((x: any) =>
            x.map((y: any) => y.address),
        );

        onChildAdded(optionsQuery, (snapshot) => {
            const addedOption = snapshot.val();

            if (addresses.includes(addedOption.optionAddress)) {
                return;
            }
            console.log('added', addedOption);

            dispatch(updateOptionDataAsync(addedOption));
        });

        onChildChanged(fireOptionsEventsRef, (snapshot) => {
            const changedOption = snapshot.val();
            console.log('changed', changedOption);

            dispatch(updateOptionDataAsync(changedOption));
        });
    },
);

export const updateOptionDataAsync = createAsyncThunk(
    'options/data-update',
    async (payload: any, { dispatch, getState }) => {
        const { displayOption } = (getState() as any).options;

        const option = payload;
        const formatted = formatBaseData([option])[0];

        dispatch(addOrUpdateHistoricalOptions(formatted));
        await dispatch(fetchDynamicOptionsDataAsync([formatted]));
        dispatch(setDisplayOption(displayOption.address));
    },
);

export const pnlLiveDataListener = createAsyncThunk(
    'options/pnl-data-listener',
    async (payload: any, { dispatch, getState }) => {
        const { account } = (getState() as any).wallet;
        invariant(account, 'No account set');

        const pnlEventsRef = ref(database, 'portfolios');

        get(child(pnlEventsRef, account)).then((snapshot) => {
            const portfolio = snapshot.val() || {};
            console.log('pnl', portfolio);

            const interactedOptions = Object.keys(portfolio).reduce(
                (acc: any, type) => {
                    Object.keys(portfolio[type]).forEach((address) => {
                        const { unrealizedPnl, realizedPnl, fullPnl } =
                            portfolio[type][address];

                        acc[address] = {
                            unrealizedPnl,
                            realizedPnl,
                            fullPnl,
                        };
                    });

                    return acc;
                },
                {},
            );

            dispatch(updateOptionPnlAsync(interactedOptions));
        });

        // pnlEventsRef.on('value', (snapshot) => {
        //     const portfolio = snapshot.val() || {};
        //     console.log('pnl', portfolio);

        //     const interactedOptions = Object.keys(portfolio).reduce(
        //         (acc: any, type) => {
        //             Object.keys(portfolio[type]).forEach((address) => {
        //                 const {
        //                     unrealizedPnl,
        //                     realizedPnl,
        //                     fullPnl,
        //                 } = portfolio[type][address];

        //                 acc[address] = {
        //                     unrealizedPnl,
        //                     realizedPnl,
        //                     fullPnl,
        //                 };
        //             });

        //             return acc;
        //         },
        //         {},
        //     );

        //     dispatch(updateOptionPnlAsync(interactedOptions));
        // });
    },
);

export const updateOptionPnlAsync = createAsyncThunk(
    'options/pnl-update',
    async (payload: any, { dispatch, getState }) => {
        const { historicalOptions, displayOption } = (getState() as any)
            .options;
        const interactedOptions = payload;

        Object.values(historicalOptions)
            .flatMap((x) => x as OilerOption)
            .filter((option: OilerOption) => interactedOptions[option.address])
            .forEach((option: OilerOption) => {
                dispatch(
                    addOrUpdateHistoricalOptions({
                        ...option,
                        ...interactedOptions[option.address],
                    }),
                );
            });

        dispatch(setDisplayOption(displayOption.address));
    },
);

export const options = createSlice({
    name: 'options',
    initialState,
    reducers: {
        setTradingOption: (state: OptionsState, action: any) => {
            state.tradingOption = action.payload;
            state.displayOption =
                state.historicalOptions[
                    action.payload as TradingOptionsFamily
                ][0];
        },
        setDisplayOption: (state: OptionsState, action: any) => {
            const option =
                Object.values(state.historicalOptions)
                    .flatMap((value) => value)
                    .find((x) => x.address === action.payload) || defaultOption;

            state.displayOption = option;
        },
        addOrUpdateHistoricalOptions: (state: OptionsState, action: any) => {
            const newOption = action.payload;

            const historicalsOfType = [
                ...state.historicalOptions[
                    newOption.family as TradingOptionsFamily
                ],
            ];

            const updateIndex = historicalsOfType.findIndex(
                (x) => x.address === newOption.address,
            );

            if (updateIndex < 0) {
                historicalsOfType.unshift({ ...defaultOption, ...newOption });
            } else {
                historicalsOfType[updateIndex] = {
                    ...historicalsOfType[updateIndex],
                    ...newOption,
                };
            }

            const newHistoricals = {
                ...state.historicalOptions,
                [newOption.family]: historicalsOfType,
            };

            state.historicalOptions = newHistoricals;
        },
        changeHistoricalOptionsInitialFetchFlag: (
            state: OptionsState,
            action: any,
        ) => {
            state.historicalOptionsInitialFetch = action.payload;
        },
        changeHistoricalOptionsState: (state: OptionsState, action: any) => {
            state.historicalOptionsState = action.payload;
        },
        initiateSwapPricingData: (state: OptionsState, action: any) => {
            const { from, to } = action.payload;

            if (!state.spotPrices[from]) {
                state.spotPrices[from] = {};
            }

            state.spotPrices[from][to] = {
                priceWithFee: state.spotPrices[from][to]?.priceWithFee || '0',
                priceWithoutFee:
                    state.spotPrices[from][to]?.priceWithoutFee || '0',
                calculatedSwapAmount: '0',
            };

            state.spotPrices[from][to] = {
                priceWithFee: state.spotPrices[from][to].priceWithFee,
                priceWithoutFee: state.spotPrices[from][to].priceWithoutFee,
                calculatedSwapAmount: '0',
            };
        },
        changeAllowanceMode: (state: OptionsState, action: any) => {
            state.limitedAllowance = action.payload;
        },
        changeSlippage: (state: OptionsState, action: any) => {
            state.slippage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(calculateSpotPriceAsync.fulfilled, (state, action) => {
                const { from, to, priceWithFee, priceWithoutFee } =
                    action.payload;

                if (!state.spotPrices[from]) {
                    state.spotPrices[from] = {};
                }

                state.spotPrices[from][to] = {
                    priceWithFee,
                    priceWithoutFee,
                    calculatedSwapAmount:
                        state.spotPrices[from][to]?.calculatedSwapAmount || '0',
                };
            })
            .addCase(getAmountIn.fulfilled, (state, action) => {
                const { from, to, calculatedSwapAmount } = action.payload;

                state.spotPrices[from][to] = {
                    priceWithFee: state.spotPrices[from][to].priceWithFee,
                    priceWithoutFee: state.spotPrices[from][to].priceWithoutFee,
                    calculatedSwapAmount,
                };
            })
            .addCase(getAmountOut.fulfilled, (state, action) => {
                const { from, to, calculatedSwapAmount } = action.payload;

                state.spotPrices[from][to] = {
                    priceWithFee: state.spotPrices[from][to].priceWithFee,
                    priceWithoutFee: state.spotPrices[from][to].priceWithoutFee,
                    calculatedSwapAmount,
                };
            })
            .addCase(fetchDbOptionsDataAsync.fulfilled, (state, action) => {
                state.historicalOptionsState =
                    HistoricalOptionsState.DbDataSuccess;
                state.historicalOptions = action.payload.reduce(
                    (acc, baseOption) => {
                        const option = {
                            ...defaultOption,
                            ...baseOption,
                        };

                        acc[option.family] = [...acc[option.family], option];
                        return acc;
                    },
                    { ...initialState.historicalOptions },
                );
            })
            .addCase(fetchDbOptionsDataAsync.rejected, (state, action) => {
                state.historicalOptionsState =
                    HistoricalOptionsState.DbDataFail;
            })
            .addCase(
                fetchDynamicOptionsDataAsync.fulfilled,
                (state, action) => {
                    state.historicalOptionsState =
                        HistoricalOptionsState.DynamicDataSuccess;
                },
            )
            .addCase(fetchDynamicOptionsDataAsync.rejected, (state, action) => {
                state.historicalOptionsState =
                    HistoricalOptionsState.DynamicDataFail;
            });
    },
});

export const selectOptions = (state: RootState) => state.options;
export const {
    setTradingOption,
    addOrUpdateHistoricalOptions,
    changeHistoricalOptionsInitialFetchFlag,
    setDisplayOption,
    changeHistoricalOptionsState,
    initiateSwapPricingData,
    changeAllowanceMode,
    changeSlippage,
} = options.actions;

export default options.reducer;
