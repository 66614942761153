import React, { useEffect, useState } from 'react';
import OilerGrid from '@oilerKit/OilerGrid';
import OilerTypography from '@oilerKit/OilerTypography';
import OilerSkeleton from '@oilerKit/OilerSkeleton';
import OilerDivider from '@oilerKit/OilerDivider';
import { BigNumber } from 'ethers/lib/ethers';
import { getForwardsData } from '@features/contracts-interactions/forwards/actions';

interface TableProps {
    inputAmount: string | undefined;
}

const DataCell = ({ value, loading }: { value: string; loading: boolean }) => {
    return (
        <OilerTypography variant={'h6'} weight={'bold'} color={'primary'}>
            {loading ? <OilerSkeleton width={50} /> : value}
        </OilerTypography>
    );
};

const Table = ({ inputAmount }: TableProps) => {
    const [forwardsData, setForwardsData] = useState<{
        bidLambda: BigNumber;
        askLambda: BigNumber;
        bidStrike: BigNumber;
        askStrike: BigNumber;
        liquidity: string;
        lambda: string;
        shortContracts: string;
        longContracts: string;
        poolHealth: string;
        tvl: string;
    } | null>(null);

    useEffect(() => {
        getForwardsData().then(setForwardsData);
    }, []);

    const placeholder = '---';

    // useEffect(() => {
    //     async function calculateBidAndAsk() {
    //         if (!isValidNumber(inputAmount!)) return;
    //         const lambdaContract = new Contract(
    //             process.env.REACT_APP_FORWARDS_ADDRESS!,
    //             Forwards.abi,
    //             // provider.getSigner(),
    //         );
    //         await multiCallProvider.init();
    //         const bidCall = lambdaContract.calculateLambda(
    //             false,
    //             parseEther(inputAmount!),
    //         );
    //         const askCall = lambdaContract.calculateLambda(
    //             true,
    //             parseEther(inputAmount!),
    //         );
    //         const [bidLambda, askLambda] = await multiCallProvider.all([
    //             bidCall,
    //             askCall,
    //         ]);
    //         setBidLambda(bidLambda.toString());
    //         setAskLambda(askLambda.toString());
    //         const bidStrikeCall = lambdaContract.calculateStrike(
    //             bidLambda.toString(),
    //         );
    //         const askStrikeCall = lambdaContract.calculateStrike(
    //             askLambda.toString(),
    //         );
    //         const [bidS, askS] = await multiCallProvider.all([
    //             bidStrikeCall,
    //             askStrikeCall,
    //         ]);
    //         setBidStrike(bidS.toString());
    //         setAskStrike(askS.toString());
    //     }

    //     calculateBidAndAsk();
    // }, [blockNumber, inputAmount]);

    return (
        <>
            <OilerGrid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <OilerGrid item xs={4} />
                <OilerGrid container item xs={4} justifyContent="center">
                    <OilerTypography
                        variant="caption"
                        weight={'regular'}
                        color={'textPrimary'}
                    >
                        BID
                    </OilerTypography>
                </OilerGrid>
                <OilerGrid container item xs={4} justifyContent="center">
                    <OilerTypography
                        variant="caption"
                        weight={'regular'}
                        color={'textPrimary'}
                    >
                        ASK
                    </OilerTypography>
                </OilerGrid>
            </OilerGrid>
            <OilerGrid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <OilerGrid item xs={3} container justifyContent="flex-end">
                    <OilerTypography
                        variant="caption"
                        weight={'regular'}
                        color={'textPrimary'}
                        mr={3}
                    >
                        LAMBDA
                    </OilerTypography>
                </OilerGrid>
                <OilerGrid item xs={1}>
                    <OilerDivider flexItem small orientation={'vertical'} />
                </OilerGrid>
                <OilerGrid item xs={4} container justifyContent="center">
                    <DataCell
                        value={
                            forwardsData?.bidLambda
                                ? // parseFloat(
                                  //     formatEther(
                                  forwardsData?.bidLambda?.toString()
                                : placeholder
                            //     ),
                            // ).toFixed(4)
                        }
                        loading={forwardsData?.bidLambda?.toString() === ''}
                    />
                </OilerGrid>
                <OilerGrid item xs={4} container justifyContent="center">
                    <DataCell
                        value={
                            forwardsData?.askLambda
                                ? // parseFloat(
                                  //     formatEther(
                                  forwardsData?.askLambda?.toString()
                                : placeholder
                            //     ),
                            // ).toFixed(4)
                        }
                        loading={forwardsData?.askLambda?.toString() === ''}
                    />
                </OilerGrid>
            </OilerGrid>
            <OilerDivider />
            <OilerGrid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <OilerGrid item xs={3} container justifyContent="flex-end">
                    <OilerTypography
                        variant="caption"
                        weight={'regular'}
                        color={'textPrimary'}
                        mr={3}
                    >
                        STRIKE
                    </OilerTypography>
                </OilerGrid>
                <OilerGrid item xs={1}>
                    <OilerDivider flexItem small orientation={'vertical'} />
                </OilerGrid>
                <OilerGrid item xs={4} container justifyContent="center">
                    <DataCell
                        value={
                            forwardsData?.bidStrike
                                ? forwardsData?.bidStrike?.toString()
                                : placeholder
                        }
                        loading={forwardsData?.bidStrike?.toString() === ''}
                    />
                </OilerGrid>

                <OilerGrid item xs={4} container justifyContent="center">
                    <DataCell
                        value={
                            forwardsData?.askStrike
                                ? forwardsData?.askStrike?.toString()
                                : placeholder
                        }
                        loading={forwardsData?.askStrike?.toString() === ''}
                    />
                </OilerGrid>
            </OilerGrid>
        </>
    );
};

export default Table;
