import React from 'react';
import styled from 'styled-components';
import { Container, ContainerProps } from '@material-ui/core';

const StyledContainer = styled(Container)`
    width: 100%;
    max-width: 1140px;
    flex-grow: 1;
`;

const OilerContainer = (props: ContainerProps) => {
    return <StyledContainer {...props}>{props.children}</StyledContainer>;
};

export default OilerContainer;
