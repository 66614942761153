import { Link, LinkProps } from '@material-ui/core';

const OilerLink = (props: LinkProps) => {
    return (
        <Link
            underline={'none'}
            rel={props.target === '_blank' ? 'noreferrer' : ''}
            {...props}
        >
            {props.children}
        </Link>
    );
};

export default OilerLink;
